import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import Modal from "../../../shared/components/UIElements/Modal";
import Input from "../../../shared/components/FormElements/Input";
import Button from "../../../shared/components/FormElements/Button";
import { PencilIcon } from "@heroicons/react/24/outline";

import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import {
	PencilSquareIcon,
	PlusCircleIcon,
	PlusIcon,
} from "@heroicons/react/16/solid";

const LocationSchema = Yup.object().shape({
	name: Yup.string().required("Naam is verplicht"),
	street: Yup.string().required("Straat is verplicht"),
	postCode: Yup.string().required("Postcode is verplicht"),
	city: Yup.string().required("Stad is verplicht"),
});

const ManageLocations = () => {
	const { user } = useUser();

	const [locations, setLocations] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentLocation, setCurrentLocation] = useState(null);
	const fetchData = useFetch();

	useEffect(() => {
		fetchLocations();
	}, []);

	const fetchLocations = async () => {
		try {
			const response = await fetchData(
				`/api/practice/user/${user.id}/locations`,
			);
			setLocations(response);
		} catch (error) {
			console.error("Error fetching locations:", error);
		}
	};

	const handleSubmit = async (values, { setSubmitting, resetForm }) => {
		try {
			const url = currentLocation
				? `/api/practice/user/${user.id}/locations/${currentLocation._id}`
				: `/api/practice/user/${user.id}/locations`;
			const method = currentLocation ? "PUT" : "POST";

			await fetchData(url, {
				method: method,
				body: JSON.stringify(values),
				headers: {
					"Content-Type": "application/json",
				},
			});

			setIsModalOpen(false);
			resetForm();
			fetchLocations();
		} catch (error) {
			console.error("Error saving location:", error);
		}
		setSubmitting(false);
	};

	const openModal = (location = null) => {
		setCurrentLocation(location);
		setIsModalOpen(true);
	};

	return (
		<div>
			<h3 className="font-bold mb-1 text-lg">Behandellocaties</h3>
			<p className="text-sm text-slate-700 mb-8">
				Heb je meerdere behandellocaties die hetzelfde patiëntenbestand
				delen? Dan kun je hier extra locaties toevoegen.
			</p>

			<div className="space-y-6">
				<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
					{locations.map((location) => (
						<div
							key={location._id}
							className="bg-slate-100 rounded-2xl p-4"
						>
							<h3 className="font-semibold">{location.name}</h3>
							<p className="text-sm text-slate-800">
								{location.address.street}
							</p>
							<p className="text-sm text-slate-800">
								{`${location.address.postCode} ${location.address.city}`}
							</p>
							<button
								onClick={() => openModal(location)}
								className="text-brand-600 flex items-center gap-1 font-semibold hover:text-brand-700 transition-all duration-200 text-sm mt-4"
							>
								<PencilSquareIcon className="size-4" />
								Bewerken
							</button>
						</div>
					))}
				</div>
				<div>
					<Button
						onClick={() => openModal()}
						iconPrefix={PlusIcon}
						size="medium"
						ghost
					>
						Nieuwe locatie
					</Button>
				</div>

				<Modal open={isModalOpen} setOpen={setIsModalOpen}>
					<Formik
						initialValues={{
							name: currentLocation?.name || "",
							street: currentLocation?.address?.street || "",
							postCode: currentLocation?.address?.postCode || "",
							city: currentLocation?.address?.city || "",
							seats: currentLocation?.seats,
						}}
						validationSchema={LocationSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form className="space-y-4">
								<div className="px-6 pb-8">
									<h2 className="text-xl font-semibold mb-4 -mt-2">
										{currentLocation
											? "Locatie bewerken"
											: "Nieuwe locatie toevoegen"}
									</h2>
									<div className="">
										<Input
											name="name"
											label="Naam van de locatie"
											description="Dit krijgen patiënten ook te zien in het appje. Dus zorg dat het onderscheid tussen de locaties duidelijk is."
											required
										/>
										<Input
											name="seats"
											label="Aantal stoelen"
											type="number"
											min={0}
											suffix="stoelen"
											width="1/2"
											required
										/>
										<Input
											name="street"
											label="Straat"
											required
										/>
										<div className="flex gap-4">
											<Input
												name="postCode"
												label="Postcode"
												width="1/3"
												required
											/>
											<Input
												name="city"
												label="Stad"
												width="2/3"
												required
											/>
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
									<Button
										type="submit"
										disabled={isSubmitting}
										size="medium"
									>
										{currentLocation
											? "Opslaan"
											: "Toevoegen"}
									</Button>
									<Button
										size="medium"
										onClick={(e) => {
											e.preventDefault();
											setIsModalOpen(false);
										}}
										ghost
									>
										Annuleren
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal>
			</div>
		</div>
	);
};

export default ManageLocations;
