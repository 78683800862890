import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Tooltip = ({
	id = "default-id",
	content,
	html = false,
	helpCursor = false,
	children,
	place,
	clickable,
	...props
}) => {
	// Place should be 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end'.
	return (
		<>
			<span
				data-tooltip-id={id}
				data-tooltip-content={html ? null : content}
				data-tooltip-html={html ? content : null}
				data-tooltip-place={place}
				data-tooltip-clickable={clickable}
				{...props}
				className={helpCursor ? "cursor-help" : ""}
			>
				{children}
			</span>
			<ReactTooltip
				id={id}
				place={place}
				clickable={clickable}
				{...props}
			/>
		</>
	);
};

export default Tooltip;
