import React from "react";

import { useParams } from "react-router-dom";

import { CalendarIcon, MoonIcon, TrashIcon } from "@heroicons/react/24/outline";

import Container from "../../shared/components/UIElements/Container";
import Button from "../components/PortalButton";
import Heading from "../../shared/components/UIElements/Heading";

import { usePortal } from "../context/PortalContext";
import PortalHeader from "../components/PortalHeader";
import PortalLayout from "./PortalLayout";

const PatientPortal = ({ previewSettings }) => {
	const { patientId } = useParams();
	const key = new URLSearchParams(window.location.search).get("key");

	const portal = usePortal();
	const portalSettings = previewSettings || portal.portalSettings;

	const handleClick = (e) => {
		if (previewSettings) {
			e.preventDefault();
		}
	};

	const content = (
		<>
			<div className="text-left pb-10 pt-10">
				<Heading>Wat wilt u doen?</Heading>
			</div>
			<div className="text-left flex flex-col items-start">
				<p className="text-gray-800 text-sm pb-4">
					Krijg gerichter uitnodigingen door aan te geven wanneer u
					zeker niet kunt.
				</p>
				<Button
					iconPrefix={CalendarIcon}
					to={`/c/beschikbaarheid/${patientId}?key=${key}`}
					onClick={handleClick}
					previewSettings={previewSettings}
					fullWidth
				>
					Geef beschikbaarheid op
				</Button>
			</div>

			<div className="relative mt-10 pt-10">
				<p className="absolute -top-3 left-1/2 transform -translate-x-1/2 z-20 bg-white px-2 text-center text-gray-500 text-sm rounded-full">
					of
				</p>
				<div className="border-t border-gray-200 -mt-10"></div>
			</div>
			<div className="text-left flex flex-col items-start">
				<p className="text-gray-800 text-sm pb-4">
					Bent u met vakantie? Krijg een tijdje geen uitnodigingen
					meer voor vrijgekomen plekken.
				</p>
				<Button
					iconPrefix={MoonIcon}
					to={`/c/snooze/${patientId}?key=${key}`}
					onClick={handleClick}
					previewSettings={previewSettings}
					fullWidth
				>
					Snooze uitnodigingen
				</Button>
			</div>

			<div className="relative mt-10 pt-10">
				<p className="absolute -top-3 left-1/2 transform -translate-x-1/2 z-20 bg-white px-2 text-center text-gray-500 text-sm rounded-full">
					of
				</p>
				<div className="border-t border-gray-200 -mt-10"></div>
			</div>
			<div className="text-left flex flex-col items-start">
				<Button
					ghost
					iconPrefix={TrashIcon}
					to={`/c/verwijder/${patientId}?key=${key}`}
					onClick={handleClick}
					previewSettings={previewSettings}
					fullWidth
				>
					Haal me van de wachtlijst af
				</Button>
				<p className="text-gray-500 text-xs mt-4">
					U wordt niet meer opgeroepen voor plekken in de agenda die
					vrijkomen.
				</p>
			</div>
		</>
	);

	return previewSettings ? (
		<>
			<div className="flex justify-start mt-2">
				{portalSettings?.logoUrl && (
					<img
						src={
							previewSettings
								? `${portalSettings?.logoUrl}?${new Date().getTime()}`
								: portalSettings?.logoUrl
						}
						alt={portalSettings?.name}
						className="max-w-40 h-auto max-h-20"
					/>
				)}
			</div>
			{content}
		</>
	) : (
		<PortalLayout>{content}</PortalLayout>
	);
};

export default PatientPortal;
