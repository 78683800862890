import React, { useState, useEffect } from "react";

import { SparklesIcon } from "@heroicons/react/24/solid";

import useFetch from "../../../shared/hooks/useFetch";

import Button from "../../../shared/components/FormElements/Button";
import CreatiefVullenModal from "./CreatiefVullenModal";
import classNames from "classnames";
import {
	BoltIcon,
	ChevronDoubleRightIcon,
	ClockIcon,
} from "@heroicons/react/16/solid";

const NewEligiblePatientsNotice = ({
	slotID,
	batches,
	slotDuration,
	reload,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [extraPatients, setExtraPatients] = useState([]);
	const [extraUnavailablePatients, setExtraUnavailablePatients] = useState(
		[],
	);
	const [isLoading, setIsLoading] = useState(true);
	const [isHidden, setIsHidden] = useState(false);

	const fetchPatients = useFetch();

	useEffect(() => {
		const fetchNewPatients = async () => {
			const patients = await fetchPatients(
				`/api/slots/${slotID}/new-eligible-patients`,
			);
			setExtraPatients(patients.available);
			setExtraUnavailablePatients(patients.unavailable);
			setIsLoading(false);
		};

		fetchNewPatients();
	}, []);

	if (!isLoading && extraPatients.length === 0) return null;

	if (isHidden) return null;

	return (
		<div
			className={classNames("transition-all duration-500 ease-in-out", {
				"opacity-0 h-0": isLoading,
				"opacity-100 h-auto": !isLoading,
			})}
		>
			<li className="relative flex gap-x-4">
				<div>
					<div className="w-[90px]">
						<p className="text-sm text-right text-gray-600 flex items-end justify-end"></p>
					</div>
					<div className="h-24 absolute left-[105px] -top-12 flex w-6 justify-center z-0">
						<div className="w-px bg-gray-200" />
					</div>
					<div className="flex h-6 w-6 flex-none items-center justify-center bg-transparent absolute left-[105px] top-0">
						<div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
					</div>
				</div>
				<div className="flex h-6 w-6 flex-none items-center justify-center bg-white">
					<div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-0 ring-gray-300" />
				</div>
				<ul className="flex-auto py-0.5 text-sm text-gray-900 font-regular flex flex-wrap gap-4 -mt-3">
					<li className={"py-3 items-center"}>
						<p className="text-brand-600 font-semibold flex items-center pb-1">
							<ChevronDoubleRightIcon className="size-5 text-brand-500 mr-1" />
							{extraPatients.length === 1
								? "Je hebt recent 1 patiënt aangemaakt die ook in aanmerking komt"
								: `Je hebt recent ${extraPatients.length} patiënten aangemaakt die ook in aanmerking komen`}
						</p>
						<p className="text-sm text-gray-600 pb-4">
							Deze patiënten zijn pas net toegevoegd aan
							Snelterecht
						</p>
						<Button
							size="medium"
							onClick={() => setOpenModal(true)}
						>
							Bekijken
						</Button>
					</li>
				</ul>
			</li>
			<CreatiefVullenModal
				open={openModal}
				setOpen={setOpenModal}
				extraPatients={extraPatients}
				extraUnavailablePatients={extraUnavailablePatients}
				isLoading={isLoading}
				slotDuration={slotDuration}
				slotID={slotID}
				reload={reload}
				setIsHidden={setIsHidden}
				type="newlyCreatedPatients"
			/>
		</div>
	);
};

export default NewEligiblePatientsNotice;
