import { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";

import useFetch from "../../shared/hooks/useFetch";
import { useTaskContext } from "../context/TaskContext";
import Tooltip from "../../shared/components/UIElements/Tooltip";

const TasksCounter = () => {
	const [notifs, setNotifs] = useState([]);
	const fetchNotifications = useFetch();
	const { user } = useUser();
	const { tasksUpdated } = useTaskContext();

	useEffect(() => {
		const getNotifications = async () => {
			const response = await fetchNotifications(
				`/api/tasks/user/${user.id}/open`,
			);
			setNotifs(response.length);
		};

		getNotifications();
	}, [tasksUpdated]);

	if (notifs && notifs > 0) {
		return (
			<Tooltip
				content={`Je moet nog ${notifs} ${notifs === 1 ? "afspraak" : "afspraken"} verzetten in de praktijkagenda`}
				helpCursor
			>
				<span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-100 text-xs font-semibold text-red-900">
					{notifs}
				</span>
			</Tooltip>
		);
	}
};

export default TasksCounter;
