import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const CloseWizard = ({ currentStep, to }) => {
	const navigate = useNavigate();

	const closeWizard = () => {
		if (currentStep !== 0) {
			if (window.confirm("Weet je zeker dat je wilt stoppen?")) {
				navigate(to);
			}
		} else {
			navigate(to);
		}
	};

	return (
		<button
			onClick={closeWizard}
			className="font-medium text-gray-500 text-md hover:text-gray-800 items-center flex gap-x-2 transition-colors duration-300"
		>
			Sluiten
			<XMarkIcon className="size-5" />
		</button>
	);
};

export default CloseWizard;
