import React, { useEffect, useState } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import Table from "../../../shared/components/Tables/Table";
import Heading from "../../../shared/components/UIElements/Heading";
import { Link } from "react-router-dom";
import ProfileImage from "../../../users/components/ProfileImage";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useUser } from "@clerk/clerk-react";

const PracticesWithMultipleLocations = () => {
	const fetchData = useFetch();
	const [practices, setPractices] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const { user } = useUser();

	useEffect(() => {
		const fetchPractices = async () => {
			try {
				const data = await fetchData(
					`/api/admin/practices/multiple-locations?adminId=${user?.id}`,
				);
				setPractices(data);
			} catch (error) {
				console.error("Error fetching practices:", error);
			}
			setIsLoading(false);
		};

		fetchPractices();
	}, [fetchData]);

	return (
		<div>
			<div className="flex items-center justify-between mb-10">
				<Heading>Praktijken met meerdere locaties</Heading>
			</div>

			<Table data={practices} isLoading={isLoading}>
				<th
					scope="col"
					className="pl-6 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div className="flex items-center gap-x-4">
							<ProfileImage name={row.name} />
							<div>
								<Link
									to={`/admin/practice/?id=${row._id}`}
									className="hover:underline"
								>
									<p className="font-medium text-black">
										{row.name}
									</p>
								</Link>
							</div>
						</div>
					)}
				>
					Practice
				</th>

				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div className="flex flex-col gap-y-2">
							{row.locations.map((location) => (
								<div
									key={location._id}
									className="flex flex-col gap-y-1"
								>
									<div className="flex items-center gap-x-2">
										<MapPinIcon className="h-4 w-4 text-gray-400" />
										<p className="font-medium">
											{location.name} (
											{location.address.city})
										</p>
									</div>
									<div className="pl-6 text-sm text-gray-500">
										{location.seats || "Onbekend"} stoelen •{" "}
										{location.notifications?.emailAddresses?.join(
											", ",
										) || "Geen email"}
									</div>
								</div>
							))}
						</div>
					)}
				>
					Locaties ({practices.length})
				</th>
			</Table>
		</div>
	);
};

export default PracticesWithMultipleLocations;
