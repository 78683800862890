import React from "react";
import FieldSuggestions from "../../../shared/components/FormElements/FieldSuggestions";

const TreatmentSuggestions = ({ treatment, treatments, duration }) => {
	const suggestedTreatments = treatments
		.filter((treatment) => treatment?.possibleDurations?.includes(duration))
		.map((treatment) => ({
			label: treatment.label,
			value: treatment.value,
		}));

	if (suggestedTreatments.length === 0) {
		return null;
	}

	return (
		<>
			<FieldSuggestions
				suggestions={suggestedTreatments}
				fieldName="treatment"
				isVisible={!treatment}
			/>
		</>
	);
};

export default TreatmentSuggestions;
