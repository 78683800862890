import React from "react";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import Button from "../../shared/components/FormElements/Button";
import {
	CalendarDaysIcon,
	QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import { useUser } from "@clerk/clerk-react";

const ReactivationPilotPage = () => {
	const { user } = useUser();

	return (
		<Container className="flex">
			<div className="w-1/2">
				<div className="flex items-center gap-x-2 bg-yellow-50 px-4 py-2 rounded-lg border-yellow-300 border w-fit mb-4">
					<img
						src="https://em-content.zobj.net/source/apple/391/construction_1f6a7.png"
						alt="Oproepen"
						className="size-5"
					/>
					<p className="text-sm font-semibold text-yellow-800">
						Druk aan het bouwen...
					</p>
				</div>
				<Heading className="text-balance">
					Oproepen van patiënten die lang niet zijn geweest
				</Heading>
				<div className="text-slate-800 mt-10 space-y-4">
					<p>
						We zijn hard op weg met deze nieuwe feature. Hiermee kun
						je op een slimme manier patiënten oproepen die al lange
						tijd niet geweest zijn.
					</p>
					<p>
						{" "}
						Lijkt het je leuk om mee te denken met hoe dit er voor
						jouw praktijk uit het beste uit kan zien? Door een paar
						<strong> korte vragen</strong> te beantwoorden, help je
						ons om de best mogelijke oplossing te bouwen.
					</p>
				</div>

				<div className="flex flex-col gap-y-4 mt-8">
					<Button
						iconPrefix={QuestionMarkCircleIcon}
						href={`https://dentiva.typeform.com/to/MIvu7xsv#user_id=${user.id}&email=${user.primaryEmailAddress.emailAddress}&name=${user.firstName} ${user.lastName}`}
						target="_blank"
					>
						Vul de korte vragen in (2 min)
					</Button>
					{/* <Button
						ghost
						iconPrefix={CalendarDaysIcon}
						href="https://calendly.com/matthijs-b658/snelterecht-oproepen-patienten"
						target="_blank"
					>
						Doe mee aan de brainstormsessie
					</Button> */}
				</div>
			</div>
		</Container>
	);
};

export default ReactivationPilotPage;
