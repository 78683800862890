import { useAuth } from "@clerk/clerk-react";
import { useCallback } from "react";
import { toast } from "react-toastify";

export default function useFetch() {
	const { getToken } = useAuth();
	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const authenticatedFetch = useCallback(
		async (
			endpoint,
			{
				responseType = "json",
				retries = 1,
				timeout = 10000,
				...init
			} = {},
		) => {
			const fetchWithTimeout = (url, options, timeout) => {
				return Promise.race([
					fetch(url, options),
					new Promise((_, reject) =>
						setTimeout(
							() => reject(new Error("Request timed out")),
							timeout,
						),
					),
				]);
			};

			const delay = (ms) =>
				new Promise((resolve) => setTimeout(resolve, ms));

			for (let attempt = 0; attempt < retries; attempt++) {
				try {
					const response = await fetchWithTimeout(
						`${baseURL}${endpoint}`,
						{
							credentials: "include",
							...init,
							headers: {
								Authorization: `Bearer ${await getToken()}`,
								...init.headers,
							},
						},
						timeout,
					);

					if (!response.ok) {
						let errorMessage =
							"Er is iets misgegaan, probeer het later opnieuw.";
						try {
							const res = await response.json();
							errorMessage = res.message || errorMessage;
						} catch (jsonError) {
							console.error("Error parsing JSON:", jsonError);
						}

						await toast.error(errorMessage);
						throw new Error(errorMessage);
					}

					switch (responseType) {
						case "json":
							return response.json();
						case "blob":
							return response;
						default:
							throw new Error(
								`Unsupported response type: ${responseType}`,
							);
					}
				} catch (error) {
					if (attempt < retries - 1) {
						console.warn(
							`Fetch attempt ${attempt + 1} failed, retrying...`,
							error,
						);
						await delay(1000); // 1-second delay between retries
						continue;
					}
					console.error("Fetch error:", error);
					return { error: error.message };
				}
			}
		},
		[getToken],
	);

	return authenticatedFetch;
}
