import React from "react";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import BehandelaarTable from "../components/Behandelaren/BehandelaarTable";
import PracticeInfoForm from "../components/PracticeInfoForm";

const PracticeSettings = () => {
	return (
		<Container>
			<div>
				<Heading>Beheer je praktijk</Heading>
				<p className="text-gray-600 pt-2">
					Je praktijkgegevens worden getoond aan patiënten in de
					WhatsApp uitnodigingen.
				</p>
			</div>
			<div className="flex gap-10">
				<div className="mt-12 w-1/4">
					<PracticeInfoForm />
				</div>

				<div className="mt-14 w-3/4">
					<BehandelaarTable />
				</div>
			</div>
		</Container>
	);
};

export default PracticeSettings;
