import React from "react";

const FieldErrorMessage = ({ meta }) => {
	return (
		<>
			{meta.touched && meta.error ? (
				<div className="text-orange-600 text-sm pt-2 animate-fade-in">
					{meta.error}
				</div>
			) : null}
		</>
	);
};

export default FieldErrorMessage;
