import React, { useState, useEffect, useRef } from "react";
import { useUser } from "@clerk/clerk-react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";

import useFetch from "../../../shared/hooks/useFetch";
import Button from "../../../shared/components/FormElements/Button";
import FormattedDate from "../../../shared/components/util/FormattedDate";

const PatientsEyes = () => {
	const [patientData, setPatientData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const fetchData = useFetch();
	const { user } = useUser();
	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView();
	};

	const fetchRandomPatient = async () => {
		setIsLoading(true);
		try {
			const data = await fetchData(
				`/api/admin/patient-stats?adminId=${user.id}`,
			);
			setPatientData(data);
		} catch (error) {
			console.error("Error fetching patient data:", error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchRandomPatient();
	}, []);

	useEffect(() => {
		scrollToBottom();
	}, [patientData]);

	const groupMessagesByDate = (messages) => {
		const groups = {};
		messages
			?.sort((a, b) => new Date(a.dateSent) - new Date(b.dateSent))
			.forEach((message) => {
				const date = new Date(message.dateSent).toLocaleDateString();
				if (!groups[date]) {
					groups[date] = [];
				}
				groups[date].push(message);
			});
		return groups;
	};

	const formatMessageContent = (message) => {
		if (message.type === "slot-invitation") {
			return (
				<div>
					<p>
						Uitnodiging voor plek bij{" "}
						{message.slotId?.behandelaar?.firstName} (
						{message.slotId?.behandelaar?.occupation.title})
					</p>
					<p>
						- <FormattedDate date={message.slotId?.date} />
					</p>
					<p>- {message.slotId?.startTime} uur</p>
				</div>
			);
		} else if (message.type === "patient-added") {
			return `Je bent toegevoegd aan de wachtlijst bij ${patientData?.practice?.name}`;
		}
		return message.content;
	};

	if (isLoading) {
		return <div>Aan het laden...</div>;
	}

	const groupedMessages = groupMessagesByDate(patientData?.messages);

	return (
		<div className="max-w-2xl mx-auto">
			<div className="flex items-center justify-between mb-6">
				<div>
					<h1 className="text-2xl font-bold">
						{patientData?.practice?.name}
					</h1>
				</div>
				<Button onClick={fetchRandomPatient} iconPrefix={ArrowPathIcon}>
					Andere patiënt
				</Button>
			</div>

			<div className="mx-auto w-[375px] h-[667px] bg-white border-8 border-gray-800 rounded-[48px] relative">
				<div className="absolute top-0 w-32 h-6 bg-gray-800 left-1/2 -translate-x-1/2 rounded-b-2xl"></div>
				<div className="h-full overflow-y-auto px-4 py-6 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
					<div className="space-y-8">
						{Object.entries(groupedMessages || {}).map(
							([date, messages]) => (
								<div key={date}>
									<div className="text-center mb-4">
										<span className="bg-gray-100 text-gray-600 px-3 py-1 rounded-full text-sm">
											{date}
										</span>
									</div>

									<div className="space-y-4">
										{messages.map((message) => (
											<>
												<div
													key={`sent-${message._id}`}
													className="flex justify-start"
												>
													<div className="max-w-[70%] rounded-lg px-4 py-2 bg-gray-100 text-gray-800">
														{formatMessageContent(
															message,
														)}
														<div className="text-xs mt-1 text-gray-500 flex items-center gap-1">
															{new Date(
																message.dateSent,
															).toLocaleTimeString(
																[],
																{
																	hour: "2-digit",
																	minute: "2-digit",
																},
															)}
															{message.deliveryStatus ===
																"read" && (
																<svg
																	className="w-4 h-4 text-blue-500"
																	viewBox="0 0 24 24"
																	fill="currentColor"
																>
																	<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
																	<path
																		d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
																		transform="translate(4 0)"
																	/>
																</svg>
															)}
														</div>
													</div>
												</div>

												{message.response && (
													<div
														key={`response-${message._id}`}
														className="flex justify-end"
													>
														<div className="max-w-[70%] rounded-lg px-4 py-2 bg-green-500 text-white">
															{message.response ===
															"accepted"
																? "Ja, ik kan!"
																: "Nee, ik kan niet"}
															<div className="text-xs mt-1 text-green-100">
																{new Date(
																	message.dateResponded,
																).toLocaleTimeString(
																	[],
																	{
																		hour: "2-digit",
																		minute: "2-digit",
																	},
																)}
															</div>
														</div>
													</div>
												)}
											</>
										))}
									</div>
								</div>
							),
						)}
						<div ref={messagesEndRef} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PatientsEyes;
