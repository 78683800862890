import React, { useState, useEffect } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";

const LocationFilter = ({
	onFilterChange,
	enabledLocations,
	showCount = false,
}) => {
	const fetchLocations = useFetch();
	const { user } = useUser();
	const [isLoading, setIsLoading] = useState(true);
	const [fetchedLocations, setFetchedLocations] = useState([]);
	const [locations, setLocations] = useState([]);
	const [locationFilter, setLocationFilter] = useState("all");

	useEffect(() => {
		const getLocations = async () => {
			try {
				const fetchedLocations = await fetchLocations(
					`/api/practice/user/${user.id}/locations`,
					"GET",
				);
				setFetchedLocations(fetchedLocations);
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching locations:", error);
			}
		};

		if (user) {
			getLocations();
		}
	}, [user, fetchLocations]);

	useEffect(() => {
		if (fetchedLocations.length > 0 && enabledLocations?.length > 0) {
			const filteredLocations = fetchedLocations?.filter((location) =>
				enabledLocations?.includes(location._id),
			);
			setLocations(filteredLocations);
		} else {
			setLocations(fetchedLocations);
		}
	}, [fetchedLocations, enabledLocations]);

	const handleLocationChange = (e) => {
		const value = e.target.value;
		setLocationFilter(value);
		onFilterChange(value);
	};

	if (isLoading || !fetchedLocations || fetchedLocations.length === 1)
		return null;

	return (
		<div>
			<select
				value={locationFilter}
				onChange={handleLocationChange}
				className="focus:ring-0 w-fit py-1.5 pr-8 rounded-lg flex text-sm text-slate-800 px-3 items-center focus:ring-brand-400 border-1 border-slate-400 border-dashed bg-transparent"
			>
				<option value="all">Alle locaties</option>
				{locations?.map((location) => (
					<option key={location._id} value={location._id}>
						{location.name}
						{showCount &&
							` (${locations?.filter((id) => id === location._id).length})`}
					</option>
				))}
			</select>
		</div>
	);
};

export default LocationFilter;
