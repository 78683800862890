import React from "react";
import attentionIcon from "../../assets/AttentionRequired.png";

const AttentionRequired = () => {
	return (
		<div className="flex items-center gap-x-1.5">
			<img src={attentionIcon} alt="Actie vereist" className="w-4 h-4" />
			<p className="text-[#F81606] font-semibold text-sm">
				Actie vereist
			</p>
		</div>
	);
};

export default AttentionRequired;
