import React from "react";

const SubHeading = ({ children, className, icon: Icon }) => {
	return (
		<div
			className={`text-slate-500 uppercase font-semibold flex items-center gap-x-2 ${className}`}
		>
			{Icon && <Icon className="w-4 h-4" />}
			{children}
		</div>
	);
};

export default SubHeading;
