import React, { useEffect, useState, useMemo } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import Heading from "../../../shared/components/UIElements/Heading";
import SubHeading from "../../../shared/components/UIElements/SubHeading";
import Table from "../../../shared/components/Tables/Table";
import {
	BuildingOfficeIcon,
	CreditCardIcon,
	EyeIcon,
} from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/16/solid";
import { Link } from "react-router-dom";
import MiniCopyButton from "../../../shared/components/FormElements/MiniCopyButton";
import classNames from "classnames";

const UserOverview = () => {
	const fetchData = useFetch();
	const { user } = useUser();
	const [practices, setPractices] = useState([]);

	const [isLoading, setIsLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		const fetchPractices = async () => {
			setIsLoading(true);
			const data = await fetchData(`/api/admin/user-overview/${user.id}`);
			setPractices(data.practices);

			setIsLoading(false);
		};
		fetchPractices();
	}, [fetchData, user.id]);

	const formatMetadata = (metadata) => {
		return JSON.stringify(metadata, null, 2);
	};

	const filteredPractices = useMemo(() => {
		return practices.filter((practice) => {
			const searchLower = searchTerm.toLowerCase();
			return (
				practice.name?.toLowerCase().includes(searchLower) ||
				practice._id.toLowerCase().includes(searchLower) ||
				practice.creator?.firstName
					?.toLowerCase()
					.includes(searchLower) ||
				practice.creator?.lastName
					?.toLowerCase()
					.includes(searchLower) ||
				practice.creator?.id?.toLowerCase().includes(searchLower)
			);
		});
	}, [practices, searchTerm]);

	return (
		<>
			<div className="flex items-center gap-2 justify-between">
				<Heading>Gebruikers</Heading>
				<input
					type="text"
					placeholder="Zoek praktijk"
					className="px-3 py-1.5 rounded-lg text-sm border border-gray-300"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
			</div>

			<div className="mt-10 mb-8">
				<SubHeading icon={BuildingOfficeIcon}>
					Praktijken ({filteredPractices.length})
				</SubHeading>
				<Table data={filteredPractices} isLoading={isLoading}>
					<th
						scope="col"
						className="pl-6 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<>
								<div className="group flex items-center gap-2">
									<Link
										to={`/admin/practice/?id=${row._id}`}
										className="hover:underline"
									>
										<p className="font-bold text-black">
											{row.name || "N/A"}
										</p>
									</Link>

									<div className="hidden group-hover:flex items-center gap-2">
										<Link
											to={`https://dashboard.clerk.com/apps/app_2adroXLNht5HisJZaWk5fiFqpm2/instances/ins_2eBRcL5awsiVs7CDnNulzxnwJES/users/${row.creator?.id}`}
											target="_blank"
										>
											<PencilSquareIcon className="w-4 h-4 text-slate-400" />
										</Link>
										<Link
											to={`https://dashboard.stripe.com/customers/${row.creator?.publicMetadata?.customerId}`}
											target="_blank"
										>
											<CreditCardIcon className="w-4 h-4 text-slate-400" />
										</Link>
										<MiniCopyButton
											text={row._id}
											label="ID"
										/>
										<MiniCopyButton
											text={row.creator?.id}
											label="Clerk ID"
										/>
									</div>
								</div>
								<p>
									{`${row.creator?.firstName} ${row.creator?.lastName}`}
								</p>
							</>
						)}
					>
						Praktijk
					</th>

					{/* <th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							new Date(row.dateCreated).toLocaleDateString()
						}
					>
						Laatst ingelogd op
					</th> */}
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<pre
								className={classNames(
									"text-xs p-2 rounded bg-gray-100",
									{
										"bg-gray-100":
											Object.keys(
												row.creator?.publicMetadata ||
													{},
											).length > 0,
										"bg-orange-50":
											Object.keys(
												row.creator?.publicMetadata ||
													{},
											).length === 0,
									},
								)}
							>
								{formatMetadata(row.creator?.publicMetadata)}
							</pre>
						)}
					>
						Public Metadata
					</th>

					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<pre
								className={classNames(
									"text-xs p-2 rounded bg-gray-100",
									{
										"bg-gray-100":
											Object.keys(
												row.creator?.unsafeMetadata ||
													{},
											).length > 0,
										"bg-orange-50":
											Object.keys(
												row.creator?.unsafeMetadata ||
													{},
											).length === 0,
									},
								)}
							>
								{formatMetadata(row.creator?.unsafeMetadata)}
							</pre>
						)}
					>
						Unsafe Metadata
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<pre
								className={classNames(
									"text-xs p-2 rounded bg-gray-100",
									{},
								)}
							>
								{formatMetadata(row.creator?.privateMetadata)}
							</pre>
						)}
					>
						Private Metadata
					</th>
				</Table>
			</div>
		</>
	);
};

export default UserOverview;
