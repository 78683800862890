import React from "react";
import Button from "./FormElements/Button";
import { HomeIcon, ArrowPathIcon } from "@heroicons/react/20/solid";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can log the error to an error reporting service here
		console.error("Error caught by ErrorBoundary:", error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="flex items-center justify-center min-h-screen bg-gray-100">
					<div className="text-center">
						<h1 className="text-4xl font-black mb-4">
							Ai, er is iets misgegaan...
						</h1>
						<p className="text-xl text-gray-700 mb-8">
							Zou je ons via de chat rechtsonder willen laten
							weten wat je probeerde te doen?
						</p>
						<div className="flex justify-center space-x-4">
							<Button
								onClick={() => window.location.reload()}
								iconPrefix={ArrowPathIcon}
								ghost
							>
								Refresh pagina
							</Button>
							<Button href="/" iconPrefix={HomeIcon}>
								Naar dashboard
							</Button>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
