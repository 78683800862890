import React, { useState, useCallback } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/16/solid";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";

import Modal from "../components/UIElements/Modal";
import Button from "../components/FormElements/Button";
import PortalButton from "../../public/components/PortalButton";

const useCustomConfirm = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [resolveReject, setResolveReject] = useState([]);

	const [settings, setSettings] = useState({});

	const confirm = useCallback((settings) => {
		setIsOpen(true);
		setSettings(settings);
		return new Promise((resolve, reject) => {
			setResolveReject([resolve, reject]);
		});
	}, []);

	const handleClose = useCallback(
		(result) => {
			setIsOpen(false);
			const [resolve] = resolveReject;
			resolve(result);
		},
		[resolveReject],
	);

	const ConfirmModal = useCallback(
		({ children, overrideColor }) => {
			const ActionButton = ({ isConfirm }) => {
				const buttonProps = {
					size: "medium",
					classNames: "w-full justify-center sm:w-fit",
					onClick: () => handleClose(isConfirm),
					ghost: !isConfirm,
				};

				if (overrideColor) {
					return (
						<PortalButton
							{...buttonProps}
							previewSettings={
								isConfirm
									? { primaryColor: overrideColor }
									: undefined
							}
						>
							{isConfirm
								? settings.cta || "Ja, ik weet het zeker"
								: settings.cancel || "Annuleren"}
						</PortalButton>
					);
				}

				return (
					<Button
						{...buttonProps}
						classNames={classNames(buttonProps.classNames, {
							"bg-red-600 hover:bg-red-500":
								isConfirm && settings.warning,
							"bg-brand-600 hover:bg-brand-500":
								isConfirm && !settings.warning,
						})}
					>
						{isConfirm
							? settings.cta || "Ja, ik weet het zeker"
							: settings.cancel || "Annuleren"}
					</Button>
				);
			};

			return (
				<Modal
					open={isOpen}
					includeCloseButton={false}
					setOpen={() => handleClose(false)}
				>
					<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
						<div className="sm:flex sm:items-start">
							<div
								className={classNames(
									"mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
									{
										"bg-red-100":
											settings.warning && !overrideColor,
										"bg-brand-100":
											!settings.warning && !overrideColor,
									},
								)}
								style={
									overrideColor
										? {
												backgroundColor: `${overrideColor}20`,
											}
										: undefined
								}
							>
								<ExclamationTriangleIcon
									className={classNames("h-6 w-6", {
										"text-red-600":
											settings.warning && !overrideColor,
										"text-brand-600":
											!settings.warning && !overrideColor,
									})}
									style={
										overrideColor
											? { color: overrideColor }
											: undefined
									}
									aria-hidden="true"
								/>
							</div>
							<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
								<Dialog.Title
									as="h3"
									className="text-base font-semibold leading-6 text-gray-900"
								>
									{settings.title || "Weet je het zeker?"}
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-sm text-gray-500">
										{settings.description}
									</p>
								</div>
								<div>{children}</div>
							</div>
						</div>
					</div>
					<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2 space-y-2 sm:space-y-0">
						<ActionButton isConfirm={true} />
						<ActionButton isConfirm={false} />
					</div>
				</Modal>
			);
		},
		[isOpen, settings, handleClose],
	);

	return [confirm, ConfirmModal];
};

export default useCustomConfirm;
