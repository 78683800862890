import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import Table from "../../../shared/components/Tables/Table";
import FormattedDate from "../../../shared/components/util/FormattedDate";
import StatusPill from "../../../slots/components/StatusPill";
import Tooltip from "../../../shared/components/UIElements/Tooltip";
import Heading from "../../../shared/components/UIElements/Heading";

import blueTicks from "../../../assets/BlueTicks.png";

import ProfileImage from "../../../users/components/ProfileImage";
import { Link } from "react-router-dom";

import {
	DateRangePicker,
	Tab,
	TabGroup,
	TabList,
	TabPanel,
	TabPanels,
} from "@tremor/react";
import {
	ChatBubbleOvalLeftIcon,
	CheckCircleIcon,
} from "@heroicons/react/16/solid";
import DonutChart from "../../../shared/components/Charts/DonutChart";
import classNames from "classnames";

const TodaysSlots = () => {
	const { user } = useUser();
	const fetchData = useFetch();

	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
	});
	const [slotsData, setSlotsData] = useState({
		slots: [],
		statusCounts: {},
		occupationCounts: {},
	});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchSlotsData = async () => {
			try {
				const data = await fetchData(
					`/api/admin/slots/today?adminId=${user.id}&from=${dateRange.from.toISOString()}&to=${dateRange.to.toISOString()}`,
				);
				setSlotsData(data);
			} catch (error) {
				console.error("Error fetching today's slots:", error);
			}
			setIsLoading(false);
		};

		fetchSlotsData();
	}, [fetchData, user.id, dateRange]);

	const statusCounts = {
		all: slotsData.slots.length,
		claimed: slotsData.slots.filter((slot) => slot.status === "claimed")
			.length,
		open: slotsData.slots.filter((slot) => slot.status === "open").length,
		deleted: slotsData.slots.filter((slot) => slot.status === "deleted")
			.length,
		failed: slotsData.slots.filter((slot) => slot.status === "failed")
			.length,
	};

	const renderTable = (filteredSlots) => (
		<Table data={filteredSlots} isLoading={isLoading}>
			<th
				scope="col"
				className="pl-6 py-3.5 text-left text-sm font-semibold text-gray-900"
				render={(row) => (
					<div className="flex items-center gap-x-4">
						<ProfileImage name={row.practice.name} />
						<div className="">
							<Link
								to={`/admin/practice/?id=${row.practice.id}`}
								className="hover:underline"
							>
								<p className="font-medium text-black">
									{row.practice.name}
								</p>
							</Link>
							<p className="text-sm text-gray-500">
								{row.occupation}
							</p>
						</div>
					</div>
				)}
			>
				Practice
			</th>
			<th
				scope="col"
				className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
				render={(row) => (
					<>
						<p className="font-medium text-black">
							<FormattedDate
								date={row.date}
								format="DD MMM YYYY"
							/>
						</p>
						<p className="text-sm text-gray-500">
							{row.startTime} - {row.endTime} uur ({row.duration}{" "}
							min)
						</p>
					</>
				)}
			>
				Datum
			</th>

			<th
				scope="col"
				className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
				render={(row) => (
					<div className="">
						<StatusPill status={row.status} size="medium" />
						{row.status === "claimed" &&
							row.dateClaimed &&
							row.dateCreated && (
								<>
									<p className="text-xs text-gray-500 pt-2">
										in{" "}
										{Math.round(
											(new Date(row.dateClaimed) -
												new Date(row.dateCreated)) /
												60000,
										)}{" "}
										minuten
									</p>
									<p
										className={classNames(
											"text-xs font-medium",
											{
												"text-brand-700":
													row.duration -
														row.patientDuration <
													0,
												"text-slate-700":
													row.duration -
														row.patientDuration >
													0,
											},
										)}
									>
										{row.duration - row.patientDuration !==
											0 &&
											`${row.duration - row.patientDuration} min over`}
									</p>
								</>
							)}

						{row.status === "deleted" && row.dateDeleted && (
							<p className="text-xs text-gray-500 pt-2">
								in{" "}
								{Math.round(
									(new Date(row.dateDeleted) -
										new Date(row.dateCreated)) /
										60000,
								)}{" "}
								minuten
							</p>
						)}
					</div>
				)}
			>
				Status
			</th>
			<th
				scope="col"
				className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
				render={(row) => (
					<Tooltip
						content={`${row.totalInvitesRead}/${row.totalInvitesSent} gelezen`}
						id={`read-rate_row_${row._id}`}
					>
						<p className="text-sm font-medium flex gap-x-1 items-center">
							<img
								src={blueTicks}
								alt="Read"
								className="h-[11px] w-auto"
							/>
							{row.totalInvitesRead}/{row.totalInvitesSent}
						</p>
					</Tooltip>
				)}
			>
				Read rate
			</th>
			<th
				scope="col"
				className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
				render={(row) => (
					<div>
						<Tooltip
							content={`${row.totalInvitesResponded}/${row.totalInvitesSent} gereageerd`}
							id={`response-rate_row_${row._id}`}
						>
							<p className="text-sm font-medium flex gap-x-1 items-center">
								<ChatBubbleOvalLeftIcon className="size-4 text-slate-400" />
								{row.totalInvitesResponded}/
								{row.totalInvitesSent}{" "}
							</p>
						</Tooltip>
						{((row.status === "claimed" && row.totalAccepted > 1) ||
							(row.status !== "claimed" &&
								row.totalAccepted > 0)) && (
							<p className="text-sm text-green-700 font-medium flex gap-x-1 items-center pt-1">
								<CheckCircleIcon className="size-3" />
								{row.totalAccepted} geaccepteerd{" "}
								{row.status === "deleted" && (
									<span>
										(
										{Math.round(
											(new Date(row.dateFirstAcceptance) -
												new Date(row.dateCreated)) /
												60000,
										)}{" "}
										min na verwijderen)
									</span>
								)}
							</p>
						)}
					</div>
				)}
			>
				Response rate
			</th>
		</Table>
	);

	return (
		<div>
			<div className="flex items-center justify-between mb-10">
				<Heading>Plekken gemeld</Heading>
				<DateRangePicker
					value={dateRange}
					onValueChange={setDateRange}
				/>
			</div>
			<TodaysSlotsStats
				statusCounts={slotsData.statusCounts}
				occupationCounts={slotsData.occupationCounts}
			/>

			<TabGroup>
				<TabList className="mt-8">
					<Tab>Alles ({statusCounts.all})</Tab>
					<Tab>
						Gevuld ({statusCounts.claimed} -{" "}
						{Math.round(
							(statusCounts.claimed / statusCounts.all) * 100,
						)}
						%)
					</Tab>
					<Tab>
						Open ({statusCounts.open} -{" "}
						{Math.round(
							(statusCounts.open / statusCounts.all) * 100,
						)}
						%)
					</Tab>
					<Tab>
						Verwijderd ({statusCounts.deleted} -{" "}
						{Math.round(
							(statusCounts.deleted / statusCounts.all) * 100,
						)}
						%)
					</Tab>
					<Tab>
						Niemand kan ({statusCounts.failed} -{" "}
						{Math.round(
							(statusCounts.failed / statusCounts.all) * 100,
						)}
						%)
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>{renderTable(slotsData.slots)}</TabPanel>
					<TabPanel>
						{renderTable(
							slotsData.slots.filter(
								(slot) => slot.status === "claimed",
							),
						)}
					</TabPanel>
					<TabPanel>
						{renderTable(
							slotsData.slots.filter(
								(slot) => slot.status === "open",
							),
						)}
					</TabPanel>
					<TabPanel>
						{renderTable(
							slotsData.slots.filter(
								(slot) => slot.status === "deleted",
							),
						)}
					</TabPanel>
					<TabPanel>
						{renderTable(
							slotsData.slots.filter(
								(slot) => slot.status === "failed",
							),
						)}
					</TabPanel>
				</TabPanels>
			</TabGroup>
		</div>
	);
};

const TodaysSlotsStats = ({ statusCounts, occupationCounts }) => {
	const statusData = Object.entries(statusCounts).map(([name, value]) => ({
		name,
		value,
	}));
	const occupationData = Object.entries(occupationCounts).map(
		([name, value]) => ({ name, value }),
	);
	const totalSlots = Object.values(statusCounts).reduce(
		(sum, count) => sum + count,
		0,
	);

	return (
		<div className="grid grid-cols-2 gap-4 mb-8">
			<div>
				<DonutChart
					data={statusData}
					category="value"
					index="name"
					variant="pie"
					valueFormatter={(value) =>
						`${value}x (${Math.round((value / totalSlots) * 100)}%)`
					}
					colors={["green-500", "blue-600", "red-600", "yellow-600"]}
					includeLegend={true}
					className="w-20"
				/>
			</div>

			<div>
				<DonutChart
					data={occupationData}
					category="value"
					variant="pie"
					index="name"
					valueFormatter={(value) =>
						`${value}x (${Math.round((value / totalSlots) * 100)}%)`
					}
					colors={[
						"indigo-500",
						"cyan-500",
						"pink-500",
						"orange-500",
						"emerald-500",
					]}
					includeLegend={true}
					className="w-20"
				/>
			</div>
		</div>
	);
};

export default TodaysSlots;
