import React, { useEffect, useState } from "react";
import useFetch from "../../shared/hooks/useFetch";

import PatientsTableItem from "./PatientsTableItem";
import { useUser } from "@clerk/clerk-react";
import SkeletonLoading from "../../shared/components/UIElements/SkeletonLoading";
import PatientDetail from "./PatientDetail";
import { useParams } from "react-router-dom";
import EmptyState from "../../shared/components/Tables/EmptyState";
import { UserIcon } from "@heroicons/react/24/outline";

import NewPatientButton from "./NewPatientButton";
import PatientsFilters from "./PatientsFilters";

const PatientsTable = ({ selectPatients = [] }) => {
	const fetchPatients = useFetch();
	const { user } = useUser();
	const { patientId } = useParams();

	const [patients, setPatients] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [openDetail, setOpenDetail] = useState(false);
	const [filteredPatients, setFilteredPatients] = useState(patients);

	useEffect(() => {
		if (patientId !== "all") {
			setOpenDetail(true);
		}
	}, [patientId]);

	const reload = async (ignoreFilter = false) => {
		if (selectPatients.length > 0) {
			setPatients(selectPatients);
			setFilteredPatients(selectPatients);
			setIsLoading(false);
			return;
		}

		const fetchedPatients = await fetchPatients(
			`/api/patients/user/${user.id}`,
			"GET",
		);

		setPatients(fetchedPatients);
		setFilteredPatients(fetchedPatients);

		setIsLoading(false);
	};

	useEffect(() => {
		reload();
	}, [fetchPatients]);

	if (!isLoading && patients.length === 0) {
		return (
			<div className="">
				<EmptyState
					title="Je hebt nog geen patiënten in Snelterecht"
					description="Voeg nieuwe patiënten toe om je snel bellen lijst te vullen."
					icon={UserIcon}
				>
					<NewPatientButton />
				</EmptyState>
			</div>
		);
	}

	return (
		<>
			<div>
				<PatientsFilters
					patients={patients}
					onFilterChange={setFilteredPatients}
				/>
			</div>

			{!isLoading && filteredPatients.length === 0 && (
				<div className="mt-6">
					<EmptyState
						title="Er zijn geen patiënten die overeenkomen met je zoekopdracht"
						description="Wil je de patiënt toevoegen?"
						icon={UserIcon}
					>
						<NewPatientButton />
					</EmptyState>
				</div>
			)}
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								{(isLoading ||
									filteredPatients.length !== 0) && (
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Naam en patiëntnummer
											</th>
											<th
												scope="col"
												className="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Afspraak
											</th>
											<th
												scope="col"
												className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Toegevoegd
											</th>
										</tr>
									</thead>
								)}
								{isLoading && (
									<SkeletonLoading
										rows={18}
										columns={3}
										components={[
											<div className="">
												<div className="h-5 w-40 mb-2 bg-gray-200 rounded"></div>
												<div className="h-3 w-24 bg-gray-200 rounded"></div>
											</div>,

											<div className="flex items-center gap-3">
												<div className="size-10 rounded-full bg-gray-200"></div>
												<div className="">
													<div className="h-5 w-40 mb-2 bg-gray-200 rounded"></div>
													<div className="h-3 w-24 bg-gray-200 rounded"></div>
												</div>
											</div>,
										]}
									/>
								)}
								<tbody className="bg-white divide-y divide-gray-200">
									{filteredPatients.map((patient) => (
										<PatientsTableItem
											key={patient._id}
											name={patient.name}
											behandelaar={patient.behandelaar}
											id={patient._id}
											reload={reload}
											priority={patient.priority}
											patientNumber={
												patient.patientNumber
											}
											duration={
												patient?.appointment?.duration
											}
											snoozeUntil={patient?.snoozeUntil}
											phoneNumber={
												patient.phoneNumber ||
												patient.phoneNumbers[0]
											}
											createdAt={patient.createdAt}
											appointmentDate={
												patient.appointment?.datePlanned
											}
											errorInfo={patient.errors}
											notes={patient.notes}
										/>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<PatientDetail
				open={openDetail}
				setOpen={setOpenDetail}
				patientId={patientId}
				reload={reload}
			/>
		</>
	);
};

export default PatientsTable;
