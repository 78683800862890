import React, { useEffect } from "react";
import * as Frigade from "@frigade/react";
import { useFlow } from "@frigade/react";
import { useUser } from "@clerk/clerk-react";
import { CheckCircleIcon } from "@heroicons/react/16/solid";

const OnboardingChecklist = ({ setAllCompleted, isCompleted }) => {
	const { user } = useUser();
	const { flow } = useFlow("flow_l1E2bn90");

	useEffect(() => {
		if (flow) {
			setAllCompleted(flow.isCompleted);
		}
	}, [isCompleted, flow]);

	if (flow && flow.isCompleted) {
		return (
			<p className="text-green-600 font-semibold items-center flex">
				<CheckCircleIcon className="size-6 mr-2" /> Klaar! Naar de
				volgende stap.
			</p>
			// commment
		);
	}

	return (
		<Frigade.Checklist.Carousel
			flowId="flow_l1E2bn90"
			variables={{ user_id: user.id }}
			onComplete={setAllCompleted}
		/>
	);
};

export default OnboardingChecklist;
