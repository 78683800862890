import React from "react";

const Card = ({ children }) => {
	return (
		<div className="bg-white rounded-3xl shadow border border-slate-200 p-6">
			{children}
		</div>
	);
};

export default Card;
