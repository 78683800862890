import React from "react";
import DatePicker from "../../../shared/components/FormElements/DatePicker";
import { useFormikContext } from "formik";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import FieldSuggestions from "../../../shared/components/FormElements/FieldSuggestions";

const SlotDatePicker = ({ errors, touched }) => {
	const { values } = useFormikContext();

	const getNextWeekdays = () => {
		const today = dayjs().locale("nl");
		const weekdays = [];
		let daysToAdd = 1;

		while (weekdays.length < 2) {
			const nextDay = today.add(daysToAdd, "day");
			if (nextDay.day() !== 0 && nextDay.day() !== 6) {
				let label;
				if (daysToAdd === 1) {
					label = "Morgen";
				} else if (daysToAdd === 2) {
					label = "Overmorgen";
				} else {
					label = nextDay.format("dddd");
					label = label.charAt(0).toUpperCase() + label.slice(1);
				}

				weekdays.push({
					value: nextDay,
					label: label,
				});
			}
			daysToAdd++;
		}

		return weekdays;
	};

	const nextWeekdays = getNextWeekdays();

	return (
		<>
			<DatePicker label="Datum" name="unformattedDate" required />

			<FieldSuggestions
				suggestions={nextWeekdays}
				fieldName="unformattedDate"
				label="Suggesties"
				isVisible={!values.unformattedDate && values.behandelaar}
				eventTrackingFieldName="slotDate"
			/>
		</>
	);
};

export default SlotDatePicker;
