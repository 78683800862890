import React from "react";

import SignInForm from "../components/SignInForm";

import AuthFooter from "../components/AuthFooter";
import AuthPromo from "../components/AuthPromo";
import Heading from "../../shared/components/UIElements/Heading";
import MainLogo from "../../shared/components/Branding/MainLogo";
import AnimateIn from "../../shared/components/Animations/AnimateIn";

const LoginPage = () => {
	return (
		<div className="bg-white h-screen grid lg:grid-cols-2">
			<div className="grid content-between px-12 sm:px-24">
				<MainLogo className="mt-12" />

				<div>
					<AnimateIn type="fromTop">
						<div>
							<div className="pb-12">
								<Heading>Welkom terug!</Heading>
								<p className="pt-4 text-gray-700">
									Log in om door te gaan naar Snelterecht
								</p>
							</div>
						</div>
					</AnimateIn>
					<AnimateIn delay={300} type="fromTop">
						<SignInForm />
						<p className="pt-6 sm:center text-sm text-gray-800">
							Heb je nog geen account?{"  "}
							<a
								href="https://snelterecht.nl/"
								className="text-brand-500 font-semibold hover:text-brand-600 ml-1"
								target="_blank"
								rel="noreferrer"
							>
								Krijg toegang tot Snelterecht
							</a>
						</p>
					</AnimateIn>
				</div>

				<div className="mb-12 sm:flex justify-between">
					<AuthFooter />
				</div>
			</div>
			<div className="bg-brand-intense my-8 mr-8 rounded-2xl p-32 text-white hidden lg:block">
				<AuthPromo />
			</div>
		</div>
	);
};

export default LoginPage;
