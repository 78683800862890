import React, { useEffect } from "react";
import { useField } from "formik";
import FieldErrorMessage from "./FieldErrorMessage";

const CheckboxGroup = ({
	label,
	description,
	options,
	autoCheck = false,
	columns = 1,
	onChange,
	...props
}) => {
	const [field, meta, helpers] = useField(props);

	const handleCheckboxChange = (event) => {
		const { checked, value } = event.target;
		const currentValue = Array.isArray(field.value) ? field.value : [];

		let newValue;
		if (checked) {
			newValue = [...currentValue, value];
		} else {
			newValue = currentValue.filter((item) => item !== value);
		}

		helpers.setValue(newValue);

		if (onChange) {
			onChange(newValue);
		}
	};

	useEffect(() => {
		if (autoCheck && (!field.value || field.value.length === 0)) {
			const allValues = options.map((option) => option.value);
			helpers.setValue(allValues);
		}
	}, [autoCheck, options, helpers, field.value]);

	return (
		<div className={props.width ? "w-" + props.width : "w-full"}>
			<div
				className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}
			>
				<div className={"col-span-full"}>
					<label
						htmlFor={props.id || props.name}
						className="block text-sm font-medium leading-6
				text-gray-900 mb-3"
					>
						{label}
					</label>
					{description && (
						<p
							className="text-sm leading-5 text-gray-500 pb-5"
							dangerouslySetInnerHTML={{ __html: description }}
						></p>
					)}
					<div className={`grid grid-cols-${columns} gap-4`}>
						{options.map((option, index) => (
							<div
								key={index}
								className="relative flex items-start"
							>
								<label className="flex items-center">
									<input
										{...props}
										type="checkbox"
										value={option.value}
										checked={field.value?.includes(
											option.value,
										)}
										onChange={handleCheckboxChange}
										id={`${props.name}-${option.value}`}
										className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded cursor-pointer"
									/>
									<div className="ml-3 text-sm">
										<span className="text-gray-700 cursor-pointer">
											{option.label}
										</span>
										{option.description && (
											<p
												id={`${option.value}-description`}
												className="text-gray-500 cursor-pointer"
											>
												{option.description}
											</p>
										)}
									</div>
								</label>
							</div>
						))}
						<FieldErrorMessage meta={meta} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckboxGroup;
