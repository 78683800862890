import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import PhoneInputField from "./PhoneInput";
import { PlusIcon, MinusIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { TrashIcon } from "@heroicons/react/24/outline";

const MultiplePhoneInputs = ({ maxPhones = 3, amountOfPhones = 1 }) => {
	const { values, setFieldValue } = useFormikContext();
	const [phoneFields, setPhoneFields] = useState(["phoneNumber"]);

	useEffect(() => {
		const fields = ["phoneNumber"];
		if (values.phoneNumber2) fields.push("phoneNumber2");
		if (values.phoneNumber3) fields.push("phoneNumber3");
		setPhoneFields(fields);
	}, [values.phoneNumber, values.phoneNumber2, values.phoneNumber3]);

	const handleAddPhone = (e) => {
		e.preventDefault();
		const currentCount = phoneFields.length;
		if (currentCount < maxPhones) {
			const newField = `phoneNumber${
				currentCount > 0 ? currentCount + 1 : ""
			}`;
			setPhoneFields([...phoneFields, newField]);
			setFieldValue(newField, "");
		}
	};

	const handleRemovePhone = (e, name) => {
		e.preventDefault();
		setFieldValue(name, undefined);
		const newPhoneFields = phoneFields.filter((field) => field !== name);
		setPhoneFields(newPhoneFields);
	};

	return (
		<div>
			{phoneFields.map((field, index) => (
				<div key={field} className="mb-4">
					<PhoneInputField
						label={`Telefoonnummer ${index > 0 ? index + 1 : ""}`}
						name={field}
						allowMultiple={true}
						phoneIndex={index + 1}
						maxAmountOfPhones={maxPhones}
						onAddNewNumber={handleAddPhone}
					/>
					{index > 0 && (
						<button
							type="button"
							onClick={(e) => handleRemovePhone(e, field)}
							className="text-slate-500 flex items-center text-sm gap-1 pt-2"
						>
							<MinusIcon className="size-4" />
							<p className="">Weghalen</p>
						</button>
					)}
				</div>
			))}
		</div>
	);
};

export default MultiplePhoneInputs;
