import React, { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import RadioGroup from "../../shared/components/FormElements/RadioGroup";
import useFetch from "../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";

const LocationPicker = () => {
	const [locations, setLocations] = useState([]);
	const { user } = useUser();
	const fetchLocations = useFetch();
	const [field] = useField("location");
	const { setFieldValue } = useFormikContext();

	useEffect(() => {
		const getLocations = async () => {
			try {
				const fetchedLocations = await fetchLocations(
					`/api/practice/user/${user.id}/locations`,
					"GET",
				);
				setLocations(fetchedLocations);

				if (fetchedLocations.length === 1) {
					setFieldValue("location", fetchedLocations[0]._id);
				} else if (fetchedLocations.length === 0) {
					setFieldValue("location", null);
				}
			} catch (error) {
				console.error("Error fetching locations:", error);
			}
		};

		if (user) {
			getLocations();
		}
	}, [user, fetchLocations, setFieldValue]);

	if (locations.length < 2) {
		return null;
	}

	return (
		<RadioGroup
			{...field}
			name="location"
			label="Locatie"
			options={locations.map((location) => ({
				label: location.name,
				value: location._id,
			}))}
		/>
	);
};

export default LocationPicker;
