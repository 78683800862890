import React from "react";
import PortalHeader from "../components/PortalHeader";

const PortalLayout = ({ children, fullHeight = true }) => {
	return (
		<div className="min-h-[100dvh] flex flex-col">
			<div className="px-6 pt-16 h-[15dvh]">
				<PortalHeader />
			</div>
			<div className="h-[85dvh] px-6 pb-6">{children}</div>
		</div>
	);
};

export default PortalLayout;
