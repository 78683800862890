import React from "react";
import { Legend, DonutChart as TremorDonutChart } from "@tremor/react";

const DonutChart = ({
	data,
	variant,
	valueFormatter,
	onValueChange,
	includeLegend = false,
	...props
}) => {
	return (
		<div className="flex gap-x-4">
			<TremorDonutChart
				data={data}
				variant={variant}
				valueFormatter={valueFormatter}
				onValueChange={onValueChange}
				{...props}
			/>
			{includeLegend && (
				<Legend
					categories={data.map((d) => d.name)}
					colors={props.colors}
					className={props.legendClassName}
				/>
			)}
		</div>
	);
};

export default DonutChart;
