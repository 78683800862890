import {
	ArrowLeftIcon,
	CheckBadgeIcon,
	CheckCircleIcon,
	CheckIcon,
} from "@heroicons/react/16/solid";
import React from "react";
import Button from "../components/PortalButton";

const UpdatedSuccessfullyPage = ({
	title = "Beschikbaarheid opgeslagen",
	patientId,
	patientKey,
	children,
}) => {
	return (
		<div className="flex flex-col h-[100vh] px-6 pt-24">
			<div className="bg-green-50 border border-green-100 py-2 px-3 rounded-lg text-green-800 mb-6 flex items-top">
				<CheckCircleIcon className="size-4 text-green-600 mr-2 mt-1" />
				{title}
			</div>
			<div>{children}</div>
		</div>
	);
};

export default UpdatedSuccessfullyPage;
