import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import PortalLayout from "./PortalLayout";

const SlotAlreadyClaimedPage = () => {
	return (
		<PortalLayout>
			<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-100">
				<XMarkIcon
					className="h-6 w-6 text-orange-600"
					aria-hidden="true"
				/>
			</div>
			<h1 className="text-2xl font-bold pt-6">
				Deze plek is al bevestigd door iemand anders
			</h1>
			<p className="mt-4 text-gray-600">
				Helaas, een andere patiënt was u voor. We geven de plek op basis
				van wie het eerst op de bevestigingsknop klikt.
			</p>
		</PortalLayout>
	);
};

export default SlotAlreadyClaimedPage;
