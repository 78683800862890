import React from "react";
import { useFormikContext } from "formik";
import classNames from "classnames";

import { SparklesIcon } from "@heroicons/react/16/solid";

import Tooltip from "../UIElements/Tooltip";
import posthog from "posthog-js";

const FieldSuggestions = ({
	suggestions,
	fieldName,
	isVisible,
	label,
	eventTrackingFieldName,
}) => {
	const formik = useFormikContext();

	if (!formik) {
		console.warn("FieldSuggestions: No Formik context found");
		return null;
	}

	if (!fieldName) {
		console.warn("FieldSuggestions: No fieldName provided");
		return null;
	}

	const { setFieldValue, getFieldProps } = formik;

	const fieldExists = getFieldProps(fieldName) !== undefined;
	if (!fieldExists) {
		console.warn(
			`FieldSuggestions: Field "${fieldName}" not found in form`,
		);
		return null;
	}

	const handleSuggestion = (value) => {
		if (!value && value !== 0) {
			console.warn(
				"FieldSuggestions: Attempted to set undefined/null value",
			);
			return;
		}

		try {
			setFieldValue(fieldName, value);

			posthog.capture("🏷️ Veldsuggestie geaccepteerd", {
				field: eventTrackingFieldName || fieldName,
				suggestion: value,
			});
		} catch (error) {
			console.error("Error setting field value:", error);
			posthog.capture("🏷️ Veldsuggestie error", {
				field: eventTrackingFieldName || fieldName,
				suggestion: value,
				error: error.message,
			});
		}
	};

	const handleClick = (e, value) => {
		e.preventDefault();
		e.stopPropagation();
		handleSuggestion(value);
	};

	return (
		<>
			<div
				className={classNames(
					"flex items-center gap-x-2 text-sm transition-all duration-300 ease-in-out",
					{
						"opacity-100 max-h-20 pb-4 pt-4": isVisible,
						"opacity-0 max-h-0 pb-0 pt-0 pointer-events-none":
							!isVisible,
					},
				)}
			>
				<Tooltip content="Suggesties" id={`suggestion_${fieldName}`}>
					<p className="text-xs text-gray-500 items-center flex gap-x-1">
						<SparklesIcon className="w-4 h-4" />
						{label && <span>{label}</span>}
					</p>
				</Tooltip>
				{suggestions.map(({ label, value }) => (
					<button
						key={label}
						type="button"
						onClick={(e) => handleClick(e, value)}
						className={classNames(
							"rounded-full px-2 py-[5px] text-xs border border-dashed",
							"text-gray-600 hover:text-gray-900",
							"border-gray-400 hover:bg-slate-50 hover:border-gray-500",
							"transition-all duration-300",
						)}
					>
						{label}
					</button>
				))}
			</div>
		</>
	);
};

export default FieldSuggestions;
