import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import useFetch from "../../../shared/hooks/useFetch";
import Heading from "../../../shared/components/UIElements/Heading";
import ColorPicker from "../../../shared/components/FormElements/ColorPicker";
import Button from "../../../shared/components/FormElements/Button";

import UploadFile from "../../../shared/components/FormElements/UploadFile";
import { useUser } from "@clerk/clerk-react";

import { PortalProvider } from "../../../public/context/PortalContext";
import PatientPortal from "../../../public/pages/PatientPortal";
import { toast } from "react-toastify";
import PreviewPatientPortal from "../../../admin/components/PreviewPatientPortal";

const CustomizePatientPortal = () => {
	const [previewSettings, setPreviewSettings] = useState(null);

	return (
		<PortalProvider>
			<Heading size="2">Bewerk je patiëntenportaal</Heading>
			<p className="text-slate-700 pt-2 mb-8">
				Hier kunnen patiënten zelf hun beschikbaarheid en vakanties
				doorgeven.
			</p>
			<div className="flex gap-8">
				<div className="w-1/2">
					<CustomizePortalForm
						setPreviewSettings={setPreviewSettings}
						previewSettings={previewSettings}
					/>
				</div>
				<div className="w-1/2 border-l pl-8">
					{previewSettings && (
						<PreviewPatientPortal
							previewSettings={previewSettings}
						/>
					)}
				</div>
			</div>
		</PortalProvider>
	);
};

const CustomizePortalForm = ({ previewSettings, setPreviewSettings }) => {
	const [portalSettings, setPortalSettings] = useState(null);
	const fetch = useFetch();
	const { user } = useUser();

	useEffect(() => {
		const fetchPractice = async () => {
			const res = await fetch(`/api/practice/user/${user.id}`);
			setPortalSettings(res.settings.patientPortal);
			setPreviewSettings(res.settings.patientPortal);
		};
		fetchPractice();
	}, []);

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			const formData = new FormData();
			formData.append("primaryColor", values.primaryColor);
			if (values.logo && values.logo.file) {
				formData.append("logo", values.logo.file);
			}

			const res = await fetch(
				`/api/practice/user/${user.id}/customize-patient-portal`,
				{
					method: "PUT",
					body: formData,
				},
			);

			toast.success("Patiëntenportaal succesvol aangepast", {
				position: "bottom-center",
			});
			setPreviewSettings(res.settings.patientPortal);
			setPortalSettings(res.settings.patientPortal);

			await user.update({
				unsafeMetadata: {
					...user.unsafeMetadata,
					onboarding: {
						...user.unsafeMetadata.onboarding,
						patientPortalCustomized: true,
					},
				},
			});
		} catch (err) {
			console.log(err);
		}
		setSubmitting(false);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				primaryColor: portalSettings?.primaryColor || "#7634DE",
				logo: {
					preview: portalSettings?.logoUrl
						? `${portalSettings?.logoUrl}?${new Date().getTime()}`
						: null,
				},
			}}
			onSubmit={handleSubmit}
		>
			{({ values }) => (
				<Form>
					<div className="space-y-12">
						<ColorPicker
							label="Primaire kleur"
							name="primaryColor"
						/>

						<UploadFile
							name="logo"
							label="Logo"
							initialPreview={
								portalSettings?.logoUrl
									? `${portalSettings?.logoUrl}?${new Date().getTime()}`
									: null
							}
						/>

						<Button size="medium" type="submit">
							Opslaan
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default CustomizePatientPortal;
