import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
	unformattedDate: Yup.mixed().nullable().required("Vul de datum in"),
	startTime: Yup.string()
		.matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Ongeldige starttijd")
		.required("Starttijd is verplicht"),
	endTime: Yup.string()
		.matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Ongeldige eindtijd")
		.required("Eindtijd is verplicht")
		.test(
			"is-after-start-time",
			"Eindtijd moet na starttijd zijn",
			function (endTime) {
				const { startTime } = this.parent;
				return !startTime || !endTime || endTime > startTime;
			},
		),
	behandelaar: Yup.string().required("Behandelaar is verplicht"),
});
