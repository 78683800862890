import React from "react";
import Tooltip from "../UIElements/Tooltip";
import { InformationCircleIcon } from "@heroicons/react/16/solid";

const Label = ({ name, label, description, optional = false, id, tooltip }) => {
	return (
		<>
			<label
				htmlFor={id || name}
				className="flex text-sm font-medium leading-6 text-gray-900 mb-1.5 items-center"
			>
				{label}
				{optional && (
					<span className="text-xs font-normal ml-1 text-gray-500">
						(optioneel)
					</span>
				)}
				{tooltip && (
					<div className="ml-2 cursor-help text-base">
						<Tooltip content={tooltip}>
							<InformationCircleIcon className="h-4 w-4 text-gray-400" />
						</Tooltip>
					</div>
				)}
			</label>

			{description && (
				<p
					className="text-sm leading-5 text-gray-500 pb-2"
					dangerouslySetInnerHTML={{ __html: description }}
				></p>
			)}
		</>
	);
};

export default Label;
