import React, { useEffect, useState } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import Heading from "../../../shared/components/UIElements/Heading";
import Table from "../../../shared/components/Tables/Table";
import classNames from "classnames";
import Button from "../../../shared/components/FormElements/Button";
import Modal from "../../../shared/components/UIElements/Modal";
import { EyeIcon } from "@heroicons/react/20/solid";

const formatMetadata = (metadata) => {
	return JSON.stringify(metadata, null, 2);
};

const highlightDifferences = (str1, str2) => {
	const result = [];
	for (let i = 0; i < Math.max(str1?.length, str2?.length); i++) {
		if (str1[i] !== str2[i]) {
			result.push(
				<span key={i} className="text-red-500">
					{str1[i] || ""}
				</span>,
			);
		} else {
			result.push(str1[i] || "");
		}
	}
	return result;
};

const IntegrationResultsExperiment = () => {
	const fetchData = useFetch();
	const { user } = useUser();
	const [integrationResults, setIntegrationResults] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [row, setRow] = useState(null);

	useEffect(() => {
		const fetchIntegrationResults = async () => {
			setIsLoading(true);
			const data = await fetchData(
				`/api/admin/integration-experiment/${user.id}`,
			);
			setIntegrationResults(data);
			setIsLoading(false);
		};
		fetchIntegrationResults();
	}, [fetchData, user.id]);

	return (
		<>
			<div className="flex justify-between items-center">
				<Heading>Koppeling: Experiment</Heading>
			</div>

			<div className="mt-10 mb-8">
				<Table data={integrationResults} isLoading={isLoading}>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<p className="font-medium text-black">
								{row.software}
							</p>
						)}
					>
						Praktijksoftware
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<pre
								className={classNames(
									"text-xs p-2 rounded bg-gray-100",
								)}
							>
								{highlightDifferences(
									formatMetadata(
										row.textExperiment.patientData,
									),
									formatMetadata(row.patientData),
								)}
							</pre>
						)}
					>
						OCR + AI
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<pre
								className={classNames(
									"text-xs p-2 rounded bg-gray-100",
								)}
							>
								{highlightDifferences(
									formatMetadata(row.patientData),
									formatMetadata(
										row.textExperiment.patientData,
									),
								)}
							</pre>
						)}
					>
						Image + AI (default)
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<Button
								size="medium"
								ghost
								onClick={() => {
									setRow(row);
									setOpen(true);
								}}
								iconPrefix={EyeIcon}
							/>
						)}
					>
						Details
					</th>
				</Table>
			</div>
			<DetailModal row={row} open={open} setOpen={setOpen} />
		</>
	);
};

const DetailModal = ({ row, open, setOpen }) => {
	return (
		<Modal open={open} setOpen={setOpen}>
			<div className="px-6 pb-8">
				<div className="grid grid-cols-2 gap-2">
					<div>
						<p className="font-medium text-black">
							Image + AI (default)
						</p>
						<pre
							className={classNames(
								"text-xs p-2 rounded bg-gray-100",
							)}
						>
							{highlightDifferences(
								formatMetadata(row?.patientData),
								formatMetadata(
									row?.textExperiment?.patientData,
								),
							)}
						</pre>
					</div>
					<div>
						<p className="font-medium text-black">Text + AI</p>
						<pre
							className={classNames(
								"text-xs p-2 rounded bg-gray-100",
							)}
						>
							{highlightDifferences(
								formatMetadata(
									row?.textExperiment?.patientData,
								),
								formatMetadata(row?.patientData),
							)}
						</pre>
					</div>
				</div>
				<div className="mt-8">
					<p className="font-medium text-black">OCR</p>
					<pre className="text-xs p-2 rounded bg-gray-100">
						{row?.ocrText}
					</pre>
				</div>
			</div>
		</Modal>
	);
};

export default IntegrationResultsExperiment;
