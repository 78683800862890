import { CheckIcon } from "@heroicons/react/16/solid";
import classNames from "classnames";

const SlotSteps = ({ steps, currentStep }) => {
	return (
		<nav aria-label="Progress" className="flex items-center gap-x-2">
			<p className="text-sm font-semibold text-green-600 text-right pr-2 w-24">
				Stap {currentStep + 1}/{steps.length}
			</p>
			<ol className="flex items-center w-full gap-x-2">
				{steps.map((step, stepIdx) => (
					<div
						key={step.name}
						className={classNames(
							"h-2 w-20 rounded-full transition-all duration-300 ease-in-out",
							{
								"bg-[#36bf3d]": step.status === "complete",
								"bg-slate-200": step.status !== "complete",
							}
						)}
					></div>
				))}
			</ol>
		</nav>
	);
};

export default SlotSteps;
