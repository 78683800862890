import React, { useState } from "react";
import LocationsChecklist from "../../../shared/components/FormElements/LocationsChecklist";
import { Form, Formik } from "formik";
import Button from "../../../shared/components/FormElements/Button";
import { toast } from "react-toastify";
import { ComputerDesktopIcon } from "@heroicons/react/16/solid";
import classNames from "classnames";

const LocationPreferences = () => {
	const [hasLocations, setHasLocations] = useState(false);

	const handleSubmit = (values) => {
		try {
			localStorage.setItem(
				"locationPreferences",
				JSON.stringify(values.locations),
			);
			toast.success("Locatie voorkeuren opgeslagen", {
				position: "bottom-center",
			});
		} catch (error) {
			console.error("Error saving location preferences:", error);
			toast.error("Er ging iets mis bij het opslaan van de voorkeuren");
		}
	};

	return (
		<div
			className={classNames(
				"p-8 bg-slate-50 bg-opacity-50 rounded-3xl border border-slate-200",
				!hasLocations && "hidden",
			)}
		>
			<span className="flex items-center gap-1 text-sm font-bold text-brand-intense mb-2">
				<ComputerDesktopIcon className="size-4" />
				<span className="uppercase">Alleen voor deze computer</span>
			</span>
			<h2 className="text-xl font-bold">Locatie voorkeuren</h2>
			<p className="text-sm text-slate-700 pt-2">
				Selecteer de locaties die standaard geselecteerd moeten zijn bij
				het aanmaken van een patiënt
			</p>
			<Formik initialValues={{ locations: [] }} onSubmit={handleSubmit}>
				<Form>
					<LocationsChecklist
						name="locations"
						label="Standaard locaties"
						setHasLocations={setHasLocations}
						useLocalStorage={true}
					/>

					<div className="mt-4">
						<Button type="submit" size="medium">
							Opslaan
						</Button>
					</div>
				</Form>
			</Formik>
		</div>
	);
};

export default LocationPreferences;
