import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { CheckIcon, MapPinIcon } from "@heroicons/react/16/solid";
import { toast } from "react-toastify";

import useFetch from "../../shared/hooks/useFetch";
import Heading from "../../shared/components/UIElements/Heading";
import EmailTagsInput from "../../shared/components/FormElements/TagInputWithoutSuggestions";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import OneSignal from "react-onesignal";
import Button from "../../shared/components/FormElements/Button";
import CustomTagInput from "../../shared/components/FormElements/CustomTagInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const NotificationSettings = () => {
	const [settings, setSettings] = useState({});
	const [locations, setLocations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const updatePractice = useFetch();
	const { user } = useUser();

	const handleSave = async (values) => {
		const updatedLocations = locations.map((location) => {
			return {
				...location,
				notifications: {
					...location.notifications,
					emailAddresses: values.locations[location._id].emails,
				},
			};
		});

		setLocations(updatedLocations);

		try {
			await updatePractice(
				`/api/practice/user/${user.id}/location-notifications`,
				{
					method: "PUT",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ locations: updatedLocations }),
				},
			);

			toast.success("Meldingsinstellingen gewijzigd", {
				position: "bottom-center",
				theme: "light",
				hideProgressBar: true,
				autoClose: 2000,
				icon: (
					<CheckIcon className="text-green-500" aria-hidden="true" />
				),
			});
		} catch (error) {
			toast.error("Er ging iets mis bij het opslaan");
		}
	};

	const turnOnPushNotifications = async (e) => {
		e.preventDefault();
		await OneSignal.Slidedown.promptPush({ force: true });
	};

	useEffect(() => {
		const getPractice = async () => {
			const practiceReq = await updatePractice(
				`/api/practice/user/${user.id}`,
				"GET",
			);

			setSettings(practiceReq.settings);
			setLocations(practiceReq.locations);
			setIsLoading(false);
		};

		getPractice();
	}, [updatePractice, user]);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	const initialValues = {
		locations: locations.reduce((acc, location) => {
			const hasEmailAddresses =
				location.notifications?.emailAddresses?.length > 0;
			acc[location._id] = {
				emails: hasEmailAddresses
					? location.notifications.emailAddresses
					: [settings.emailAddress],
			};
			return acc;
		}, {}),
	};

	return (
		<>
			<Heading size="2">Meldingen</Heading>
			<p className="pt-2 pb-6 text-gray-600">
				Krijg een melding zodra er een lege plek in de agenda wordt
				opgevuld. Zo weet je dat je een afspraak in de praktijkagenda
				moet verzetten.
			</p>
			<div>
				<div className="border-b border-gray-200 pb-6">
					<div className="flex items-center justify-between pt-8 pb-2">
						<span className="flex-grow flex flex-col">
							<p className="text-sm font-medium text-gray-900">
								Push notificaties
							</p>
							<p className="text-sm text-gray-500">
								Stuur een push melding naar de receptie wanneer
								er een lege plek in de agenda wordt opgevuld
							</p>
						</span>

						{OneSignal.User.PushSubscription.optedIn ? (
							<p className="text-sm font-semibold text-green-600 flex items-center">
								<CheckIcon className="size-4 mr-2" />
								Ingeschakeld
							</p>
						) : (
							<button
								className="text-brand-600 font-semibold text-sm"
								onClick={turnOnPushNotifications}
							>
								Instellen
							</button>
						)}
					</div>
				</div>
				<div>
					<h3 className="text-md font-bold mb-2 mt-8">
						Email meldingen
					</h3>
					<p className="text-sm text-gray-500">
						Je kunt meerdere e-mailadressen kiezen. Zorg ervoor dat
						tenminste het e-mailadres van de receptie erbij staat.
					</p>

					<div className="space-y-8 mt-8">
						<Formik
							initialValues={initialValues}
							onSubmit={handleSave}
							validationSchema={Yup.object().shape({
								locations: Yup.object().shape(
									locations.reduce(
										(acc, location) => ({
											...acc,
											[location._id]: Yup.object().shape({
												emails: Yup.array()
													.min(
														1,
														"Voeg tenminste één e-mailadres toe",
													)
													.required(
														"E-mailadressen zijn verplicht",
													),
											}),
										}),
										{},
									),
								),
							})}
						>
							<Form>
								<div className="space-y-8 mb-8">
									{locations.map((location) => (
										<div key={location._id}>
											<div className="flex items-center gap-1 text-brand-intense font-semibold text-md">
												<MapPinIcon className="size-4" />
												{location.name}
											</div>

											<CustomTagInput
												name={`locations.${location._id}.emails`}
												placeholder="Voer een e-mailadres in en klik op enter..."
											/>
										</div>
									))}
								</div>
								<Button type="submit" size="medium">
									Opslaan
								</Button>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotificationSettings;
