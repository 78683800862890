import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	UsersIcon,
	SparklesIcon,
} from "@heroicons/react/16/solid";
import { CalendarIcon, ClockIcon, UserIcon } from "@heroicons/react/24/outline";
import useFetch from "../../../shared/hooks/useFetch";
import Button from "../../../shared/components/FormElements/Button";
import FormattedDate from "../../../shared/components/util/FormattedDate";
import SelectPatientsTable from "../../../patients/components/SelectPatientsTable";
import PatientFilters from "./PatientFilters";
import AnimateIn from "../../../shared/components/Animations/AnimateIn";
import EmptyState from "../../../shared/components/Tables/EmptyState";
import { UserGroupIcon } from "@heroicons/react/20/solid";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";

const NewSlotPatients = ({
	slotID,
	onChosenPatients,
	onBack,
	selectedPatients,
	setSelectedPatients,
}) => {
	const [allPatients, setAllPatients] = useState([]);
	const [filteredPatients, setFilteredPatients] = useState([]);
	const [notAvailablePatients, setNotAvailablePatients] = useState([]);
	const [slot, setSlot] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [availableTreatments, setAvailableTreatments] = useState([]);
	const [showTreatmentFilter, setShowTreatmentFilter] = useState(false);
	const [creatiefVullenPatients, setCreatiefVullenPatients] = useState([]);
	const [isLoadingCreatiefVullen, setIsLoadingCreatiefVullen] =
		useState(false);

	const fetchPatients = useFetch();

	// Extract filter logic to a separate function
	const applyFilters = useCallback((patients, filters) => {
		if (!filters || !patients) return patients;

		return patients.filter((patient) => {
			const matchesAppointmentType =
				!filters.appointmentType ||
				filters.appointmentType === "all" ||
				(filters.appointmentType === "noAppointment" &&
					!patient.appointment?.datePlanned) ||
				(filters.appointmentType === "hasAppointment" &&
					patient.appointment?.datePlanned);

			const matchesTreatment =
				!filters.treatments?.length ||
				(patient.appointment?.treatmentType &&
					filters.treatments.includes(
						patient.appointment.treatmentType._id,
					));

			return matchesAppointmentType && matchesTreatment;
		});
	}, []);

	const getPatients = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await fetchPatients(
				`/api/slots/${slotID}/patients`,
			);

			setAllPatients(response.eligiblePatients);
			setFilteredPatients(response.eligiblePatients);
			setNotAvailablePatients(response.notAvailablePatients);
			setSlot(response.slot);

			// If no eligible patients found, fetch creatief vullen patients
			if (response.eligiblePatients.length === 0) {
				setIsLoadingCreatiefVullen(true);
				const creatiefResponse = await fetchPatients(
					`/api/slots/${slotID}/creatief-vullen`,
				);
				setCreatiefVullenPatients(creatiefResponse.available);
				setIsLoadingCreatiefVullen(false);
			}

			setShowTreatmentFilter(
				response.slot.behandelaar?.occupation?.includeTreatments ||
					false,
			);

			return response;
		} catch (error) {
			console.error("Failed to fetch patients:", error);
		} finally {
			setIsLoading(false);
		}
	}, [slotID, fetchPatients]);

	const handleFilterChange = useCallback(
		(newFilters) => {
			const filtered = applyFilters(allPatients, newFilters);
			setFilteredPatients(filtered);

			// Select all filtered patients automatically
			const filteredIds = filtered.map((p) => p._id);
			setSelectedPatients(filteredIds);
		},
		[allPatients, applyFilters, setSelectedPatients],
	);

	// Calculate valid selected patients - update to include creatiefVullen patients
	const validSelectedPatients = useMemo(
		() =>
			selectedPatients.filter((id) =>
				filteredPatients.length > 0
					? filteredPatients.some((p) => p._id === id)
					: creatiefVullenPatients.some((p) => p._id === id),
			),
		[selectedPatients, filteredPatients, creatiefVullenPatients],
	);

	const choosePatients = useCallback(async () => {
		// Always use the regular patients endpoint
		await fetchPatients(`/api/slots/${slotID}/patients`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				patients: validSelectedPatients,
			}),
		});
		onChosenPatients(validSelectedPatients);
	}, [slotID, validSelectedPatients, fetchPatients, onChosenPatients]);

	useEffect(() => {
		getPatients().then((response) => {
			if (!response) return;

			if (selectedPatients.length === 0) {
				setSelectedPatients(
					response.eligiblePatients.map((p) => p._id),
				);
			} else {
				setSelectedPatients((prev) =>
					prev.filter((id) =>
						response.eligiblePatients.some((p) => p._id === id),
					),
				);
			}

			const uniqueTreatments = [
				...response.eligiblePatients,
				...response.notAvailablePatients,
			]
				.map((patient) => patient.appointment?.treatmentType)
				.filter(Boolean)
				.filter(
					(treatment, index, self) =>
						index ===
						self.findIndex((t) => t._id === treatment._id),
				);

			setAvailableTreatments(uniqueTreatments);
		});
	}, [getPatients, setSelectedPatients]);

	const SlotInfo = () => (
		<div className="flex mt-5 space-x-3 text-sm text-gray-500">
			<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200">
				<UserIcon className="h-5 w-5 text-slate-600 mr-3" />
				<span className="text-slate-700">
					{slot.behandelaar.firstName} {slot.behandelaar.lastName}
				</span>
			</p>
			<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200 text-slate-700">
				<CalendarIcon className="h-5 w-5 text-slate-600 mr-3" />
				<FormattedDate date={slot.date} />
			</p>
			<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200">
				<ClockIcon className="h-5 w-5 text-slate-600 mr-3" />
				<span className="text-slate-700">
					{slot.startTime} - {slot.endTime} uur ({slot.duration} min)
				</span>
			</p>
		</div>
	);

	return (
		<>
			<AnimateIn>
				<div className="bg-white rounded-2xl border border-slate-200 px-10 py-12 mb-14">
					<h3 className="text-xl leading-6 font-bold text-gray-900 flex items-center gap-2">
						<UsersIcon className="size-5 text-brand-intense" />
						Welke patiënten wil je uitnodigen?
						<span className="font-medium text-gray-500 text-sm">
							({filteredPatients.length} komen in aanmerking)
						</span>
					</h3>

					{isLoading && (
						<div className="flex items-center mt-6 ml-1">
							<LoadingSpinner fullHeight={false} />
							<p className="text-gray-600">
								Wachtlijst aan het analyseren...
							</p>
						</div>
					)}

					{filteredPatients.length === 0 && !isLoading && (
						<div className="mt-6">
							<div className="flex items-start gap-3">
								<div className="w-full">
									{creatiefVullenPatients.length > 0 ? (
										<>
											<div className="bg-orange-50 px-4 py-2 border-orange-200 border rounded-xl font-medium text-orange-900 w-fit">
												Er zijn géén patiënten die
												precies passen...
											</div>
											<p className="text-gray-600 mt-3">
												Maar we hebben wel{" "}
												{creatiefVullenPatients.length}{" "}
												patiënten gevonden met een
												afspraak die 5-10 minuten langer
												duurt dan de tijd die je
												beschikbaar hebt. Wil je deze
												patiënten toch uitnodigen?
											</p>
										</>
									) : (
										<EmptyState
											title="Geen patiënten die passen"
											description="Helaas... Geen enkele patiënt komt in aanmerking voor deze plek."
											icon={UserGroupIcon}
										/>
									)}
								</div>
							</div>

							{creatiefVullenPatients.length > 0 && (
								<div className="mt-6">
									{/* <h3 className="text-lg font-bold text-gray-900 -mb-3">
										Alternatieve patiënten
									</h3> */}
									<SelectPatientsTable
										patients={creatiefVullenPatients}
										notAvailablePatients={
											notAvailablePatients
										}
										isLoading={isLoadingCreatiefVullen}
										selectedPatients={selectedPatients}
										setSelectedPatients={
											setSelectedPatients
										}
										slotDuration={slot.duration}
										creatiefVullen={true}
									/>
								</div>
							)}
						</div>
					)}

					{filteredPatients.length > 0 && (
						<>
							<p className="mt-1 text-sm text-gray-500">
								Deze patiënten komen in aanmerking voor de lege
								plek in de agenda, wie wil je uitnodigen?
							</p>
							{slot.behandelaar && <SlotInfo />}

							<div className="flex gap-10">
								<div>
									<PatientFilters
										onFilterChange={handleFilterChange}
										availableTreatments={
											availableTreatments
										}
										showTreatmentFilter={
											showTreatmentFilter
										}
									/>
								</div>
								<div className="w-full">
									<SelectPatientsTable
										patients={filteredPatients}
										notAvailablePatients={
											notAvailablePatients
										}
										isLoading={isLoading}
										selectedPatients={selectedPatients}
										setSelectedPatients={
											setSelectedPatients
										}
										slotDuration={slot.duration}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</AnimateIn>
			<div className="shadow-2xl fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 flex gap-2 justify-start">
					<Button onClick={onBack} ghost iconPrefix={ArrowLeftIcon}>
						Terug
					</Button>
					<Button
						onClick={choosePatients}
						disabled={validSelectedPatients.length === 0}
						iconSuffix={ArrowRightIcon}
					>
						Naar benaderplan{" "}
						<span className="font-normal pl-1 opacity-80">
							({validSelectedPatients.length} patiënten)
						</span>
					</Button>
				</div>
			</div>
		</>
	);
};

const NoPatientsFound = () => (
	<div className="mt-6">
		<EmptyState
			title="Er zijn geen patiënten gevonden die precies passen"
			description="Probeer de filters aan te passen"
		>
			hoi
		</EmptyState>
	</div>
);

export default NewSlotPatients;
