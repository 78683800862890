import { toast } from "react-toastify";

export const handleSubmit = async (
	values,
	createSlot,
	user,
	onSlotDetailsSubmit,
) => {
	try {
		const date = values.unformattedDate.format("YYYY-MM-DD");

		const slotDetails = {
			...values,
			date,
			creatorId: user.id,
		};

		const slotID = await createSlot("/api/slots", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(slotDetails),
		});

		if (slotID.error) {
			toast.error(slotID.error);
			return;
		}

		onSlotDetailsSubmit({
			...slotDetails,
			slotID,
		});
	} catch (error) {
		if (error.response && error.response.status === 400) {
			toast.error(
				error.response.data.message ||
					"Ongeldige datum- of tijdnotatie",
			);
		} else {
			console.log(error);
			toast.error("Fout bij het melden van een lege plek");
		}
	}
};
