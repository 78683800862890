import React, { useState, useEffect } from "react";
import { AreaChart } from "@tremor/react";
import useFetch from "../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { ChartBarIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import SkeletonLoadingItem from "../../shared/components/UIElements/SkeletonLoadingItem";
import NumberFlow from "@number-flow/react";
import AnimateIn from "../../shared/components/Animations/AnimateIn";

const SlotsStats = () => {
	const [stats, setStats] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [chartdata, setChartData] = useState([]);

	const fetchStats = useFetch();
	const { user } = useUser();

	useEffect(() => {
		const fetchStatistics = async () => {
			const response = await fetchStats(
				`/api/practice/user/${user.id}/slots-stats`,
			);
			setIsLoading(false);
			setStats(response);
		};

		fetchStatistics();
	}, []);

	useEffect(() => {
		if (stats.weeklyStats && stats.weeklyStats.length > 0) {
			const data = stats.weeklyStats.map((stat) => ({
				date: stat.week,
				"Plekken gevuld": stat.slotsFilled,
			}));
			setChartData(data);
		}
	}, [stats.weeklyStats]);

	// if (isLoading || !stats)
	// 	return (
	// 		<div className="mt-3 mb-10">
	// 			<div className="flex gap-2 items-center mb-3">
	// 				<ChartBarIcon className="size-4 text-slate-400" />
	// 				<h3 className="text-sm font-semibold text-slate-500 uppercase">
	// 					Afgelopen 30 dagen
	// 				</h3>
	// 			</div>
	// 			<div className="flex gap-3 [&>div]:w-1/3 [&>div]:justify-between [&>div]:gap-3 [&>div]:items-center">
	// 				<SkeletonLoadingItem
	// 					width="w-1/3"
	// 					height="h-32"
	// 					color="slate-200"
	// 					rounded="2xl"
	// 				/>

	// 				<SkeletonLoadingItem
	// 					width="w-1/3"
	// 					height="h-32"
	// 					color="slate-200"
	// 					rounded="2xl"
	// 				/>
	// 			</div>
	// 		</div>
	// 	);

	if (!isLoading && stats?.totalSlotsFilledLastMonth < 2) return null;

	return (
		<div className="mt-3 mb-10">
			<div className="flex gap-2 items-center mb-3">
				<ChartBarIcon className="size-4 text-slate-400" />
				<h3 className="text-sm font-semibold text-slate-500 uppercase">
					Afgelopen 30 dagen
				</h3>
			</div>
			<div className="flex gap-3 [&>div]:w-1/3 [&>div]:justify-between [&>div]:gap-3 [&>div]:items-center [&>div]:bg-white [&>div]:py-4 [&>div]:pl-6 [&>div]:pr-4 [&>div]:rounded-2xl [&>div]:border-slate-200 [&>div]:border [&>div]:shadow-sm">
				<div className="flex">
					<div>
						<h3
							className="text-tremor-default text-tremor-content dark:text-dark-tremor-content mb-0.5"
							data-record="true"
						>
							Plekken gevuld
						</h3>
						<p
							className="text-tremor-metric text-tremor-content-strong font-semibold"
							data-record="true"
						>
							<NumberFlow
								value={
									isLoading
										? 0
										: stats?.weeklyStats?.length > 0
											? stats?.totalSlotsFilledLastMonth
											: 0
								}
							/>
						</p>
					</div>
					{isLoading ? (
						<div className="mt-4 h-20 w-32" />
					) : (
						<AnimateIn type="fromTop">
							<AreaChart
								className="mt-4 h-20 w-32"
								data={chartdata}
								index="date"
								categories={["Plekken gevuld"]}
								showYAxis={false}
								showXAxis={false}
								showAnnotation={true}
								showGridLines={false}
								showLegend={false}
								colors={["indigo"]}
								curveType="monotone"
							/>
						</AnimateIn>
					)}
				</div>
				<div className="flex">
					<div>
						<h3
							className="text-tremor-default text-tremor-content dark:text-dark-tremor-content mb-0.5"
							data-record="true"
						>
							Gemiddelde tijd om plek te vullen
						</h3>
						<div className="flex items-baseline gap-3">
							<p
								className="text-tremor-metric text-tremor-content-strong font-semibold"
								data-record="true"
							>
								<NumberFlow
									value={
										isLoading
											? 0
											: stats?.weeklyStats?.length > 0
												? stats?.averageFillTime
												: 0
									}
								/>
								<span className="text-slate-400 text-base uppercase ml-1">
									min
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SlotsStats;
