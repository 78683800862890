import React from "react";
import classNames from "classnames";

const Container = ({
	className,
	transparent,
	paddingTop = true,
	fullScreen = false,
	...props
}) => {
	return (
		<div
			className={classNames(
				className,
				{
					"bg-[#F8FAFC] bg-opacity-40": !transparent,
					"pt-20": paddingTop,
					"h-dvh": fullScreen,
				},
				"pb-20 min-h-dvh",
			)}
			{...props}
		>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 lg:pr-20">
				{props.children}
			</div>
		</div>
	);
};

export default Container;
