import { AdjustmentsHorizontalIcon } from "@heroicons/react/16/solid";
import CutoffDaysInviteSettings from "../../../practice/components/CutoffDaysInviteSettings";
import PracticeInfoForm from "../../../practice/components/PracticeInfoForm";
import Heading from "../../../shared/components/UIElements/Heading";
import ManageLocations from "./ManageLocations";

const ManagePractices = () => {
	return (
		<>
			<Heading size="2">Praktijkinformatie</Heading>
			<p className="pt-2 text-gray-600">
				Deze gegevens zien je patiënten ook in de WhatsApp berichten en
				op de bevestigingspagina.
			</p>
			<div className="pt-6 w-fit">
				<PracticeInfoForm />
			</div>
			<div className="pt-10">
				<ManageLocations />
			</div>
		</>
	);
};

export default ManagePractices;
