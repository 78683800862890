import { EyeIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "../../shared/components/UIElements/Tooltip";

const PracticeList = ({ practices, mapRef }) => {
	const handlePracticeClick = (practice) => {
		if (mapRef.current) {
			mapRef.current.setView(
				[practice.coordinates.latitude, practice.coordinates.longitude],
				13,
			);
		}
	};

	return (
		<div className="practice-list">
			<h3 className="text-lg font-semibold mb-2">
				Praktijken in beeld ({practices.length})
			</h3>
			<ul className="space-y-2">
				{practices.map((practice) => (
					<li
						key={practice._id}
						className="cursor-pointer hover:bg-slate-100 px-3 py-2 rounded-xl transition-colors duration-300"
						onClick={() => handlePracticeClick(practice)}
					>
						<div className="flex items-center group">
							<h4 className="font-medium">{practice.name}</h4>
							<Tooltip
								content="Bekijk praktijkpagina"
								id={practice._id}
							>
								<Link
									to={`/admin/practice/?id=${practice._id}`}
									className="ml-2 hidden group-hover:block"
								>
									<EyeIcon className="w-4 h-4 text-slate-400 hover:text-slate-500" />
								</Link>
							</Tooltip>
						</div>
						<p className="text-sm text-gray-600">
							{practice.address.city}
						</p>
					</li>
				))}
			</ul>
		</div>
	);
};

export default PracticeList;
