import { ProgressCircle } from "@tremor/react";

export const CircleWithMetric = ({ value, metric, label }) => (
	<div className="flex items-center justify-center gap-x-4">
		<ProgressCircle value={value}>
			<span className="text-sm font-medium text-gray-900">
				{Math.round(value)}%
			</span>
		</ProgressCircle>
		<div>
			<p className=" font-semibold text-gray-900 ">{metric}</p>
			<p className="text-sm text-gray-500 ">{label}</p>
		</div>
	</div>
);
