import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import FormattedPhoneNumber from "../util/FormattedPhoneNumber";
import Label from "./Label";
import FieldErrorMessage from "./FieldErrorMessage";

const RadioGroup = ({
	label,
	description,
	options = [
		{ label: "Ja", value: "ja" },
		{ label: "Nee", value: "nee" },
	],
	includeOther = false,
	renderAsPhoneNumber = false,
	...props
}) => {
	const [field, meta] = useField(props);
	const [isOtherSelected, setIsOtherSelected] = useState(false);

	let optionsArray = options;

	if (optionsArray.length === 0) {
		optionsArray = [
			{ label: "Ja", value: "ja" },
			{ label: "Nee", value: "nee" },
		];
	}

	const { setFieldValue } = useFormikContext();

	// Handler to manage the other input value
	const handleOtherInputChange = (e) => {
		setFieldValue(field.name, e.target.value);
	};

	return (
		<div className={props.width ? "w-" + props.width : "w-full"}>
			<div className={`${props.className} mt-8 mb-0`}>
				<Label label={label} description={description} />
				<fieldset className="mt-4">
					<div className="space-y-2">
						{optionsArray.map((option, index) => (
							<div key={index} className="flex items-center">
								<input
									id={field.name + "_" + option.value}
									name={field.name}
									type="radio"
									{...field}
									value={option.value}
									checked={field.value === option.value}
									className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 cursor-pointer"
								/>
								<label
									htmlFor={field.name + "_" + option.value}
									className="ml-3 block text-sm text-black font-medium cursor-pointer"
								>
									{renderAsPhoneNumber ? (
										<FormattedPhoneNumber
											phoneNumber={option.label}
										/>
									) : (
										<span className="text-black font-normal">
											{option.label}
										</span>
									)}
									{option.tag && (
										<span className="bg-slate-200 text-slate-900 px-2 py-0.5 rounded-full text-xs ml-2">
											{option.tag}
										</span>
									)}
								</label>
							</div>
						))}
						{includeOther && (
							<div className="flex items-center">
								<input
									id="otherOption"
									name={field.name}
									type="radio"
									{...field}
									value="other"
									checked={isOtherSelected}
									onChange={() => {
										setIsOtherSelected(true);
										setFieldValue(field.name, "other");
									}}
									className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300"
								/>
								<label
									htmlFor="otherOption"
									className="ml-3 block text-sm font-medium text-gray-700"
								>
									Anders, namelijk...
								</label>
								{isOtherSelected && (
									<input
										type="text"
										onChange={handleOtherInputChange}
										placeholder="Vul hier in..."
										className="ml-3 py-2 px-4 rounded border border-gray-300"
									/>
								)}
							</div>
						)}
					</div>
					<FieldErrorMessage meta={meta} />
				</fieldset>
			</div>
		</div>
	);
};

export default RadioGroup;
