import React, { useEffect, useState } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";

import Table from "../../../shared/components/Tables/Table";
import Heading from "../../../shared/components/UIElements/Heading";
import NewBehandelaar from "./NewBehandelaar";
import BehandelaarAvatar from "./BehandelaarAvatar";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import DeleteBehandelaar from "./DeleteBehandelaar";
import Modal from "../../../shared/components/UIElements/Modal";
import BehandelaarForm from "./BehandelaarForm";
import { UsersIcon } from "@heroicons/react/24/outline";

const BehandelaarTable = () => {
	const fetchBehandelaars = useFetch();
	const { user } = useUser();

	const [behandelaars, setBehandelaars] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [editingBehandelaar, setEditingBehandelaar] = useState(null);

	const reload = async () => {
		try {
			const behandelaarsReq = await fetchBehandelaars(
				`/api/behandelaars/${user.id}`,
				"GET",
			);
			setBehandelaars(behandelaarsReq);
			setIsLoading(false);
		} catch (error) {
			console.error("Error fetching behandelaars:", error);
			toast.error(
				"Er is iets misgegaan bij het ophalen van de behandelaars",
			);
		}
	};

	const updateBehandelaarInTable = (updatedBehandelaar) => {
		setBehandelaars((prevBehandelaars) =>
			prevBehandelaars.map((b) =>
				b._id === updatedBehandelaar._id ? updatedBehandelaar : b,
			),
		);
	};

	useEffect(() => {
		reload();
	}, [fetchBehandelaars]);

	return (
		<>
			<div className="flex justify-between gap-8">
				<div>
					<Heading size="2">Behandelaars</Heading>
					<p className="text-gray-600 pb-6 pt-2">
						Pas hier de behandelaars aan die in je praktijk werken
					</p>
				</div>
				<div>
					<NewBehandelaar reload={reload} />
				</div>
			</div>

			<Table
				data={behandelaars}
				isLoading={isLoading}
				emptyStateProps={{
					title: "Je hebt nog geen behandelaars toegevoegd",
					description: "Voeg het team toe aan je account",
					icon: UsersIcon,
					action: <NewBehandelaar reload={reload} />,
				}}
			>
				<th
					scope="col"
					className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
					render={(behandelaar) => (
						<div className="flex items-center">
							<BehandelaarAvatar
								firstName={behandelaar.firstName}
								lastName={behandelaar.lastName}
							/>
							<div className="ml-4">
								<div className="font-medium text-gray-900">
									{behandelaar.firstName}{" "}
									{behandelaar.lastName}
								</div>
							</div>
						</div>
					)}
				>
					Naam
				</th>

				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[400px]"
					render={(behandelaar) => (
						<div className="flex flex-wrap gap-2">
							<span className="inline-flex items-center px-3 py-1 text-sm bg-slate-50 rounded-full border border-slate-200">
								{behandelaar.occupation.title}
							</span>
							{behandelaar.secondaryOccupations?.map((occ) => (
								<span
									key={occ._id}
									className="inline-flex items-center px-3 py-1 text-sm text-slate-600 bg-slate-50 rounded-full border border-slate-200"
								>
									{occ.title}
								</span>
							))}
						</div>
					)}
				>
					Functie
				</th>

				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(behandelaar) => (
						<>
							{behandelaar.treatmentTypes?.length > 0 && (
								<div>
									<div className="text-gray-500 mb-1">
										{behandelaar.treatmentTypes.length}/17
										behandelingen
									</div>
								</div>
							)}
						</>
					)}
				>
					Behandelingen
				</th>

				<th
					scope="col"
					className="relative px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
					render={(behandelaar) => (
						<div className="flex gap-4 justify-end">
							<button
								onClick={() =>
									setEditingBehandelaar(behandelaar)
								}
								className="text-brand-intense"
							>
								<PencilSquareIcon className="h-5 w-5 inline" />
							</button>
							<DeleteBehandelaar
								behandelaarId={behandelaar._id}
								name={`${behandelaar.firstName} ${behandelaar.lastName}`}
								reload={reload}
								className="text-brand-intense"
							/>
						</div>
					)}
				>
					<span className="sr-only">Acties</span>
				</th>
			</Table>

			<Modal
				open={!!editingBehandelaar}
				setOpen={() => setEditingBehandelaar(null)}
			>
				{editingBehandelaar && (
					<BehandelaarForm
						initialData={editingBehandelaar}
						closeModal={() => setEditingBehandelaar(null)}
						reload={reload}
						onUpdate={updateBehandelaarInTable}
					/>
				)}
			</Modal>
		</>
	);
};

export default BehandelaarTable;
