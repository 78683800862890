import React, { useEffect, useState } from "react";
import Heading from "../../shared/components/UIElements/Heading";
import Button from "../../shared/components/FormElements/Button";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../shared/hooks/useFetch";
import {
	CreditCardIcon,
	AdjustmentsVerticalIcon,
	DocumentTextIcon,
} from "@heroicons/react/20/solid";
import Link from "../../shared/components/UIElements/Link";
import SkeletonLoadingItem from "../../shared/components/UIElements/SkeletonLoadingItem";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { useSearchParams } from "react-router-dom";
import UpgradeToYearly from "../components/UpgradeToYearly";

const SubscriptionSettings = () => {
	const { user } = useUser();
	const fetchLink = useFetch();

	const [changePaymentLink, setChangePaymentLink] = useState(null);
	const [paymentPortalLink, setPaymentPortalLink] = useState(null);
	const [status, setStatus] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get("fromStripe") === "true") {
			setStatus(
				searchParams.get("succeeded") === "false" ? "error" : "success",
			);
		}
	}, [searchParams]);

	useEffect(() => {
		const fetchLinks = async () => {
			const paymentLink = await fetchLink(
				`/api/billing/change-payment-method/${user.id}`,
			);

			const paymentPortalLink = await fetchLink(
				`/api/billing/customer-portal/${user.id}/${user.publicMetadata.customerId}`,
			);
			setChangePaymentLink(paymentLink);
			setPaymentPortalLink(paymentPortalLink);

			setIsLoading(false);
		};
		fetchLinks();
	}, [user]);

	if (!user.publicMetadata.customerId) {
		return (
			<>
				<p>
					Het lijkt erop dat je abonnement niet gekoppeld is aan dit
					account. Neem contact met ons op via de chat rechtsonder om
					dit te koppelen.
				</p>
			</>
		);
	}

	return (
		<>
			<Heading>Beheer je abonnement</Heading>

			{status === "success" && (
				<div className="flex items-center gap-2 bg-green-50 w-fit py-2 px-4 rounded-lg text-sm text-green-900 border-green-100 border mt-10">
					<CheckCircleIcon className="size-4 text-green-600" />
					<p>Je betaalmethode is gewijzigd.</p>
				</div>
			)}

			{status === "error" && (
				<div className="flex items-center gap-2 bg-red-50 w-fit py-2 px-4 rounded-lg text-sm text-red-900 border-red-100 border mt-10">
					<XMarkIcon className="size-5 text-red-600" />
					<p>
						Er is iets misgegaan bij het wijzigen van de
						betaalmethode. Probeer het opnieuw en neem anders
						contact met ons op via de chat
					</p>
				</div>
			)}

			<div className="[&>div]:bg-slate-100 [&>div]:rounded-3xl [&>div]:p-6 [&>div]:w-1/2 flex gap-4 mt-10">
				<div className="">
					<CreditCardIcon className="size-8 text-brand-intense" />
					<h2 className="text-lg font-semibold pb-1 pt-2">
						Betaalmethode wijzigen
					</h2>
					<p className="text-sm text-slate-700 pb-4">
						Wil je de betalingen via een andere rekening laten
						lopen? Pas dat hier aan.
					</p>
					{isLoading ? (
						<SkeletonLoadingItem
							height="h-6"
							width="w-32"
							color="slate-300"
						/>
					) : (
						<Link to={changePaymentLink} target="_blank">
							Wijzig betaalmethode
						</Link>
					)}
				</div>
				<div className="">
					<AdjustmentsVerticalIcon className="size-8 text-brand-intense" />
					<h2 className="text-lg font-semibold  pb-1 pt-2">
						Abonnement beheren
					</h2>
					<p className="text-sm text-slate-700 pb-4">
						Hier kun je bijvoorbeeld je factuur- en adresgegevens
						aanpassen.
					</p>
					{isLoading ? (
						<SkeletonLoadingItem
							height="h-6"
							width="w-32"
							color="slate-300"
						/>
					) : (
						<Link to={paymentPortalLink} target="_blank">
							Beheer abonnement
						</Link>
					)}
				</div>
				<div className="flex flex-col justify-between">
					<div>
						<DocumentTextIcon className="size-8 text-brand-intense" />
						<h2 className="text-lg font-semibold  pb-1 pt-2">
							Facturen
						</h2>
						<p className="text-sm text-slate-700 pb-4">
							Vind een overzicht van al je reeds betaalde facturen
							ter administratie.
						</p>
					</div>

					<div>
						<Link
							to={`https://zenvoice.io/p/6605790ecb763e5761a09293?email=${user.primaryEmailAddress.emailAddress}`}
							target="_blank"
						>
							Bekijk facturen
						</Link>
					</div>
				</div>
			</div>
			<div className="mt-4 w-full">
				<UpgradeToYearly />
			</div>
		</>
	);
};

export default SubscriptionSettings;
