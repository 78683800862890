import React, { useState } from "react";
import classNames from "classnames";
import posthog from "posthog-js";

import Button from "../components/PortalButton";
import mixpanel from "mixpanel-browser";

const PatientFeedback = ({ patientId }) => {
	const [selectedValue, setSelectedValue] = useState(null);
	const [comment, setComment] = useState("");
	const [submitted, setSubmitted] = useState(false);

	const options = [
		{
			label: "Slecht",
			value: "bad",
			image: "https://em-content.zobj.net/source/apple/391/frowning-face_2639-fe0f.png",
		},
		{
			label: "Neutraal",
			value: "neutral",
			image: "https://em-content.zobj.net/source/apple/391/neutral-face_1f610.png",
		},
		{
			label: "Goed",
			value: "good",
			image: "https://em-content.zobj.net/source/apple/391/smiling-face-with-smiling-eyes_1f60a.png",
		},

		// {
		// 	label: "Super",
		// 	value: "excellent",
		// 	image: "https://em-content.zobj.net/source/apple/391/star-struck_1f929.png",
		// },
	];

	const handleClick = async (value) => {
		setSelectedValue(value);
		posthog.capture("😊 Patiënt heeft feedback emoji geklikt", {
			patientId,
			value,
		});
		mixpanel.track("😊 Patiënt heeft feedback emoji geklikt", {
			patientId,
			value,
		});
	};

	const handleSubmit = () => {
		posthog.capture("😊 Patiënt heeft feedback ingevuld", {
			patientId,
			value: selectedValue,
			comment,
		});
		mixpanel.track("😊 Patiënt heeft feedback ingevuld", {
			patientId,
			value: selectedValue,
			comment,
		});
		setSubmitted(true);
	};

	if (submitted) {
		return (
			<div className="flex flex-col items-center justify-center">
				<div className="bg-green-50 border-green-800 border-opacity-10 border rounded-lg w-fit px-4 py-2">
					<p className="text-green-800">Bedankt voor uw feedback!</p>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col items-center justify-center">
			<div className="rounded-2xl bg-slate-200 py-5 px-5 w-fit">
				<p className="font-semibold mb-4">
					Wat vindt u van deze service?
				</p>
				<div className="flex items-center justify-center gap-4">
					{options.map((option) => (
						<button
							key={option.value}
							onClick={() => handleClick(option.value)}
							className={classNames(
								`flex flex-col items-center justify-center w-12 transition-transform duration-200`,
								!selectedValue && "opacity-100 scale-100",
								selectedValue &&
									selectedValue === option.value &&
									"scale-110",
								selectedValue &&
									selectedValue !== option.value &&
									"opacity-40",
							)}
						>
							<img src={option.image} alt={option.label} />
						</button>
					))}
				</div>
				<textarea
					placeholder="Opmerkingen, ideeën, of verbeterpunten?"
					className="w-full border shadow-sm border-gray-300 rounded-lg p-2 mt-4 bg-slate-50 text-sm"
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
				<Button size="medium" fullWidth onClick={handleSubmit}>
					Versturen
				</Button>
			</div>
		</div>
	);
};

export default PatientFeedback;
