import React from "react";

import { SparklesIcon } from "@heroicons/react/24/solid";
import Heading from "../../shared/components/UIElements/Heading";

const AuthPromo = () => {
	return (
		<>
			<SparklesIcon className="h-10 w-auto mb-4 text-brand-200" />
			<Heading className="text-white leading-10">
				Vul lege plekken in de agenda op de automatische piloot met
				Snelterecht.
			</Heading>
			<p className="text-brand-100 mt-5 leading-7">
				Met 1 druk op de knop krijgen al je patiënten op de wachtlijst
				een uitnodiging per WhatsApp. Zo vul je lege plekken in de
				agenda zonder dat je iedereen individueel moet afbellen!
			</p>
		</>
	);
};

export default AuthPromo;
