import React, { useState } from "react";
import Modal from "../../../shared/components/UIElements/Modal";
import Button from "../../../shared/components/FormElements/Button";
import BehandelaarForm from "./BehandelaarForm";
import { PlusIcon } from "@heroicons/react/16/solid";

const NewBehandelaar = ({ reload }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setIsModalOpen(true)} iconPrefix={PlusIcon}>
				Behandelaar toevoegen
			</Button>

			<Modal open={isModalOpen} setOpen={setIsModalOpen}>
				<h3 className="px-8 font-bold text-xl mb-2 -mt-4">
					Nieuwe behandelaar
				</h3>
				<BehandelaarForm
					closeModal={() => setIsModalOpen(false)}
					reload={reload}
				/>
			</Modal>
		</>
	);
};

export default NewBehandelaar;
