import React from "react";

import { usePortal } from "../context/PortalContext";
const PortalHeader = () => {
	const portal = usePortal();
	const portalSettings = portal.portalSettings;

	return (
		<div className="flex justify-start">
			{portalSettings?.logoUrl && (
				<img
					src={`${portalSettings?.logoUrl}`}
					alt={portalSettings?.name}
					className="max-w-40 h-auto max-h-20"
				/>
			)}
		</div>
	);
};

export default PortalHeader;
