import React, { useState, useEffect } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import Table from "../../../shared/components/Tables/Table";
import Container from "../../../shared/components/UIElements/Container";
import Heading from "../../../shared/components/UIElements/Heading";
import SubHeading from "../../../shared/components/UIElements/SubHeading";
import Card from "../../../shared/components/UIElements/Card";
import Carousel from "../../../shared/components/UIElements/Carousel";
import {
	BuildingOfficeIcon,
	EyeIcon,
	TrophyIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const PracticeStats = () => {
	const fetchData = useFetch();
	const { user } = useUser();
	const [stats, setStats] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [fromDate, setFromDate] = useState(() => {
		const thirtyDaysAgo = new Date();
		thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
		return thirtyDaysAgo.toISOString().split("T")[0];
	});

	useEffect(() => {
		const fetchStats = async () => {
			setIsLoading(true);
			const data = await fetchData(
				`/api/admin/stats/${user.id}?fromDate=${fromDate}`,
			);
			setStats(data);
			setIsLoading(false);
		};
		fetchStats();
	}, [fetchData, user.id, fromDate]);

	return (
		<>
			<div className="flex justify-between items-center">
				<Heading>Praktijk Statistieken</Heading>
				<FromDateSelector
					fromDate={fromDate}
					setFromDate={setFromDate}
				/>
			</div>

			<div className="mt-10 mb-8">
				<SubHeading icon={TrophyIcon} className="mb-4">
					Winnaars
				</SubHeading>

				<Carousel defaultColumns={4} gap={4}>
					{stats
						.sort(
							(a, b) =>
								b.stats.pastMonth.slotsFilled -
								a.stats.pastMonth.slotsFilled,
						)
						.map((practice, index) => (
							<Card key={practice._id}>
								<p className="font-semibold text-lg mb-2">
									#{index + 1} - {practice.name}
								</p>
								<p className="">
									<span className="text-green-800 font-bold text-xl">
										{practice.stats.pastMonth.slotsFilled}{" "}
									</span>
									<span className="text-green-800 font-normal text-base">
										van de{" "}
										{practice.stats.pastMonth.slotsCreated}{" "}
										gevuld (
										{(
											practice.stats.pastMonth.fillRate *
											100
										).toFixed(2)}
										%)
									</span>
								</p>
								<ul className="list-disc list-outside pl-4 text-slate-800 text-sm space-y-2 mt-4">
									<li>
										{practice.stats.pastMonth.patientsAdded}{" "}
										patiënten toegevoegd
									</li>
									<li>
										{practice.stats.pastMonth.callsSaved}{" "}
										telefoontjes bespaard
									</li>
									<li>
										{(
											practice.stats.pastMonth
												.responseRate * 100
										).toFixed(2)}
										% response rate
									</li>
								</ul>
							</Card>
						))}
				</Carousel>
			</div>

			<div className="pt-10 mb-8">
				<SubHeading icon={BuildingOfficeIcon}>
					Alle praktijken (
					{
						stats.filter(
							(stat) => stat.stats.pastMonth.slotsFilled > 0,
						).length
					}{" "}
					actief)
				</SubHeading>
				<Table data={stats} isLoading={isLoading}>
					<th
						scope="col"
						className="pr-3 py-3.5 pl-6 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<div className="group flex items-center">
								<p className="font-bold text-black">
									<Link
										to={`/admin/practice/?id=${row._id}`}
										className="hover:underline"
									>
										{row.name}{" "}
									</Link>
									<span className="text-slate-500 font-medium">
										({row.stats.allTime.slotsFilled}x)
									</span>
								</p>
							</div>
						)}
					>
						Praktijk
					</th>

					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => row.stats.pastMonth.patientsAdded}
					>
						Pt toegevoegd
					</th>

					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => (
							<>
								<strong>
									{row.stats.pastMonth.slotsFilled}
								</strong>{" "}
								van de {row.stats.pastMonth.slotsCreated}
								<br />
								<span className="text-gray-500">
									(
									{(
										row.stats.pastMonth.fillRate * 100
									).toFixed(2)}
									%)
								</span>
							</>
						)}
					>
						Gevuld/gemeld
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) => row.stats.pastMonth.callsSaved}
					>
						Telefoontjes bespaard
					</th>
					<th
						scope="col"
						className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						render={(row) =>
							`${(row.stats.pastMonth.responseRate * 100).toFixed(2)}%`
						}
					>
						Response rate
					</th>
				</Table>
			</div>
		</>
	);
};

const FromDateSelector = ({ fromDate, setFromDate }) => {
	const [selectedOption, setSelectedOption] = useState("30days");
	const [customDate, setCustomDate] = useState(fromDate);

	const handleOptionChange = (option) => {
		setSelectedOption(option);
		let newDate;
		switch (option) {
			case "today":
				newDate = new Date().toISOString().split("T")[0];
				break;
			case "7days":
				newDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
					.toISOString()
					.split("T")[0];
				break;
			case "30days":
				newDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
					.toISOString()
					.split("T")[0];
				break;
			case "3months":
				newDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)
					.toISOString()
					.split("T")[0];
				break;
			case "custom":
				newDate = customDate;
				break;
			default:
				newDate = fromDate;
		}
		setFromDate(newDate);
	};

	return (
		<div className="flex items-center space-x-2">
			<select
				value={selectedOption}
				onChange={(e) => handleOptionChange(e.target.value)}
				className="px-3 py-2 border border-gray-300 rounded-md"
			>
				<option value="today">Vandaag</option>
				<option value="7days">Afgelopen 7 dagen</option>
				<option value="30days">Afgelopen 30 dagen</option>
				<option value="3months">Afgelopen 3 maanden</option>
				<option value="custom">Custom</option>
			</select>
			{selectedOption === "custom" && (
				<input
					type="date"
					value={customDate}
					onChange={(e) => {
						setCustomDate(e.target.value);
						setFromDate(e.target.value);
					}}
					className="px-3 py-2 border border-gray-300 rounded-md"
				/>
			)}
		</div>
	);
};

export default PracticeStats;
