import React, { useState, useEffect } from "react";

import { useUser } from "@clerk/clerk-react";

import {
	CalendarDaysIcon,
	CalendarIcon,
	ClockIcon,
	UserPlusIcon,
	HomeIcon,
	PhoneArrowUpRightIcon,
} from "@heroicons/react/20/solid";
import {
	ArrowRightIcon,
	ArrowTopRightOnSquareIcon,
	ShieldCheckIcon,
} from "@heroicons/react/16/solid";

import Heading from "../components/UIElements/Heading";
import useFetch from "../hooks/useFetch";

import GettingStarted from "../../practice/pages/GettingStarted";

import UpgradeToYearly from "../../billing/components/UpgradeToYearly";
import MiniSlotList from "../../slots/components/MiniSlotList";
import Button from "../components/FormElements/Button";
import SubHeading from "../components/UIElements/SubHeading";

import CardWithIcon from "../components/UIElements/CardWithIcon";
import Notice from "../components/UIElements/Notice";

const Dashboard = () => {
	const { user } = useUser();
	const [stats, setStats] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [hasActiveSlots, setHasActiveSlots] = useState(true);

	const fetchStats = useFetch();

	useEffect(() => {
		const getNotifications = async () => {
			const response = await fetchStats(
				`/api/practice/user/${user.id}/stats`,
			);
			setStats(response);
			setIsLoading(false);
		};

		getNotifications();
	}, []);

	if (user.unsafeMetadata.hasCompletedOnboarding !== true) {
		return <GettingStarted />;
	}

	return (
		<div className="h-dvh bg-[#F8FAFC] bg-opacity-40">
			<div className={"border-b-2 border-gray-200"}>
				<DashboardContainer>
					<div className="flex justify-between items-center mb-10">
						<div>
							<div className="flex gap-2 items-center mb-3">
								<HomeIcon className="size-4 text-slate-400" />
								<h3 className="text-sm font-semibold text-slate-500 uppercase">
									Dashboard
								</h3>
							</div>
							<Heading>Vrijgekomen plekken</Heading>
						</div>
						<DashboardButtons />
					</div>

					{hasActiveSlots ? (
						<MiniSlotList setHasActiveSlots={setHasActiveSlots} />
					) : (
						<div className="text-gray-500">
							Je hebt geen openstaande plekken.
						</div>
					)}
					<div className="mt-4 w-2/3">
						<Notice
							show={
								user.unsafeMetadata.onboarding
									?.patientPortalCustomized !== true
							}
							title="Personaliseer je patiëntenportaal in 1 minuut"
						>
							<p className="mb-2 text-sm">
								Je patiënten zien liever jullie logo en kleuren
								dan een standaard ontwerp. Maak je
								patiëntenportaal herkenbaar en professioneel –
								het kost je minder dan 1 minuut!
							</p>
							<Button
								size="medium"
								ghost
								to="/instellingen/portaal"
								iconSuffix={ArrowRightIcon}
							>
								Stel kleur en logo in
							</Button>
						</Notice>
					</div>
				</DashboardContainer>
			</div>
			<div>
				<DashboardContainer>
					<div className="flex gap-x-4 items-baseline ">
						<Heading>Statistieken</Heading>

						{stats.totalSlotsFilled !== 0 && (
							<TotalSlotsFilledBadge
								amountOfSlotsFilled={stats.totalSlotsFilled}
							/>
						)}
					</div>
					<div className="mt-4 mb-10">
						<SubHeading className="mb-3">
							Afgelopen 30 dagen
						</SubHeading>

						<div className="grid grid-cols-4 gap-4">
							<CardWithIcon
								icon={CalendarDaysIcon}
								value={stats.pastMonth?.slotsFilled}
								label={
									stats.pastMonth?.slotsFilled === 1
										? "lege plek"
										: "lege plekken"
								}
								subtitle="gevuld"
								isLoading={isLoading}
							/>
							<CardWithIcon
								icon={PhoneArrowUpRightIcon}
								value={stats.pastMonth?.callsSaved}
								label="telefoontjes"
								subtitle="bespaard"
								isLoading={isLoading}
							/>
							<CardWithIcon
								icon={ClockIcon}
								value={stats.pastMonth?.averageResponseTime}
								label="minuten"
								subtitle="reactietijd"
								isLoading={isLoading}
								preventAnimation={true}
							/>
						</div>
					</div>

					<div className="mt-3">
						<SubHeading className="mb-4">
							Afgelopen 7 dagen
						</SubHeading>
						<div className="grid grid-cols-4 gap-4">
							<CardWithIcon
								icon={UserPlusIcon}
								value={stats.pastWeek?.patientsAdded}
								label="patiënten"
								subtitle="toegevoegd"
								isLoading={isLoading}
								hideWhenZero={false}
							/>
						</div>
					</div>

					<div className="mt-16">
						<UpgradeToYearly includeIgnoreButton={true} />
					</div>
				</DashboardContainer>
			</div>
		</div>
	);
};

const DashboardContainer = ({ children }) => {
	return (
		<div className={"py-14"}>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 lg:pr-20">
				{children}
			</div>
		</div>
	);
};

const DashboardButtons = () => {
	return (
		<div className="flex gap-3">
			<Button ghost iconPrefix={UserPlusIcon} to="/patienten/nieuw">
				Patiënt toevoegen
			</Button>
			<Button iconPrefix={CalendarIcon} to="/gaten/nieuw">
				Lege plek melden
			</Button>
		</div>
	);
};

const TotalSlotsFilledBadge = ({ amountOfSlotsFilled }) => {
	return (
		<div
			data-record="true"
			className="bg-green-100 text-green-700  border-green-800 bg-opacity-70  border-[0.8px] border-opacity-60 px-2 py-1 rounded-lg flex items-center gap-x-1 text-sm font-medium"
		>
			<ShieldCheckIcon className="w-4 h-4" />
			{amountOfSlotsFilled} plekken gevuld in totaal
		</div>
	);
};

export default Dashboard;
