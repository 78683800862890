import React from "react";
import StatusPill from "./StatusPill";
import { Link } from "react-router-dom";
import FormattedDate from "../../shared/components/util/FormattedDate";

import blueTicks from "../../assets/BlueTicks.png";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import AttentionRequired from "../../tasks/components/AttentionRequired";
import classNames from "classnames";

const MiniSlotItem = ({
	id,
	date,
	startTime,
	endTime,
	batches,
	status,
	task,
}) => {
	const calculateResponseRate = () => {
		const invitedPatients = batches.flatMap((batch) => {
			if (new Date(batch.dateSent) < new Date()) {
				return batch.patients;
			}
			return [];
		});

		const totalInvited = invitedPatients.length;

		const readCount = invitedPatients.filter((pt) =>
			pt.patient.messages?.some((message) => {
				return (
					message.slotId === id &&
					(message.deliveryStatus === "read" ||
						message.response === "accepted" ||
						message.response === "rejected")
				);
			}),
		).length;
		return `${readCount}/${totalInvited}`;
	};

	return (
		<Link
			className={classNames(
				"bg-white min-w-[340px] px-6 py-5 rounded-3xl border shadow-sm hover:shadow flex items-center justify-between gap-x-10 transition-all duration-300 ",
				{
					"border-[#F81606] border-2": task?.isChecked === false,
					"border-gray-200": task?.isChecked !== false,
				},
			)}
			to={`/gaten/${id}`}
		>
			<div className="">
				{task?.isChecked === false && (
					<div className="mb-0.5">
						<AttentionRequired />
					</div>
				)}
				<p className="font-semibold text-lg" data-record="true">
					<FormattedDate date={date} data-record="true" />
				</p>
				<p className=" text-gray-500" data-record="true">
					{startTime} - {endTime} uur
				</p>
			</div>
			<div className="flex flex-col items-center justify-center pt-0.5 mr-2">
				<StatusPill status={status} size="medium" />
				{status === "open" && (
					<Tooltip
						id={`response-rate-tooltip_${id}`}
						content={`${calculateResponseRate()} uitnodigingen gelezen`}
						place="bottom"
					>
						<p
							className="text-sm text-blue-600 font-medium flex gap-x-1 items-center mt-1 pr-1 -mb-1"
							data-record="true"
						>
							<img
								src={blueTicks}
								alt="Gelezen"
								className="h-[11px] w-auto"
							/>
							{calculateResponseRate()}
						</p>
					</Tooltip>
				)}
			</div>
		</Link>
	);
};

export default MiniSlotItem;
