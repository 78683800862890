import React, { useEffect, useState, useRef } from "react";
import Heading from "../../shared/components/UIElements/Heading";
import MiniSlotItem from "./MiniSlotItem";
import useFetch from "../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import SkeletonLoadingItem from "../../shared/components/UIElements/SkeletonLoadingItem";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";

const MiniSlotList = ({ setHasActiveSlots }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [slots, setSlots] = useState([]);
	const [showRightGradient, setShowRightGradient] = useState(false);
	const [showLeftGradient, setShowLeftGradient] = useState(false);
	const scrollContainerRef = useRef(null);

	const fetchSlots = useFetch();
	const { user } = useUser();

	useEffect(() => {
		const getSlots = async () => {
			const response = await fetchSlots(`/api/slots/user/${user.id}/`);
			setSlots(response);
			setIsLoading(false);
			setHasActiveSlots(response.length > 0);
		};

		getSlots();
	}, []);

	useEffect(() => {
		const checkOverflow = () => {
			if (scrollContainerRef.current) {
				const { scrollWidth, clientWidth, scrollLeft } =
					scrollContainerRef.current;
				setShowRightGradient(
					scrollWidth > clientWidth &&
						scrollLeft < scrollWidth - clientWidth,
				);
				setShowLeftGradient(scrollLeft > 0);
			}
		};

		checkOverflow();
		window.addEventListener("resize", checkOverflow);
		const scrollContainer = scrollContainerRef.current;
		if (scrollContainer) {
			scrollContainer.addEventListener("scroll", checkOverflow);
		}
		return () => {
			window.removeEventListener("resize", checkOverflow);
			if (scrollContainer) {
				scrollContainer.removeEventListener("scroll", checkOverflow);
			}
		};
	}, [slots]);

	const scroll = (direction) => {
		if (scrollContainerRef.current) {
			const scrollAmount = direction === "right" ? 1020 : -1020;
			scrollContainerRef.current.scrollBy({
				left: scrollAmount,
				behavior: "smooth",
			});
		}
	};

	if (!isLoading && slots.length === 0) {
		return null;
	}

	if (isLoading) {
		return (
			<div className="flex gap-x-6 overflow-x-auto pb-4">
				<SkeletonLoadingItem
					width="w-[340px]"
					height="h-24"
					rounded="3xl"
					color="slate-200"
				/>
				<SkeletonLoadingItem
					width="w-[340px]"
					height="h-24"
					rounded="3xl"
					color="slate-200"
				/>
				<SkeletonLoadingItem
					width="w-[340px]"
					height="h-24"
					rounded="3xl"
					color="slate-200"
				/>
			</div>
		);
	}

	return (
		<div className="relative">
			<div
				ref={scrollContainerRef}
				className="overflow-x-auto pb-4 scrollbar-hide"
			>
				<div className="flex gap-4 w-max">
					{slots.map((slot) => (
						<MiniSlotItem key={slot._id} id={slot._id} {...slot} />
					))}
				</div>
			</div>
			{showRightGradient && (
				<>
					<div className="absolute top-0 right-0 bottom-0 w-24 bg-gradient-to-l from-white to-transparent pointer-events-none" />
					<button
						onClick={() => scroll("right")}
						className="absolute top-1/2 right-2 -translate-y-1/2 bg-white rounded-full p-2 shadow-sm hover:shadow-md transition-all border border-gray-200"
					>
						<ChevronRightIcon className="w-6 h-6 text-gray-600" />
					</button>
				</>
			)}
			{showLeftGradient && (
				<>
					<div className="absolute top-0 left-0 bottom-0 w-24 bg-gradient-to-r from-white to-transparent pointer-events-none" />
					<button
						onClick={() => scroll("left")}
						className="absolute top-1/2 left-2 -translate-y-1/2 bg-white rounded-full p-2 shadow-sm hover:shadow-md transition-all border border-gray-200"
					>
						<ChevronLeftIcon className="w-6 h-6 text-gray-600" />
					</button>
				</>
			)}
		</div>
	);
};

export default MiniSlotList;
