import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import posthog from "posthog-js";

import { useFlow } from "@frigade/react";

import useFetch from "../hooks/useFetch";

import Button from "../components/FormElements/Button";
import Heading from "../components/UIElements/Heading";

import koppelingImage from "../../assets/Koppeling.png";
import { CheckIcon, QuestionMarkCircleIcon } from "@heroicons/react/16/solid";

import Modal from "../components/UIElements/Modal";
import mixpanel from "mixpanel-browser";
import CheckList from "../components/UIElements/CheckList";

const DownloadDesktopApp = () => {
	const fetchDownloadLink = useFetch();
	const { user } = useUser();
	const { flow } = useFlow("flow_l1E2bn90");

	const [downloadLink, setDownloadLink] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const getLink = async () => {
			const response = await fetchDownloadLink(
				`/api/desktop/download/${user.id}`,
			);
			setDownloadLink(response.download_url);
		};
		getLink();
	}, [fetchDownloadLink, user.id]);

	const handleDownload = async () => {
		window.open(downloadLink, "_blank");
		window.open(
			"https://help.dentiva.nl/nl/articles/9472084-koppeling-installeren",
		);

		mixpanel.track("💾 Desktop app gedownload");
		posthog.capture("💾 Desktop app gedownload");

		await flow.steps.get("integratie").complete();
	};

	return (
		<>
			<div className="flex gap-x-8 items-center">
				<div className="w-1/2">
					<Heading>
						Voeg iemand toe aan Snelterecht direct vanuit de
						patiëntenkaart
					</Heading>
					<p className="text-gray-600 mt-4 mb-6">
						Installeer het programma op de computers achter de
						receptie.
					</p>

					<div className="pr-10 mb-8">
						<p className="uppercase text-gray-500 font-semibold text-sm mb-2">
							Werkt met...
						</p>
						<CheckList
							horizontal
							items={[
								"Exquise Classic",
								"Exquise Next Generation",
								"Oase",
								"Novadent",
								"Simplex",
								"Bytegear",
								"Dentadmin",
							]}
						/>
					</div>
					<div className="gap-x-2 flex">
						<Button
							iconPrefix={ArrowDownTrayIcon}
							onClick={() => setIsModalOpen(true)}
						>
							Download programma
						</Button>
						<Button
							iconPrefix={QuestionMarkCircleIcon}
							ghost
							to="https://help.dentiva.nl/nl/collections/9594448-koppeling-met-praktijksoftware"
							target="_blank"
						>
							Bekijk hulpartikel
						</Button>
					</div>
					<div>
						<p className="text-sm bg-slate-100 p-5 rounded-xl mt-8 text-slate-800">
							<strong>Let op:</strong> dit is geen officiele
							koppeling vanuit je praktijksoftware leverancier.
							Het is een tool die we hebben gemaakt om het overtyp
							werk voor je te automatiseren. Voor vragen en
							support kun je alleen bij ons terecht.
						</p>
					</div>
				</div>
				<div className="w-1/2">
					<img
						src="https://res.cloudinary.com/dcydtlxtl/image/upload/f_auto,q_auto/p701nvu3kligjryfy2ri"
						alt="Desktop app"
						className="rounded-[35px]"
					/>
				</div>
			</div>
			<Modal open={isModalOpen} setOpen={setIsModalOpen}>
				<div className="px-6 pb-6">
					<h3 className="text-lg font-bold">
						Let op: Voor vragen en support over de tool kun je
						alleen bij ons team terecht.
					</h3>
					<p className="text-gray-600 mt-3 mb-6">
						De tool is geen officiele koppeling vanuit je
						praktijksoftware leverancier.
						<br />
						<br />
						Zij kunnen je dus ook niet helpen met de applicatie. Je
						kunt ons bereiken via de chat rechtsonder in je beeld.
					</p>
					<Button
						onClick={handleDownload}
						iconPrefix={ArrowDownTrayIcon}
					>
						Download programma
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default DownloadDesktopApp;
