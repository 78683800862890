import React, { useState, useEffect } from "react";

import { useUser } from "@clerk/clerk-react";

import useFetch from "../../shared/hooks/useFetch";
import Table from "../../shared/components/Tables/Table";
import FormattedDate from "../../shared/components/util/FormattedDate";
import MiniCopyButton from "../../shared/components/FormElements/MiniCopyButton";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";
import { ClockIcon } from "@heroicons/react/20/solid";

const DeletedPatientsOverview = () => {
	const [deletedPatients, setDeletedPatients] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useUser();
	const fetchPatients = useFetch();

	const deleteReasonsDictionary = {
		not_a_patient: "Ik ben geen patiënt bij deze praktijk",
		not_requested: "Ik heb nooit gevraagd om mijn afspraak te vervroegen",
		changed_mind: "Ik wil mijn afspraak toch niet meer vervroegen",
		other: "Anders",
	};

	useEffect(() => {
		const fetchDeletedPatients = async () => {
			const response = await fetchPatients(
				`/api/patients/user/${user.id}/deleted`,
			);
			setDeletedPatients(response);
			setIsLoading(false);
		};
		fetchDeletedPatients();
	}, [user, fetchPatients]);

	const sevenDaysAgo = new Date();
	sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

	const recentlyDeletedPatients = deletedPatients.filter(
		(patient) => new Date(patient.deletedAt) >= sevenDaysAgo,
	);
	const allDeletedPatients = deletedPatients.filter(
		(patient) => new Date(patient.deletedAt) < sevenDaysAgo,
	);

	const tableColumns = [
		{
			header: "Patiëntnummer",
			accessor: "patientNumber",
			className:
				"pr-3 py-3.5 pl-6 text-left text-sm font-semibold text-gray-900",
			render: (row) => (
				<div className="flex">
					<span className="text-black font-medium mr-2">
						{row.patientNumber}
					</span>
					<MiniCopyButton
						text={row.patientNumber}
						tooltip="Patiëntnummer kopiëren"
						tooltipId={`copy-patient-number-${row.patientNumber}`}
					/>
				</div>
			),
		},
		{
			header: "Datum toegevoegd",
			accessor: "createdAt",
			className:
				"px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
			render: (row) => <FormattedDate date={row.createdAt} />,
		},
		{
			header: "Datum verwijderd",
			accessor: "deletedAt",
			className:
				"px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
			render: (row) => <FormattedDate date={row.deletedAt} />,
		},
		{
			header: "Reden voor verwijdering",
			accessor: "deleteReason",
			className:
				"px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
			render: (row) => deleteReasonsDictionary[row.deleteReason],
		},
		{
			header: "Opmerking",
			accessor: "deleteComment",
			className:
				"px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
		},
	];

	const renderTable = (data) => (
		<Table data={data} isLoading={isLoading}>
			{tableColumns.map((column) => (
				<th
					key={column.accessor}
					scope="col"
					className={column.className}
					accessor={column.accessor}
					render={column.render}
				>
					{column.header}
				</th>
			))}
		</Table>
	);

	return (
		<Container>
			<MiniBackButton to="/patienten/all" />
			<Heading>
				Patiënten die zichzelf uit Snelterecht hebben verwijderd
			</Heading>
			<p className="text-slate-600 mt-4">
				Zodra een patiënt zichzelf uit Snelterecht verwijdert,
				anonimiseren we de gegevens in onze systemen. Je ziet dus alleen
				het patiëntnummer en de reden die de patiënt heeft opgegeven.
			</p>

			{recentlyDeletedPatients.length > 0 && (
				<div className="mt-12">
					<h3 className="font-semibold uppercase text-slate-600 -mb-2 flex items-center gap-x-2">
						<ClockIcon className="size-4 text-slate-500" />
						Afgelopen 7 dagen
					</h3>
					{renderTable(recentlyDeletedPatients)}
				</div>
			)}

			<div className="mt-16">
				{recentlyDeletedPatients.length > 0 && (
					<h3 className="font-semibold uppercase text-slate-600 -mb-2 flex items-center gap-x-2">
						<ClockIcon className="size-4 text-slate-500" />
						Alles
					</h3>
				)}
				{renderTable(allDeletedPatients)}
			</div>
		</Container>
	);
};

export default DeletedPatientsOverview;
