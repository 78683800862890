import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Label from "./Label";

const UploadFile = ({
	label,
	initialPreview,
	doOnChange,
	maxFileSize = 4 * 1024 * 1024,
	...props
}) => {
	const [field, meta, helpers] = useField(props);
	const [previewUrl, setPreviewUrl] = useState(initialPreview || null);
	const [selectedFile, setSelectedFile] = useState(null);

	useEffect(() => {
		if (initialPreview) {
			setPreviewUrl(initialPreview);
		}
	}, [initialPreview]);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file) {
			if (file.size > maxFileSize) {
				alert(
					`Bestand is te groot. Maximaal toegestaan: ${maxFileSize / (1024 * 1024)}MB`,
				);
				return;
			}

			if (file.type.startsWith("image/")) {
				setSelectedFile(file);
				const preview = URL.createObjectURL(file);

				const fileValue = {
					file: file,
					preview: preview,
					name: file.name,
				};

				helpers.setValue(fileValue);
				setPreviewUrl(preview);

				// Call the doOnChange prop with file and preview
				if (doOnChange) {
					doOnChange(file, preview);
				}
			}
		}
	};

	React.useEffect(() => {
		return () => {
			if (previewUrl && previewUrl !== initialPreview) {
				URL.revokeObjectURL(previewUrl);
			}
		};
	}, [previewUrl, initialPreview]);

	return (
		<div>
			<Label label={label} {...props} />

			<div className="">
				<div className="flex items-center w-full">
					<label
						htmlFor="file-upload"
						className="relative cursor-pointer rounded-md bg-white font-semibold text-brand-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-brand-600 focus-within:ring-offset-2 hover:text-brand-500"
					>
						<div className="flex flex-col items-center justify-center pt-5 pb-6 px-6 mt-2 w-96 h-64 border-2 border-gray-300 border-dashed rounded-lg">
							{field.value?.preview ? (
								<img
									src={field.value.preview}
									alt="Preview"
									className="max-h-56 object-contain"
								/>
							) : previewUrl ? (
								<img
									src={previewUrl}
									alt="Preview"
									className="max-h-56 object-contain"
								/>
							) : (
								<>
									<ArrowUpTrayIcon className="w-8 h-8 mb-4 text-gray-500" />
									<p className="mb-2 text-sm text-gray-500">
										<span className="font-semibold">
											Klik om te uploaden
										</span>{" "}
										of sleep een bestand
									</p>
									<p className="text-xs text-gray-500">
										PNG, JPG, GIF (MAX. 800x400px)
									</p>
								</>
							)}
						</div>
						<input
							id="file-upload"
							name={field.name}
							type="file"
							className="sr-only"
							accept="image/*"
							onChange={handleFileChange}
						/>
					</label>
				</div>
			</div>
		</div>
	);
};

export default UploadFile;
