import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";

import useFetch from "../../../shared/hooks/useFetch";
import Input from "../../../shared/components/FormElements/Input";
import Select from "../../../shared/components/FormElements/Select";
import Button from "../../../shared/components/FormElements/Button";
import CheckboxGroup from "../../../shared/components/FormElements/CheckboxGroup";
import TagInput from "../../../shared/components/FormElements/TagInput";
import BehandelaarAvatar from "./BehandelaarAvatar";

const BehandelaarForm = ({
	closeModal,
	reload,
	initialData = null,
	onUpdate = null,
}) => {
	const fetchData = useFetch();
	const { user } = useUser();
	const [occupations, setOccupations] = useState([]);
	const [treatments, setTreatments] = useState([]);

	const isEditMode = !!initialData;

	const initialValues = isEditMode
		? {
				firstName: initialData.firstName,
				lastName: initialData.lastName,
				functie: initialData.occupation._id,
				treatments: initialData.treatmentTypes || [],
				extraFuncties:
					initialData.secondaryOccupations.map((occ) => ({
						value: occ._id,
						label: occ.title,
					})) || [],
			}
		: {
				firstName: "",
				lastName: "",
				functie: "",
				treatments: [],
				extraFuncties: [],
			};

	useEffect(() => {
		const loadFormData = async () => {
			try {
				const [occupationsRes, treatmentsRes] = await Promise.all([
					fetchData("/api/occupations", "GET"),
					fetchData("/api/treatments", "GET"),
				]);

				setOccupations(
					occupationsRes.map((occ) => ({
						label: occ.title,
						value: occ._id,
					})),
				);

				setTreatments(
					treatmentsRes.map((treatment) => ({
						label: treatment.name,
						value: treatment._id,
					})),
				);
			} catch (error) {
				console.error("Error loading form data:", error);
				toast.error(
					"Er is iets misgegaan bij het laden van de gegevens",
				);
			}
		};

		loadFormData();
	}, [fetchData]);

	const handleSubmit = async (values) => {
		try {
			if (isEditMode) {
				const response = await fetchData(
					`/api/behandelaars/${initialData._id}`,
					{
						method: "PUT",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(values),
					},
				);

				if (response) {
					toast.success("Behandelaar gewijzigd", {
						position: "bottom-center",
						theme: "light",
						hideProgressBar: true,
						autoClose: 2000,
						icon: (
							<CheckCircleIcon
								className="text-green-500"
								aria-hidden="true"
							/>
						),
					});

					if (onUpdate) onUpdate(response);
				}
			} else {
				const response = await fetchData("/api/behandelaars/", {
					method: "POST",
					body: JSON.stringify({
						userId: user.id,
						behandelaars: [
							{
								firstName: values.firstName,
								lastName: values.lastName,
								function: values.functie,
								treatments: values.treatments,
								extraFuncties: values.extraFuncties,
							},
						],
					}),
					headers: { "Content-Type": "application/json" },
				});

				if (response) {
					toast.success("Nieuwe behandelaar toegevoegd", {
						position: "bottom-center",
						theme: "light",
						hideProgressBar: true,
						autoClose: 2000,
						icon: (
							<CheckCircleIcon
								className="text-green-500"
								aria-hidden="true"
							/>
						),
					});
				}
			}

			closeModal();
			reload();
		} catch (error) {
			console.error("Error submitting form:", error);
			toast.error(
				`Er is iets misgegaan bij het ${isEditMode ? "wijzigen" : "toevoegen"} van de behandelaar`,
			);
		}
	};

	return (
		<>
			{isEditMode && (
				<div className="flex items-center px-6 -mt-4">
					<BehandelaarAvatar
						firstName={initialData.firstName}
						lastName={initialData.lastName}
					/>
					<div className="pl-3">
						<h3 className="font-bold text-xl">
							Behandelaar bewerken
						</h3>
						<p className="text-gray-600 text-sm">
							{initialData.firstName} {initialData.lastName}
						</p>
					</div>
				</div>
			)}

			<Formik initialValues={initialValues} onSubmit={handleSubmit}>
				{({ values, handleChange }) => (
					<Form>
						<div className="border-b-2 pb-10 px-8 border-gray-100">
							<div className="flex gap-2">
								<Input
									label="Voornaam"
									name="firstName"
									value={values.firstName}
									onChange={handleChange}
									width="1/2"
									required
									autoComplete="off"
								/>
								<Input
									label="Achternaam"
									name="lastName"
									value={values.lastName}
									onChange={handleChange}
									width="1/2"
									autoComplete="off"
								/>
							</div>

							<Select
								label="Functie"
								name="functie"
								value={values.functie}
								onChange={handleChange}
								options={occupations}
								width="full"
								required
								autoComplete="off"
							/>

							<TagInput
								label="Kan ook behandelingen uitvoeren van de..."
								description="Bijvoorbeeld als de mondhygiënist ook behandelingen mag overnemen van de preventie-assistent"
								name="extraFuncties"
								suggestions={occupations.filter(
									(occupation) =>
										occupation.value !== values.functie,
								)}
								width="full"
							/>

							{(values.functie === "659fcad31bfb44314301ca81" ||
								(values.extraFuncties &&
									values.extraFuncties.some(
										(extraFunctie) =>
											extraFunctie.value ===
											"659fcad31bfb44314301ca81",
									))) && (
								<CheckboxGroup
									label="Behandelingen die de behandelaar kan uitvoeren"
									name="treatments"
									options={treatments}
									columns={2}
								/>
							)}
						</div>

						<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
							<Button type="submit" size="medium">
								{isEditMode
									? "Updaten"
									: "Behandelaar toevoegen"}
							</Button>
							<Button size="medium" onClick={closeModal} ghost>
								Annuleren
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default BehandelaarForm;
