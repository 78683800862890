import React, { useState, useEffect } from "react";

import { useClerk } from "@clerk/clerk-react";
import useFetch from "../../../shared/hooks/useFetch";

import FormattedDate from "../../../shared/components/util/FormattedDate";
import warningImage from "../../../assets/Warning.png";
import { Link } from "react-router-dom";
import AnimateIn from "../../../shared/components/Animations/AnimateIn";
import { Transition } from "@headlessui/react";
import { ArrowUpRightIcon } from "@heroicons/react/20/solid";

const DuplicatePatientWarning = ({ patientId }) => {
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [dateOfDuplicate, setDateOfDuplicate] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [debouncedPatientId, setDebouncedPatientId] = useState(patientId);
	const [duplicatePatientId, setDuplicatePatientId] = useState(null);

	const fetchPatient = useFetch();
	const { user } = useClerk();

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedPatientId(patientId);
		}, 1000); // Adjust the delay as needed

		return () => {
			clearTimeout(handler);
		};
	}, [patientId]);

	useEffect(() => {
		async function fetchPatientDeletion() {
			const response = await fetchPatient(
				`/api/patients/user/${user.id}/${debouncedPatientId}/duplicate`,
			);

			setIsDuplicate(response.duplicate);
			setDateOfDuplicate(response.duplicateAt);
			setDuplicatePatientId(response.patientId);
		}

		if (debouncedPatientId) {
			fetchPatientDeletion();
		}
	}, [debouncedPatientId]);

	if (!isDuplicate) {
		return null;
	}

	return (
		<Transition show={isDuplicate}>
			<AnimateIn type="fromTop">
				<div className="p-4 bg-slate-50 border rounded-xl mb-10 max-w-xl">
					<div className="">
						<img
							src={warningImage}
							alt="Warning"
							className="size-6 mb-1"
						/>
						<p className="text-sm text-slate-900 font-semibold">
							Patiënt staat al in Snelterecht
						</p>
					</div>
					<p className="text-sm text-slate-500 mt-2">
						<FormattedDate date={dateOfDuplicate} alwaysDate /> heb
						je{" "}
						<Link
							to={`/patienten/${duplicatePatientId}`}
							target="_blank"
							className="underline inline-flex items-center hover:text-slate-900 duration-150 transition-colors"
						>
							deze patiënt
							<ArrowUpRightIcon className="size-4" />
						</Link>{" "}
						al toegevoegd aan Snelterecht. Weet je zeker dat je hem
						opnieuw wilt toevoegen?
					</p>
				</div>
			</AnimateIn>
		</Transition>
	);
};

export default DuplicatePatientWarning;
