import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../../shared/hooks/useFetch";
import Table from "../../../shared/components/Tables/Table";
import FormattedDate from "../../../shared/components/util/FormattedDate";
import Heading from "../../../shared/components/UIElements/Heading";
import { DateRangePicker } from "@tremor/react";
import ProfileImage from "../../../users/components/ProfileImage";
import { Link } from "react-router-dom";
import {
	ChatBubbleBottomCenterIcon,
	FaceFrownIcon,
} from "@heroicons/react/20/solid";
import PriorityStar from "../../../patients/components/PriorityStar";

const ClaimedPatients = () => {
	const { user } = useUser();
	const fetchData = useFetch();
	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
	});
	const [claimedData, setClaimedData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchClaimedData = async () => {
			try {
				const data = await fetchData(
					`/api/admin/patients/claimed?adminId=${user.id}&from=${dateRange.from.toISOString()}&to=${dateRange.to.toISOString()}`,
				);
				setClaimedData(data);
			} catch (error) {
				console.error("Error fetching claimed patients:", error);
			}
			setIsLoading(false);
		};

		fetchClaimedData();
	}, [fetchData, user.id, dateRange]);

	return (
		<div>
			<div className="flex items-center justify-between mb-10">
				<Heading>Geclaimde patiënten</Heading>
				<DateRangePicker
					value={dateRange}
					onValueChange={setDateRange}
				/>
			</div>

			<Table data={claimedData} isLoading={isLoading}>
				<th
					scope="col"
					className="pl-6 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div className="flex items-center gap-x-4">
							<ProfileImage name={row.practice.name} />
							<div className="">
								<Link
									to={`/admin/practice/?id=${row.practice._id}`}
									className="hover:underline"
								>
									<p className="font-medium text-black">
										{row.practice.name}
									</p>
								</Link>
								<div className="flex items-center gap-x-2">
									<p className="text-sm text-gray-500">
										{row.patient.patientNumber}
									</p>
									{row.patient.priority === true && (
										<p className="text-sm text-yellow-600 font-semibold flex items-center">
											(Spoed
											<PriorityStar
												id={row.patient._id}
											/>
											)
										</p>
									)}
								</div>
							</div>
						</div>
					)}
				>
					Practice
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div>
							<p>
								<FormattedDate
									date={row.patient.createdAt}
									relative={true}
									verb="toegevoegd"
								/>
							</p>
							{row.patient.appointment?.datePlanned ? (
								<p className="text-sm text-gray-500">
									Afspraak met{" "}
									{Math.round(
										(new Date(
											row.patient.appointment.datePlanned,
										) -
											new Date(row.slot.date)) /
											(1000 * 60 * 60 * 24),
									)}{" "}
									dagen vervroegd
								</p>
							) : (
								<p className="text-sm text-gray-500">
									Nieuwe afspraak
								</p>
							)}
						</div>
					)}
				>
					Wachtlijst
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div>
							<p>
								{row.totalInvites} uitnodigingen (
								{row.responseRate?.toFixed(2) * 100}% RR)
							</p>
							{row.patient.messages.some(
								(message) => message.channel === "sms",
							) && (
								<p className="text-blue-800 font-semibold flex items-center gap-x-1">
									<ChatBubbleBottomCenterIcon className="w-4 h-4" />{" "}
									Via SMS
								</p>
							)}
							{row.patient.messages.filter(
								(m) => m.response === "accepted",
							).length > 1 && (
								<p className="text-orange-800 text-sm font-semibold gap-x-1 flex items-center">
									<FaceFrownIcon className="w-4 h-4" />
									{row.patient.messages.filter(
										(m) => m.response === "accepted",
									).length - 1}
									x eerder teleurgesteld
								</p>
							)}
						</div>
					)}
				>
					Uitnodigingen
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					render={(row) => (
						<div>
							<p>
								{!row.sameBehandelaar && "Andere behandelaar"}
							</p>
							<p className="text-sm text-gray-500">
								{row.slot.behandelaar?.name}
							</p>
						</div>
					)}
				>
					Behandelaar
				</th>
			</Table>
		</div>
	);
};

export default ClaimedPatients;
