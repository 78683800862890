import React from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import useFetch from "../../../shared/hooks/useFetch";
import useCustomConfirm from "../../../shared/hooks/useCustomConfirm";

const DeleteBehandelaar = ({ behandelaarId, name, reload, className }) => {
	const deleteBehandelaar = useFetch();
	const [confirm, ConfirmModal] = useCustomConfirm();

	const handleDelete = async () => {
		const confirmed = await confirm({
			title: "Behandelaar verwijderen",
			description: `Weet je zeker dat je ${name} wilt verwijderen?`,
			warning: true,
		});

		if (confirmed) {
			try {
				await deleteBehandelaar(`/api/behandelaars/${behandelaarId}`, {
					method: "DELETE",
				});
				toast.success("Behandelaar verwijderd");
				reload();
			} catch (error) {
				console.error(error);
				toast.error(
					"Er is iets misgegaan bij het verwijderen van de behandelaar",
				);
			}
		}
	};

	return (
		<>
			<button onClick={handleDelete} className={className}>
				<TrashIcon className="h-5 w-5 inline" />
			</button>
			<ConfirmModal />
		</>
	);
};

export default DeleteBehandelaar;
