import React from "react";
import { Form, Formik } from "formik";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import useFetch from "../../../shared/hooks/useFetch";
import Button from "../../../shared/components/FormElements/Button";
import FormFields from "./NewSlotFormFields";
import { validationSchema } from "./validationSchema";
import { handleSubmit } from "./submitHandler";
import { CalendarDaysIcon, CalendarIcon } from "@heroicons/react/16/solid";
import AnimateIn from "../../../shared/components/Animations/AnimateIn";

// Update the validationSchema to include the 'time' field
const updatedValidationSchema = validationSchema.shape({
	time: Yup.array()
		.of(Yup.string())
		.min(2)
		.required("Start- en eindtijd zijn verplicht"),
});

const NewSlotForm = ({ onSlotDetailsSubmit, initialFormValues }) => {
	const createSlot = useFetch();
	const { user } = useUser();

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={validationSchema}
			onSubmit={(values) =>
				handleSubmit(values, createSlot, user, onSlotDetailsSubmit)
			}
			enableReinitialize={true}
		>
			{({ errors, touched, submitForm, values }) => (
				<>
					<AnimateIn>
						<div className="bg-white rounded-2xl border border-slate-200 px-10 py-12 w-full md:w-1/2 shadow-sm">
							<h3 className="text-xl leading-6 font-bold text-gray-900 flex items-center gap-2">
								<CalendarDaysIcon className="size-5 text-brand-intense" />
								Lege agendaplek melden
							</h3>
							<Form>
								<FormFields
									errors={errors}
									touched={touched}
									values={values}
								/>
							</Form>
						</div>
					</AnimateIn>

					<div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 shadow-2xl">
						<div className="max-w-7xl mx-auto px-4 sm:px-6 flex justify-start">
							<Button type="button" onClick={submitForm}>
								Volgende stap →
							</Button>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

export default NewSlotForm;
