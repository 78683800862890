import React, { useState, useEffect } from "react";
import { AreaChart } from "@tremor/react";
import useFetch from "../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import SkeletonLoadingItem from "../../shared/components/UIElements/SkeletonLoadingItem";
import NumberFlow from "@number-flow/react";
import AnimateIn from "../../shared/components/Animations/AnimateIn";

const PatientStats = () => {
	const [stats, setStats] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [chartdata, setChartData] = useState([]);

	const fetchStats = useFetch();
	const { user } = useUser();

	useEffect(() => {
		const fetchStatistics = async () => {
			try {
				const response = await fetchStats(
					`/api/practice/user/${user.id}/patient-stats`,
				);

				setStats(response);
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching patient stats", error);
			}
		};

		fetchStatistics();
	}, []);

	useEffect(() => {
		if (stats && stats.length > 0) {
			const data = stats.map((stat) => ({
				date: stat.week,
				"Patiënten toegevoegd": stat.patientsAdded,
				"Zichzelf verwijderd": stat.patientsDeleted,
				"Berichten niet afgeleverd": stat.uniquePatientsErrored,
			}));
			setChartData(data);
		}
	}, [stats]);

	return (
		<div className="flex gap-3 [&>div]:w-1/3 [&>div]:justify-between [&>div]:gap-3 [&>div]:items-center [&>div]:bg-white [&>div]:py-4 [&>div]:pl-6 [&>div]:pr-4 [&>div]:rounded-2xl [&>div]:border-slate-200 [&>div]:border [&>div]:shadow-sm">
			<div className="flex">
				<div>
					<h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content mb-0.5">
						Toegevoegd
					</h3>
					<p className="text-tremor-metric text-tremor-content-strong font-semibold">
						<NumberFlow
							value={
								isLoading
									? 0
									: stats?.length > 0
										? stats[stats.length - 1].patientsAdded
										: 0
							}
						/>
					</p>
				</div>
				{isLoading ? (
					<div className="mt-4 h-20 w-32" />
				) : (
					<AnimateIn type="fromTop">
						<AreaChart
							className="mt-4 h-20 w-32"
							data={chartdata}
							index="date"
							categories={["Patiënten toegevoegd"]}
							showYAxis={false}
							showXAxis={false}
							showAnnotation={true}
							showGridLines={false}
							showLegend={false}
							colors={["indigo"]}
							curveType="monotone"
						/>
					</AnimateIn>
				)}
			</div>
			<div className="flex">
				<div>
					<h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content mb-0.5">
						Zichzelf verwijderd
					</h3>
					<div className="flex items-baseline gap-3">
						<p className="text-tremor-metric text-tremor-content-strong font-semibold">
							<NumberFlow
								value={
									isLoading
										? 0
										: stats?.length > 0
											? stats[stats.length - 1]
													.patientsDeleted
											: 0
								}
							/>
						</p>
						<Link
							className="text-sm font-semibold text-brand-500 hover:text-brand-600 transition-all duration-100 flex items-center"
							to="/patienten/verwijderd"
						>
							Bekijken
							<ChevronRightIcon className="size-4" />
						</Link>
					</div>
				</div>
				{isLoading ? (
					<div className="mt-4 h-20 w-32" />
				) : (
					<AnimateIn type="fromTop">
						<AreaChart
							className="mt-4 h-20 w-32"
							data={chartdata}
							index="date"
							categories={["Zichzelf verwijderd"]}
							showYAxis={false}
							showXAxis={false}
							showAnnotation={true}
							showGridLines={false}
							showLegend={false}
							colors={["red"]}
							curveType="monotone"
						/>
					</AnimateIn>
				)}
			</div>
		</div>
	);
};

export default PatientStats;
