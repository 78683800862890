import React from "react";
import Input from "../../../shared/components/FormElements/Input";
import MultiplePhoneInputs from "../../../shared/components/FormElements/MultiplePhoneInputs";
import PatientDeletedWarning from "./PatientDeletedWarning";
import { UserIcon } from "@heroicons/react/16/solid";

const PatientBasicInfo = ({ values }) => {
	return (
		<>
			<h3 className="text-xl leading-6 font-bold text-gray-900 flex items-center gap-2">
				<UserIcon className="size-5 text-brand-intense" />
				Patiëntgegevens
			</h3>
			<div className="flex gap-x-2">
				<Input
					label="Voornaam"
					name="firstName"
					type="text"
					autoComplete="off"
					width="1/2"
				/>
				<Input
					label="Achternaam"
					name="lastName"
					type="text"
					autoComplete="off"
					width="1/2"
				/>
			</div>
			<Input label="Patiëntnummer" name="patientnummer" type="text" />
			<MultiplePhoneInputs
				amountOfPhones={
					values.phoneNumber3 ? 3 : values.phoneNumber2 ? 2 : 1
				}
				maxPhones={3}
			/>
			<PatientDeletedWarning patientId={values.patientnummer} />
		</>
	);
};

export default PatientBasicInfo;
