import React from "react";
import { Formik, Form } from "formik";
import CheckboxGroup from "../../../shared/components/FormElements/CheckboxGroup";
import Select from "../../../shared/components/FormElements/Select";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";

const PatientFilters = ({
	onFilterChange,
	availableTreatments,
	showTreatmentFilter,
}) => {
	const initialValues = {
		appointmentType: "all",
		treatments: availableTreatments.map((t) => t._id),
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={() => {}} // We don't need form submission anymore
		>
			{({ values, setFieldValue }) => (
				<Form>
					<p className="uppercase text-xs font-bold text-brand-intense -mb-5 mt-10 flex items-center gap-1">
						<AdjustmentsHorizontalIcon className="size-3.5" />
						Filters
					</p>
					<Select
						label="Afspraaktype"
						name="appointmentType"
						options={[
							{ label: "Alle patiënten", value: "all" },
							{
								label: "Alleen patiënten zonder afspraak",
								value: "noAppointment",
							},
							{
								label: "Alleen patiënten met een afspraak",
								value: "hasAppointment",
							},
						]}
						value={values.appointmentType} // Bind value to Formik state
						onChange={(event) => {
							const value = event.target.value;
							setFieldValue("appointmentType", value);
							onFilterChange({
								...values,
								appointmentType: value,
							});
							console.log(value);
						}}
					/>
					{showTreatmentFilter &&
						availableTreatments &&
						availableTreatments.length > 0 && (
							<CheckboxGroup
								label="Behandelingen"
								name="treatments"
								options={availableTreatments.map((t) => ({
									label: t.name,
									value: t._id,
								}))}
								autoCheck={true}
								value={values.treatments} // Bind value to Formik state
								onChange={(selectedTreatments) => {
									setFieldValue(
										"treatments",
										selectedTreatments,
									);
									onFilterChange({
										...values,
										treatments: selectedTreatments,
									});
								}}
							/>
						)}
				</Form>
			)}
		</Formik>
	);
};

export default PatientFilters;
