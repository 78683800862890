import React, { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import useFetch from "../../hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import { CheckIcon } from "@heroicons/react/16/solid";

const LocationsChecklist = ({
	name,
	label,
	autoCheck = false,
	setHasLocations = () => {},
	useLocalStorage = false,
}) => {
	const [locations, setLocations] = useState([]);
	const { user } = useUser();
	const fetchLocations = useFetch();
	const [field] = useField(name);
	const { setFieldValue } = useFormikContext();

	useEffect(() => {
		const getLocations = async () => {
			try {
				const fetchedLocations = await fetchLocations(
					`/api/practice/user/${user.id}/locations`,
					"GET",
				);
				setLocations(fetchedLocations);
				setHasLocations(fetchedLocations.length >= 2);

				// Only set values if there are no existing values
				if (!field.value?.length) {
					// Try to load from localStorage if enabled
					if (useLocalStorage) {
						try {
							const savedLocations = localStorage.getItem(
								"locationPreferences",
							);
							if (savedLocations) {
								const parsedLocations =
									JSON.parse(savedLocations);
								// Only set saved locations that exist in fetchedLocations
								const validLocations = parsedLocations.filter(
									(locId) =>
										fetchedLocations.some(
											(loc) => loc._id === locId,
										),
								);
								if (validLocations.length > 0) {
									setFieldValue(name, validLocations);
									return;
								}
							}
						} catch (error) {
							console.error(
								"Error loading location preferences:",
								error,
							);
						}
					}

					// Set all locations as checked by default if autoCheck is true
					if (autoCheck && fetchedLocations.length > 0) {
						setFieldValue(
							name,
							fetchedLocations.map((loc) => loc._id),
						);
					}
				}
			} catch (error) {
				console.error("Error fetching locations:", error);
			}
		};

		if (user) {
			getLocations();
		}
	}, [
		user,
		fetchLocations,
		useLocalStorage,
		name,
		setFieldValue,
		autoCheck,
		field.value,
	]);

	if (locations.length < 2) {
		return null;
	}

	const toggleLocation = (locationId) => {
		const currentValue = field.value || [];
		let newValue;

		if (currentValue.includes(locationId)) {
			newValue = currentValue.filter((id) => id !== locationId);
		} else {
			newValue = [...currentValue, locationId];
		}

		setFieldValue(name, newValue);
	};

	return (
		<div className="w-full mt-6">
			{label && (
				<label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
					{label}
				</label>
			)}
			<div className="flex flex-wrap gap-2">
				{locations.map((location) => {
					const isSelected = field.value?.includes(location._id);
					return (
						<button
							key={location._id}
							type="button"
							onClick={() => toggleLocation(location._id)}
							className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm transition-all duration-300 ease-in-out ${
								isSelected
									? "bg-brand-100 text-brand-800 w-auto max-w-full"
									: "bg-slate-100 text-slate-800 hover:bg-slate-200 w-auto max-w-[95%]"
							}`}
						>
							{location.name}
							{isSelected && (
								<CheckIcon className="w-4 h-4 ml-1 text-brand-600 animate-[fadeIn_0.3s_ease-in-out]" />
							)}
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default LocationsChecklist;
