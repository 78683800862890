import React, { useEffect, useState } from "react";
import useFetch from "../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import Modal from "../../shared/components/UIElements/Modal";
import { GiftIcon } from "@heroicons/react/20/solid";
import {
	ArrowRightIcon,
	ArrowUpRightIcon,
	XMarkIcon,
} from "@heroicons/react/16/solid";
import Button from "../../shared/components/FormElements/Button";
import classNames from "classnames";

const UpgradeConfirmationModal = ({ link, open, setOpen }) => {
	return (
		<Modal open={open} setOpen={setOpen}>
			<div className="flex flex-col gap-2 px-6 pb-8">
				<GiftIcon className="size-8 text-[#00994C] mb-1" />
				<h1 className="text-xl font-bold text-balance">
					Schakel over naar jaarlijkse facturatie en bespaar{" "}
					<span className="text-green-700">€120/jaar</span>
				</h1>

				<p className="font-semibold text-black mt-2">
					Voordelen op een rijtje
				</p>
				<ul className="list-disc ml-6 mb-4 space-y-2">
					<li>
						In plaats van elke maand een factuur, krijg je die één
						keer per jaar (scheelt je weer{" "}
						<strong>administratie</strong>)
					</li>
					<li>
						Je krijgt omgerekend <strong>€120 korting</strong> per
						jaar
					</li>
					<li>
						Je bent het komende jaar <strong>verzekerd</strong> van
						je huidige tarief
					</li>
				</ul>
				<p className="mt-2 mb-5 text-sm text-gray-700">
					Als je overschakelt naar jaarlijkse facturatie wordt het
					jaartarief verrekend met het bedrag dat je deze maand al
					hebt betaald.
				</p>
				<Button to={link} iconSuffix={ArrowRightIcon} target="_blank">
					Schakel over en bespaar €120/jaar
				</Button>
			</div>
		</Modal>
	);
};

const UpgradeToYearly = ({ includeIgnoreButton = false }) => {
	const fetchData = useFetch();
	const { user } = useUser();

	const [upgradeLink, setUpgradeLink] = useState(null);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (user.publicMetadata.customerId) {
			fetchData(`/api/billing/upgrade-to-yearly/${user.id}`).then(
				(data) => {
					setUpgradeLink(data.upgradeLink);
				},
			);
		}
	}, [user.id]);

	const handleIgnore = async () => {
		await user.update({
			unsafeMetadata: {
				ignoreUpgradeToYearly: true,
				...user.unsafeMetadata,
			},
		});
	};

	if (
		!user.publicMetadata.customerId ||
		(includeIgnoreButton &&
			user.unsafeMetadata.ignoreUpgradeToYearly === true)
	) {
		return null;
	}

	return (
		<>
			<div
				className={classNames(
					"bg-green-50 p-6 rounded-3xl items-center gap-2 transition-all duration-500",
					{
						"opacity-0": !upgradeLink,
						"opacity-100": upgradeLink,
					},
				)}
			>
				<GiftIcon className="size-8 text-[#00994C] mb-2" />
				<h3 className="text-lg font-semibold text-green-800">
					Bespaar €120 per jaar met jaarlijkse facturatie
				</h3>
				<p className="text-sm text-[#3c6852] mt-0.5 mb-3">
					Schakel over naar jaarlijkse facturatie en krijg €120/jaar
					korting op je abonnement.
				</p>
				<div className="flex gap-6">
					<button
						onClick={() => setShowModal(true)}
						className="font-semibold flex items-center gap-1 text-green-800 hover:opacity-60 duration-200 transition-all"
					>
						Bekijk details
						<ArrowUpRightIcon className="size-5" />
					</button>
					{includeIgnoreButton && (
						<button
							onClick={handleIgnore}
							className="font-semibold flex items-center gap-1 text-green-800 opacity-80 hover:opacity-60 duration-200 transition-all"
						>
							Negeer
							<XMarkIcon className="size-5" />
						</button>
					)}
				</div>
			</div>
			<UpgradeConfirmationModal
				link={upgradeLink}
				open={showModal}
				setOpen={setShowModal}
			/>
		</>
	);
};

export default UpgradeToYearly;
