import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Heading from "../../../shared/components/UIElements/Heading";
import {
	BanknotesIcon,
	CalendarDaysIcon,
	CheckBadgeIcon,
	ClockIcon,
	ComputerDesktopIcon,
	CreditCardIcon,
	EyeIcon,
	EyeSlashIcon,
	PhoneArrowUpRightIcon,
	UserCircleIcon,
	UsersIcon,
	XCircleIcon,
} from "@heroicons/react/20/solid";
import { useUser } from "@clerk/clerk-react";
import MiniBackButton from "../../../shared/components/FormElements/MiniBackButton";

import CardWithIcon from "../../../shared/components/UIElements/CardWithIcon";

import useFetch from "../../../shared/hooks/useFetch";

import Tooltip from "../../../shared/components/UIElements/Tooltip";
import MiniCopyButton from "../../../shared/components/FormElements/MiniCopyButton";
import SinglePracticeMap from "../../components/SinglePracticeMap";
import { CircleWithMetric } from "../../../shared/components/Charts/CircleWithMetric";
import {
	AreaChart,
	Tab,
	TabGroup,
	TabList,
	TabPanel,
	TabPanels,
} from "@tremor/react";
import ActivityIndicator from "../../components/ActivityIndicator";
import { ArrowTrendingUpIcon } from "@heroicons/react/16/solid";
import Modal from "../../../shared/components/UIElements/Modal";
import PreviewPatientPortal from "../../components/PreviewPatientPortal";
import { PortalProvider } from "../../../public/context/PortalContext";

const SinglePractice = () => {
	const { user } = useUser();
	const location = useLocation();
	const practiceId = new URLSearchParams(location.search).get("id");
	const fetchData = useFetch();

	const [practiceStats, setPracticeStats] = useState(null);
	const [financeStats, setFinanceStats] = useState(null);
	const [activityStats, setActivityStats] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [openPreviewModal, setOpenPreviewModal] = useState(false);

	useEffect(() => {
		const fetchPracticeStats = async () => {
			if (!practiceId) {
				return;
			}

			setIsLoading(true);
			try {
				const data = await fetchData(
					`/api/admin/practice/${user?.id}?practiceId=${practiceId}`,
				);
				setPracticeStats(data.practiceStats);
				setFinanceStats(data.financeStats);
				setActivityStats(data.activityStats);
			} catch (error) {
				console.error("Error fetching practice stats:", error);
			}
			setIsLoading(false);
		};

		fetchPracticeStats();
	}, [fetchData, user?.id, practiceId]);

	if (!practiceId) {
		return <div>Geen praktijk ID opgegeven</div>;
	}

	if (isLoading) {
		return <div>Aan het laden...</div>;
	}

	return (
		<>
			<MiniBackButton to="/admin/practices" />

			<div className="group flex items-center gap-x-4">
				<Heading>{practiceStats?.practice?.name}</Heading>
				<div className="hidden group-hover:flex items-center gap-2">
					<Tooltip text="Clerk">
						<Link
							to={`https://dashboard.clerk.com/apps/app_2adroXLNht5HisJZaWk5fiFqpm2/instances/ins_2eBRcL5awsiVs7CDnNulzxnwJES/users/${practiceStats && practiceStats.user && practiceStats?.user?.id}`}
							target="_blank"
						>
							<UserCircleIcon className="w-4 h-4 text-slate-400" />
						</Link>
					</Tooltip>
					<Tooltip text="Stripe">
						<Link
							to={`https://dashboard.stripe.com/customers/${practiceStats && practiceStats.user && practiceStats?.user?.publicMetadata?.customerId}`}
							target="_blank"
						>
							<CreditCardIcon className="w-4 h-4 text-slate-400" />
						</Link>
					</Tooltip>
					<MiniCopyButton
						text={practiceId}
						label="ID"
						tooltip="Kopieer praktijk ID"
					/>
				</div>
			</div>
			<p className="flex items-center text-lg mt-3">
				<UserCircleIcon className="size-5 mr-2" />
				{practiceStats?.user?.firstName} {practiceStats?.user?.lastName}
			</p>

			<div className="grid grid-cols-4 gap-8 mt-14">
				<div className="col-span-3">
					<Heading size="2">Statistieken</Heading>

					<div className="mt-4 mb-10">
						<TabGroup>
							<TabList>
								<Tab>Totaal ooit</Tab>
								<Tab>Afgelopen 30 dagen</Tab>
								<Tab>Afgelopen 7 dagen</Tab>
								<Tab>Vandaag</Tab>
							</TabList>
							<div className="mt-8 mb-10">
								<TabPanels>
									<TabPanel>
										<AllTimeStats
											practiceStats={practiceStats}
											isLoading={isLoading}
										/>
									</TabPanel>
									<TabPanel>
										<PastMonthStats
											practiceStats={practiceStats}
											isLoading={isLoading}
										/>
									</TabPanel>
									<TabPanel>
										<PastSevenDaysStats
											practiceStats={practiceStats}
											isLoading={isLoading}
										/>
									</TabPanel>
									<TabPanel>
										<TodayStats
											practiceStats={practiceStats}
											isLoading={isLoading}
										/>
									</TabPanel>
								</TabPanels>
							</div>
						</TabGroup>
					</div>
				</div>

				<div>
					<div className="mb-4 border-slate-300 p-4 shadow-sm border rounded-xl">
						<div className="flex items-center mb-3">
							<ArrowTrendingUpIcon className="size-5 mr-2" />
							<h3 className="text-lg font-semibold">
								Activiteit (14d)
							</h3>
						</div>
						<ActivityIndicator activities={activityStats} />
					</div>
					<div className="my-6 border border-slate-300 rounded-xl px-5 py-3">
						<div className="flex items-center justify-between">
							<h5 className="text-lg font-semibold">
								Patiëntenportaal
							</h5>
							{practiceStats.practice.settings.patientPortal ? (
								<Tooltip content="Patiëntenportaal bewerkt">
									<button
										onClick={() =>
											setOpenPreviewModal(true)
										}
									>
										<EyeIcon className="size-5 ml-2 text-brand-intense mt-0.5" />
									</button>
								</Tooltip>
							) : (
								<Tooltip content="Patiëntenportaal niet gebrand">
									<EyeSlashIcon className="size-5 ml-2 text-slate-400" />
								</Tooltip>
							)}
						</div>
					</div>

					{financeStats !== null && (
						<div className="bg-slate-50 border border-slate-300 rounded-xl p-5">
							<div className="flex items-center ">
								<BanknotesIcon className="size-5 mr-2" />
								<h3 className="text-lg font-semibold">
									Financieel
								</h3>
							</div>
							<div className="mt-2">
								<ul className="list-disc pl-5 text-slate-800">
									<li>
										Totaal uitgegeven:
										<span className="text-green-700 font-bold">
											{" "}
											€{financeStats.allTimeSpend}
										</span>
									</li>
									<li>
										Actief sinds{" "}
										{new Date(
											financeStats.activeSince,
										).toLocaleDateString("nl-NL", {
											day: "numeric",
											month: "long",
											year: "numeric",
										})}{" "}
										({financeStats.activeFor} maanden)
									</li>
									<li>
										Abonnement:{" "}
										<span className="font-semibold">
											{financeStats?.plan?.name}
										</span>{" "}
										(€{financeStats?.plan?.price}/
										{financeStats?.plan?.interval})
									</li>
								</ul>
							</div>
						</div>
					)}

					<div className="mt-6 bg-slate-50 border border-slate-300 rounded-xl p-5">
						<div className="flex items-center mb-2">
							<ComputerDesktopIcon className="size-5 mr-2" />
							<h3 className="text-lg font-semibold">
								Integratie
							</h3>
						</div>
						{practiceStats.user?.unsafeMetadata
							.praktijkSoftware && (
							<p className="text-slate-800">
								Praktijksoftware:{" "}
								{
									practiceStats.user?.unsafeMetadata
										.praktijkSoftware
								}
							</p>
						)}
						{practiceStats.user?.unsafeMetadata
							.desktopAppConnected === true ? (
							<p className=" text-green-700 font-semibold flex items-center gap-1">
								<CheckBadgeIcon className="size-5" />
								Account gekoppeld
							</p>
						) : (
							<p className=" text-slate-700 flex items-center gap-1">
								<XCircleIcon className="size-5" />
								Account niet gekoppeld
							</p>
						)}
						{practiceStats.doesUseIntegration ? (
							<p className="text-green-700 font-semibold flex mt-2 gap-1 items-center">
								<CheckBadgeIcon className="size-5" />
								Gebruikt
							</p>
						) : null}
					</div>
					<div className="mt-6">
						<SinglePracticeMap
							locations={practiceStats.practice.locations}
						/>
					</div>
				</div>
			</div>
			<div className="mt-14">
				<Heading size="2">Metadata</Heading>
				<div className="grid grid-cols-3 gap-6 mt-5">
					<div>
						<h4 className="text-lg font-semibold">Public</h4>
						<pre className="text-sm p-2 bg-slate-50 border border-slate-300 rounded-xl">
							{JSON.stringify(
								practiceStats.user?.publicMetadata,
								null,
								2,
							)}
						</pre>
					</div>
					<div>
						<h4 className="text-lg font-semibold">Unsafe</h4>
						<pre className="text-sm p-2 bg-slate-50 border border-slate-300 rounded-xl">
							{JSON.stringify(
								practiceStats.user?.unsafeMetadata,
								null,
								2,
							)}
						</pre>
					</div>
					<div>
						<h4 className="text-lg font-semibold">Private</h4>
						<pre className="text-sm p-2 bg-slate-50 border border-slate-300 rounded-xl">
							{JSON.stringify(
								practiceStats.user?.privateMetadata,
								null,
								2,
							)}
						</pre>
					</div>
				</div>
			</div>
			<Modal open={openPreviewModal} setOpen={setOpenPreviewModal}>
				<PortalProvider>
					<PreviewPatientPortal
						previewSettings={
							practiceStats.practice.settings.patientPortal
						}
					/>
				</PortalProvider>
			</Modal>
		</>
	);
};

const AllTimeStats = ({ practiceStats, isLoading }) => {
	const formatChartData = (data) => {
		return data.map((item) => ({
			date: new Date(item.date).toLocaleDateString("en-US", {
				month: "short",
				day: "numeric",
			}),
			PatientsAdded: item.PatientsAdded,
		}));
	};

	return (
		<>
			<div className="grid grid-cols-3 gap-4 mb-10">
				<CardWithIcon icon={CalendarDaysIcon}>
					<CircleWithMetric
						value={practiceStats.allTime.fillRate * 100}
						metric={`${practiceStats.allTime.slotsFilled} / ${practiceStats.allTime.slotsCreated}`}
						label="plekken gevuld"
					/>
				</CardWithIcon>
				<CardWithIcon
					icon={PhoneArrowUpRightIcon}
					value={practiceStats.allTime.callsSaved}
					label="telefoontjes"
					subtitle="bespaard"
					isLoading={isLoading}
				/>
				<CardWithIcon
					icon={UsersIcon}
					value={practiceStats.allTime.patientsAdded}
					label="patiënten"
					subtitle="toegevoegd"
					isLoading={isLoading}
				/>
			</div>
			<div>
				<h4 className="text-lg mb-4 font-semibold text-black">
					Patiënten toegevoegd per week
				</h4>
				<AreaChart
					data={practiceStats.chartData}
					index="date"
					categories={["PatientsAdded"]}
				/>
			</div>
			<div className="mt-14">
				<h4 className="text-lg mb-4 font-semibold text-black">
					Berichten
				</h4>
				<div className="grid grid-cols-2 gap-4">
					<CardWithIcon icon={CheckBadgeIcon}>
						<CircleWithMetric
							value={practiceStats.allTime.readRate * 100}
							metric={`${(practiceStats.allTime.readRate * 100).toFixed(1)}% read rate`}
							label="uitnodigingen gelezen, gemiddeld 68%"
						/>
					</CardWithIcon>
					<CardWithIcon icon={ClockIcon}>
						<CircleWithMetric
							value={practiceStats.allTime.responseRate * 100}
							metric={`${(practiceStats.allTime.responseRate * 100).toFixed(1)}% response rate`}
							label="gereageerd ja/nee, gemiddeld 36%"
						/>
					</CardWithIcon>
				</div>
			</div>
		</>
	);
};

const PastMonthStats = ({ practiceStats, isLoading }) => {
	return (
		<>
			<div className="grid grid-cols-3 gap-4">
				<CardWithIcon
					icon={CalendarDaysIcon}
					value={`${practiceStats.pastThirtyDays.slotsFilled} / ${practiceStats.pastThirtyDays.slotsCreated} (${(practiceStats.pastThirtyDays.fillRate * 100 || 0).toFixed(2)}%)`}
					subtitle="plekken gevuld"
					isLoading={isLoading}
				/>
				<CardWithIcon
					icon={PhoneArrowUpRightIcon}
					value={practiceStats.pastThirtyDays.callsSaved}
					label="telefoontjes"
					subtitle="bespaard"
					isLoading={isLoading}
				/>
				<CardWithIcon
					icon={UsersIcon}
					value={practiceStats.pastThirtyDays.patientsAdded}
					label="patiënten"
					subtitle="toegevoegd"
					isLoading={isLoading}
				/>
			</div>
		</>
	);
};

const PastSevenDaysStats = ({ practiceStats, isLoading }) => {
	return (
		<div className="grid grid-cols-3 gap-4">
			<CardWithIcon
				icon={CalendarDaysIcon}
				value={`${practiceStats.pastSevenDays.slotsFilled} / ${practiceStats.pastSevenDays.slotsCreated} (${(practiceStats.pastSevenDays.fillRate * 100 || 0).toFixed(2)}%)`}
				subtitle="plekken gevuld"
				isLoading={isLoading}
			/>
			<CardWithIcon
				icon={PhoneArrowUpRightIcon}
				value={practiceStats.pastSevenDays.callsSaved}
				label="telefoontjes"
				subtitle="bespaard"
				isLoading={isLoading}
			/>

			<CardWithIcon
				icon={UsersIcon}
				value={practiceStats.pastSevenDays.patientsAdded}
				label="patiënten"
				subtitle="toegevoegd"
				isLoading={isLoading}
			/>
		</div>
	);
};

const TodayStats = ({ practiceStats, isLoading }) => {
	return (
		<>
			{practiceStats.today.slotsFilled === 0 &&
			practiceStats.today.callsSaved === 0 &&
			practiceStats.today.patientsAdded === 0 ? (
				<p className="text-orange-800 bg-orange-50 border border-orange-200 rounded-xl px-4 py-2  text-sm w-fit">
					Geen activiteit vandaag
				</p>
			) : null}
			<div className="grid grid-cols-4 gap-4">
				<CardWithIcon
					icon={CalendarDaysIcon}
					value={`${practiceStats.today.slotsFilled} / ${practiceStats.today.slotsCreated} (${(practiceStats.today.fillRate * 100 || 0).toFixed(2)}%)`}
					subtitle="plekken gevuld"
					isLoading={isLoading}
				/>
				<CardWithIcon
					icon={PhoneArrowUpRightIcon}
					value={practiceStats.today.callsSaved}
					label="telefoontjes"
					subtitle="bespaard"
					isLoading={isLoading}
				/>
				<CardWithIcon
					icon={ClockIcon}
					value={practiceStats.allTime.averageResponseTime}
					label="minuten"
					subtitle="reactietijd"
					isLoading={isLoading}
				/>
				<CardWithIcon
					icon={UsersIcon}
					value={practiceStats.today.patientsAdded}
					label="patiënten"
					subtitle="toegevoegd"
					isLoading={isLoading}
				/>
			</div>
		</>
	);
};

export default SinglePractice;
