import React, { useState, useEffect } from "react";
import useFetch from "../../shared/hooks/useFetch";
import Link from "../../shared/components/UIElements/Link";
import AnimateIn from "../../shared/components/Animations/AnimateIn";
import { useNavigate } from "react-router-dom";
import posthog from "posthog-js";
import { ArrowUpRightIcon } from "@heroicons/react/20/solid";

const SuggestedSlot = ({ slotId }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [doSuggestSlot, setDoSuggestSlot] = useState(false);
	const [suggestedSlot, setSuggestedSlot] = useState({});
	const [eligiblePatients, setEligiblePatients] = useState([]);

	const fetchData = useFetch();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchSuggestedSlot = async () => {
			const response = await fetchData(
				`/api/slots/suggested-slot/${slotId}`,
			);
			setDoSuggestSlot(response.suggestSlot);

			if (response.suggestSlot) {
				setSuggestedSlot(response.slot);
				setEligiblePatients(response.eligiblePatients);
			}

			setIsLoading(false);
		};

		fetchSuggestedSlot();
	}, [fetchData, slotId]);

	const handleClick = () => {
		posthog.capture("🆕 Suggestie voor lege plek geklikt", {
			slotId,
		});
		navigate(
			`/gaten/nieuw?date=${suggestedSlot.date}&startTime=${suggestedSlot.startTime}&endTime=${suggestedSlot.endTime}&behandelaar=${suggestedSlot.behandelaar._id}&location=${suggestedSlot.location._id}`,
		);
	};

	if (isLoading || !doSuggestSlot) return null;

	return (
		<AnimateIn type="fromTop">
			<div className="bg-slate-200 p-5 rounded-2xl">
				<h4 className="font-semibold">
					Wil je een nieuwe plek melden voor de{" "}
					{suggestedSlot.duration} minuten die nog vrij zijn?
				</h4>
				<p className="text-sm text-slate-800 pb-3 pt-1">
					Er {eligiblePatients === 1 ? "is" : "zijn"}{" "}
					{eligiblePatients} patiënten die hiervoor in aanmerking
					komen.
				</p>
				<button
					onClick={handleClick}
					className="text-brand-intense font-semibold flex items-center gap-1 hover:opacity-70 transition-all duration-200"
				>
					Plek melden
					<ArrowUpRightIcon className="w-4 h-4" />
				</button>
			</div>
		</AnimateIn>
	);
};

export default SuggestedSlot;
