import React from "react";
import dayjs from "dayjs";
import TimeRangePicker from "../../../shared/components/FormElements/TimeRangePicker";
import FieldSuggestions from "../../../shared/components/FormElements/FieldSuggestions";

const SlotTimePicker = ({ errors, touched, values }) => {
	const getSuggestion = (minutes) => {
		if (!values.startTime) return "";
		return dayjs(values.startTime, "HH:mm")
			.add(minutes, "minute")
			.format("HH:mm");
	};

	const suggestions = [
		{ label: "+30 min", value: getSuggestion(30) },
		{ label: "+40 min", value: getSuggestion(40) },
		{ label: "+45 min", value: getSuggestion(45) },
	];

	return (
		<>
			<TimeRangePicker
				label="Start- en eindtijd"
				startTimeName="startTime"
				endTimeName="endTime"
				required
				error={
					(errors.startTime && touched.startTime) ||
					(errors.endTime && touched.endTime)
				}
			/>
			<FieldSuggestions
				fieldName="endTime"
				suggestions={suggestions}
				label="Suggesties"
				isVisible={values.startTime && !values.endTime}
			/>
		</>
	);
};

export default SlotTimePicker;
