import React from "react";
import FieldSuggestions from "../../../shared/components/FormElements/FieldSuggestions";

import dayjs from "dayjs";
import "dayjs/locale/nl";

const NextAppointmentDateSuggestion = ({ hide, dateString }) => {
	const formatDate = (dateStr) => {
		const date = dayjs(dateStr, "DD-MM-YYYY").locale("nl");
		return date.format("D MMM YYYY");
	};

	const suggestion = dateString
		? {
				label: formatDate(dateString),
				value: dayjs(dateString, "DD-MM-YYYY"),
			}
		: null;

	return (
		<>
			{suggestion && (
				<FieldSuggestions
					suggestions={[suggestion]}
					fieldName="unformattedDate"
					isVisible={!hide}
					label="Misschien..."
					eventTrackingFieldName="patientNextAppointmentDate"
				/>
			)}
		</>
	);
};

export default NextAppointmentDateSuggestion;
