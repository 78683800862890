import React from "react";
import { useField } from "formik";
import Label from "../../../shared/components/FormElements/Label";
import classNames from "classnames";
import Tooltip from "../../../shared/components/UIElements/Tooltip";

import nlFlag from "../../../assets/emojis/FlagNetherlands.png";
import enFlag from "../../../assets/emojis/FlagEnglish.png";
import beFlag from "../../../assets/emojis/FlagBelgium.png";

const LanguagePicker = ({ name, label, description, tooltip }) => {
	const [field, meta, helpers] = useField(name);

	const languageOptions = [
		{
			value: "nl",
			label: nlFlag,
			tooltip: "Nederlands",
		},
		{
			value: "en",
			label: enFlag,
			tooltip: "Engels",
		},
		{
			value: "be",
			label: beFlag,
			tooltip: "Vlaams",
		},
	];

	return (
		<div className="mt-6">
			<Label
				name={name}
				label={label}
				description={description}
				tooltip={tooltip}
			/>
			<div className="inline-flex p-1 bg-gray-200 rounded-xl">
				{languageOptions.map((option) => (
					<Tooltip
						key={option.value}
						content={option.tooltip}
						place="bottom"
						id={`language-picker-tooltip-${option.value}`}
					>
						<button
							type="button"
							onClick={() => helpers.setValue(option.value)}
							className={classNames(
								"flex items-center justify-center w-14 h-8 rounded-lg transition-all duration-200 ease-in-out",
								{
									"bg-white shadow-md text-gray-900":
										field.value === option.value,
									"text-gray-500 hover:bg-gray-300":
										field.value !== option.value,
								},
							)}
						>
							<img
								src={option.label}
								alt={option.tooltip}
								className="w-6 h-6"
							/>
						</button>
					</Tooltip>
				))}
			</div>
			{meta.touched && meta.error && (
				<div className="mt-2 text-sm text-red-600">{meta.error}</div>
			)}
		</div>
	);
};

export default LanguagePicker;
