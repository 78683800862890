import React, { useEffect, useState, useRef } from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";

const Carousel = ({ children, defaultColumns = 3, gap = 4 }) => {
	const [showRightGradient, setShowRightGradient] = useState(false);
	const [showLeftGradient, setShowLeftGradient] = useState(false);
	const scrollContainerRef = useRef(null);

	useEffect(() => {
		const checkOverflow = () => {
			if (scrollContainerRef.current) {
				const { scrollWidth, clientWidth, scrollLeft } =
					scrollContainerRef.current;
				setShowRightGradient(
					scrollWidth > clientWidth &&
						scrollLeft < scrollWidth - clientWidth,
				);
				setShowLeftGradient(scrollLeft > 0);
			}
		};

		checkOverflow();
		window.addEventListener("resize", checkOverflow);
		const scrollContainer = scrollContainerRef.current;
		if (scrollContainer) {
			scrollContainer.addEventListener("scroll", checkOverflow);
		}
		return () => {
			window.removeEventListener("resize", checkOverflow);
			if (scrollContainer) {
				scrollContainer.removeEventListener("scroll", checkOverflow);
			}
		};
	}, [children]);

	const scroll = (direction) => {
		if (scrollContainerRef.current) {
			const scrollAmount =
				direction === "right"
					? scrollContainerRef.current.clientWidth
					: -scrollContainerRef.current.clientWidth;
			scrollContainerRef.current.scrollBy({
				left: scrollAmount,
				behavior: "smooth",
			});
		}
	};

	return (
		<div className="relative">
			<div
				ref={scrollContainerRef}
				className="overflow-x-auto pb-4 scrollbar-hide"
			>
				<div
					className={`grid grid-flow-col auto-cols-max gap-${gap} w-max`}
					style={{
						gridTemplateColumns: `repeat(${defaultColumns}, minmax(0, 1fr))`,
					}}
				>
					{children}
				</div>
			</div>
			{showRightGradient && (
				<>
					<div className="absolute top-0 right-0 bottom-0 w-24 bg-gradient-to-l from-white to-transparent pointer-events-none" />
					<button
						onClick={() => scroll("right")}
						className="absolute top-1/2 right-2 -translate-y-1/2 bg-white rounded-full p-2 shadow-sm hover:shadow-md transition-all border border-gray-200"
					>
						<ChevronRightIcon className="w-6 h-6 text-gray-600" />
					</button>
				</>
			)}
			{showLeftGradient && (
				<>
					<div className="absolute top-0 left-0 bottom-0 w-24 bg-gradient-to-r from-white to-transparent pointer-events-none" />
					<button
						onClick={() => scroll("left")}
						className="absolute top-1/2 left-2 -translate-y-1/2 bg-white rounded-full p-2 shadow-sm hover:shadow-md transition-all border border-gray-200"
					>
						<ChevronLeftIcon className="w-6 h-6 text-gray-600" />
					</button>
				</>
			)}
		</div>
	);
};

export default Carousel;
