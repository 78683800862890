import { toast } from "react-toastify";

export const handleSubmit = async (
	fromDesktopApp,
	values,
	actions,
	step,
	setStep,
	fromWizard,
	setCurrentStep,
	isEditing,
	initialPatient,
	user,
	createPatient,
	navigate,
) => {
	if (step === 1) {
		setStep(2);
		if (fromWizard) setCurrentStep(1);
		actions.setTouched({});
		actions.setSubmitting(false);
	} else {
		console.log(values.availability);
		try {
			const behandelingDate = values.unformattedDate;
			const date = behandelingDate
				? behandelingDate.format("YYYY-MM-DD")
				: null;

			const inviteFrom = values.unformattedInviteFrom
				? values.unformattedInviteFrom.format("YYYY-MM-DD")
				: null;

			let body = {
				...values,
				datumGeplandeAfspraak: date,
				creatorId: user.id,
				inviteFrom,
				source: fromDesktopApp ? "desktop-app" : "manual",
			};

			console.log(body);

			let response;

			if (isEditing) {
				body.patientId = initialPatient._id;
				response = await createPatient(
					`/api/patients/${initialPatient.id}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(body),
					},
				);

				toast.success("Patiënt geüpdate", {
					position: "bottom-center",
				});
				navigate(`/patienten/${response._id}`);
			} else {
				response = await createPatient("/api/patients", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(body),
				});

				if (response._id) {
					toast.success(
						"Patiënt succesvol toegevoegd aan Snelterecht",
						{
							position: "bottom-center",
						},
					);
					navigate(`/patienten/${response._id}`);
				} else {
					toast.error(response.message, {
						position: "bottom-center",
						autoClose: 500000,
					});
				}
			}
		} catch (error) {
			console.error(error);
		}
	}
};
