import React from "react";
import { useFormikContext } from "formik";
import dayjs from "dayjs";
import SelectBehandelaar from "../../../practice/components/Behandelaren/SelectBehandelaar";
import DatePicker from "../../../shared/components/FormElements/DatePicker";
import TimeRangePicker from "../../../shared/components/FormElements/TimeRangePicker";
import LocationPicker from "../../../practice/components/LocationPicker";
import SlotDatePicker from "./SlotDatePicker";
import SlotTimePicker from "./SlotTimePicker";

const FormFields = ({ errors, touched, values }) => {
	return (
		<>
			<div className="flex gap-x-2">
				<SelectBehandelaar
					label="Behandelaar"
					name="behandelaar"
					value={values.behandelaar}
					width="1/2"
					required
				/>
			</div>
			<SlotDatePicker errors={errors} touched={touched} />
			<SlotTimePicker errors={errors} touched={touched} values={values} />
			<LocationPicker />
		</>
	);
};

export default FormFields;
