import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
	CheckIcon,
	ChevronUpDownIcon,
	PlusIcon,
} from "@heroicons/react/20/solid";
import useFetch from "../../../shared/hooks/useFetch";
import { useField, useFormikContext } from "formik";
import BehandelaarAvatar from "./BehandelaarAvatar";
import { useUser } from "@clerk/clerk-react";
import Button from "../../../shared/components/FormElements/Button";
import FieldErrorMessage from "../../../shared/components/FormElements/FieldErrorMessage";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function SelectBehandelaar(props) {
	const [field, meta, helpers] = useField(props);
	const { setFieldValue } = useFormikContext();
	const { user } = useUser();

	const [behandelaars, setBehandelaars] = useState([]);
	const [selected, setSelected] = useState(props.value || null);
	const [selectedBehandelaar, setSelectedBehandelaar] = useState(null);

	const fetchBehandelaars = useFetch();

	const handleChange = (value) => {
		setSelected(value);
		setFieldValue(props.name, value);

		if (props.setBehandelaarTreatments) {
			const selectedBehandelaar = behandelaars.find(
				(b) => b._id === value,
			);

			if (selectedBehandelaar) {
				props.setBehandelaarTreatments(
					selectedBehandelaar.treatmentTypes,
				);
			}
		}
	};

	useEffect(() => {
		const getBehandelaars = async () => {
			const response = await fetchBehandelaars(
				`/api/behandelaars/${user.id}`,
				"GET",
			);
			setBehandelaars(response);

			if (response.length === 1) {
				handleChange(response[0]._id);
			}
		};

		getBehandelaars();
	}, [fetchBehandelaars, user.id]);

	useEffect(() => {
		// Update selected when props.value changes
		if (props.value && props.value !== selected) {
			setSelected(props.value);
		}
	}, [props.value, selected]);

	useEffect(() => {
		const slctdBehandelaar = behandelaars.find((b) => b._id === selected);
		setSelectedBehandelaar(slctdBehandelaar);

		// Add this block to set the selectedOccupation
		if (slctdBehandelaar && props.setSelectedOccupation) {
			props.setSelectedOccupation(slctdBehandelaar.occupation);
		}
	}, [behandelaars, selected, props.setSelectedOccupation]);

	return (
		<div className="block">
			<Listbox value={selected} onChange={handleChange}>
				{({ open }) => (
					<div>
						<Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 mt-6">
							{props.label}
						</Listbox.Label>
						<div className="relative mt-2">
							<Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-500 sm:text-sm sm:leading-6">
								<span className="flex items-center">
									{selectedBehandelaar && (
										<div className="mr-3">
											<BehandelaarAvatar
												firstName={
													selectedBehandelaar.firstName
												}
												lastName={
													selectedBehandelaar.lastName
												}
											/>
										</div>
									)}
									<span className="block">
										{selectedBehandelaar
											? `${selectedBehandelaar.firstName} ${selectedBehandelaar.lastName}`
											: "Selecteer behandelaar..."}
									</span>
								</span>
								<span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
									<ChevronUpDownIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</span>
							</Listbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Listbox.Options className="absolute z-50 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{behandelaars.length === 0 ? (
										<div className="text-center text-gray-700 py-2 px-3">
											Je hebt nog geen behandelaren
											toegevoegd.{" "}
											<Button
												to="/instellingen"
												ghost
												fullWidth
												iconPrefix={PlusIcon}
												classNames="mt-2"
											>
												Voeg toe
											</Button>
										</div>
									) : (
										behandelaars.map((person) => (
											<Listbox.Option
												key={person._id}
												className={({ active }) =>
													classNames(
														active
															? "bg-brand-700 text-white"
															: "text-gray-900",
														"relative cursor-default select-none py-2 pl-3 pr-9",
													)
												}
												value={person._id}
											>
												{({ selected, active }) => (
													<>
														<div className="flex items-center">
															<BehandelaarAvatar
																firstName={
																	person.firstName
																}
																lastName={
																	person.lastName
																}
															/>
															<span
																className={classNames(
																	selected
																		? "font-semibold"
																		: "font-normal",
																	"ml-3 block",
																)}
															>
																{`${person.firstName} ${person.lastName}`}
															</span>
														</div>
														{selected && (
															<span
																className={classNames(
																	active
																		? "text-white"
																		: "text-brand-600",
																	"absolute inset-y-0 right-0 flex items-center pr-4",
																)}
															>
																<CheckIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															</span>
														)}
													</>
												)}
											</Listbox.Option>
										))
									)}
								</Listbox.Options>
							</Transition>
						</div>
					</div>
				)}
			</Listbox>
			{meta.touched && meta.error ? (
				<div className="text-orange-600 text-sm pt-2 animate-fade-in">
					{meta.error}
				</div>
			) : null}
		</div>
	);
}
