import { CheckIcon } from "@heroicons/react/16/solid";
import React from "react";
import { useParams } from "react-router-dom";
import PatientFeedback from "../components/PatientFeedback";

const SlotConfirmed = () => {
	const { slotId } = useParams();

	const queryParams = new URLSearchParams(window.location.search);
	const patientId = queryParams.get("pt");

	return (
		<div className="flex flex-col h-screen justify-center items-center px-6 pt-24 pb-6">
			<div className="">
				<div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
					<CheckIcon
						className="h-6 w-6 text-green-600"
						aria-hidden="true"
					/>
				</div>
				<div className="mt-3 text-center sm:mt-5">
					<h3 className="text-2xl font-bold leading-6 text-gray-900">
						Gelukt! Uw afspraak is bevestigd.
					</h3>
					<div className="mt-4">
						<p className="text-gray-500">
							We zien u graag in de praktijk op de afgesproken
							tijd en plaats. Er is net ter bevestiging een
							WhatsApp bericht naar u verzonden.
						</p>
					</div>
					<div className="mt-8">
						<PatientFeedback patientId={patientId} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SlotConfirmed;
