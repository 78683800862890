import React, { useState } from "react";
import { generate, green, presetPalettes, red } from "@ant-design/colors";
import { ColorPicker, theme } from "antd";
import { useField } from "formik";
import Label from "./Label";
import FieldErrorMessage from "./FieldErrorMessage";

const genPresets = (presets = presetPalettes) =>
	Object.entries(presets).map(([label, colors]) => ({ label, colors }));

const CustomColorPicker = ({ label, doOnChange = () => {}, ...props }) => {
	const [field, meta, helpers] = useField(props);

	const presets = genPresets({
		aanbevolen: [
			"#7634DE",
			"#2456d6",
			"#32dca4",
			"#5e7062",
			"#0b8721",
			"#e7c348",
			"#fab784",
		],
	});

	return (
		<div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
			<div className="col-span-full">
				<Label label={label} {...props} />
				<div className="mt-2">
					<div className="flex items-center">
						<ColorPicker
							presets={presets}
							value={field.value}
							onChange={(color) => {
								helpers.setValue(color.toHexString());
								doOnChange(color.toHexString());
							}}
							size="large"
							disabledAlpha
							{...props}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomColorPicker;
