import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import Modal from "../../../shared/components/UIElements/Modal";
import RadioGroup from "../../../shared/components/FormElements/RadioGroup";
import Textarea from "../../../shared/components/FormElements/Textarea";
import Button from "../../../shared/components/FormElements/Button";
import { StopCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/16/solid";
import useFetch from "../../../shared/hooks/useFetch";
import { toast } from "react-toastify";

const DeleteSlotButton = ({ slot }) => {
	const [showModal, setShowModal] = useState(false);
	const [showReasonForm, setShowReasonForm] = useState(false);
	const navigate = useNavigate();
	const fetchSlot = useFetch();

	const handleDelete = async () => {
		try {
			const response = await fetchSlot(`/api/slots/${slot._id}`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
				},
			});

			setShowReasonForm(true);
		} catch (error) {
			console.error("Error deleting slot:", error);
		}
	};

	const handleSubmitReason = async (values) => {
		try {
			if (values.reason === "") {
				handleSuccess();
				return;
			}

			const response = await fetchSlot(
				`/api/slots/${slot._id}/delete-reason`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						deleteReason: values.reason,
						deleteComment: values.comment,
					}),
				},
			);

			handleSuccess();
		} catch (error) {
			console.error("Error submitting delete reason:", error);
		}
	};

	const handleSuccess = () => {
		toast.success("Uitnodigingen gestopt", {
			position: "bottom-center",
		});
		setShowModal(false);
		navigate("/gaten");
	};

	const deleteReasonOptions = [
		{
			label: "De plek is al opgevuld aan de receptie of in de behandelkamer",
			value: "filled_in_practice",
		},
		{
			label: "Ik heb gebeld om de plek op te vullen",
			value: "called_to_fill",
		},

		{ label: "Verkeerd ingevoerd", value: "wrong_input" },
		{ label: "Anders, namelijk...", value: "other" },
	];

	const ModalIcon = () => (
		<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
			<StopCircleIcon
				className="h-6 w-6 text-red-600"
				aria-hidden="true"
			/>
		</div>
	);

	const ConfirmationContent = () => (
		<div className="bg-white">
			<div className="sm:flex sm:items-start px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
				<ModalIcon />
				<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
					<h3 className="text-base font-semibold leading-6 text-gray-900">
						Weet je zeker dat je deze lege plek wilt verwijderen?
					</h3>
					<div className="mt-2">
						<p className="text-sm text-gray-500">
							Patiënten die zijn uitgenodigd kunnen de afspraak
							niet meer bevestigen.
						</p>
					</div>
				</div>
			</div>
			<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
				<Button size="medium" onClick={handleDelete} danger>
					Ja, verwijderen
				</Button>
				<Button size="medium" ghost onClick={() => setShowModal(false)}>
					Annuleren
				</Button>
			</div>
		</div>
	);

	const ReasonFormContent = () => (
		<div className="bg-white">
			<div className="sm:flex sm:items-start px-3 pb-4 pt-5">
				<div className="mt-5 text-center sm:ml-4 sm:mt-0 sm:text-left pt-3">
					<h4 className="flex items-center gap-2 text-green-700 font-semibold text-sm">
						<CheckCircleIcon className="size-4" />
						Plek verwijderd
					</h4>
					<h3 className="text-base font-semibold leading-6 text-gray-900 pt-1">
						Waarom heb je de uitnodigingen stopgezet?
					</h3>
					<div className="mt-1">
						<Formik
							initialValues={{ reason: "", comment: "" }}
							onSubmit={handleSubmitReason}
						>
							{({ values }) => (
								<Form>
									<RadioGroup
										name="reason"
										options={deleteReasonOptions}
										label="Reden voor verwijderen"
									/>
									<Textarea
										name="comment"
										label="Toelichting"
										optional={values.reason !== "other"}
									/>
									<div className="pt-5 pb-1 sm:flex sm:flex-row-reverse gap-2">
										<Button
											type="submit"
											size="medium"
											iconPrefix={CheckIcon}
										>
											Afronden
										</Button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<Button
				ghost
				iconPrefix={StopCircleIcon}
				onClick={() => setShowModal(true)}
				fullWidth
			>
				Stop uitnodigen
			</Button>

			<Modal
				open={showModal}
				setOpen={setShowModal}
				includeCloseButton={false}
			>
				{showReasonForm ? (
					<ReasonFormContent />
				) : (
					<ConfirmationContent />
				)}
			</Modal>
		</>
	);
};

export default DeleteSlotButton;
