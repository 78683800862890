import React from "react";
import PatientPortal from "../../public/pages/PatientPortal";

const PreviewPatientPortal = ({ previewSettings }) => {
	return (
		<>
			<div className="mx-auto w-[380px] h-[780px] bg-black rounded-[55px] p-[12px] relative">
				<div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[158px] h-[45px] bg-black rounded-b-[20px] z-10">
					<div className="w-[10px] h-[10px] bg-[#1a1a1a] absolute left-[50px] top-[12px] rounded-full"></div>
					<div className="w-[48px] h-[8px] bg-[#1a1a1a] absolute left-[65px] top-[13px] rounded-full"></div>
				</div>
				<div className="w-full h-full bg-white rounded-[44px] overflow-hidden">
					<div className="scale-90 mt-10 pointer-events-none">
						<PatientPortal previewSettings={previewSettings} />
					</div>
				</div>
			</div>
		</>
	);
};

export default PreviewPatientPortal;
