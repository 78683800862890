import React from "react";
import { useUser } from "@clerk/clerk-react";
import Link from "../../shared/components/UIElements/Link";
import { ComputerDesktopIcon } from "@heroicons/react/16/solid";

const DesktopAppNotice = () => {
	const { user } = useUser();

	return (
		<div className="bg-slate-200 bg-opacity-50 rounded-2xl p-5 w-full text-sm text-slate-900">
			<ComputerDesktopIcon className="size-6 mb-2 text-brand-intense" />
			<p>
				Heb je onze desktop app al geïnstalleerd? Hiermee voeg je{" "}
				<strong>vanuit de patiëntenkaart</strong> iemand toe aan
				Snelterecht. Scheelt weer overtypwerk!
				<Link
					to="https://app.snelterecht.nl/instellingen/koppeling"
					target="_blank"
					classNames="mt-2"
				>
					Meer lezen
				</Link>
			</p>
		</div>
	);
};

export default DesktopAppNotice;
