import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

// Fix for default marker icon
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
	iconUrl: require("leaflet/dist/images/marker-icon.png"),
	shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const SinglePracticeMap = ({ locations }) => {
	const mapCenter =
		locations.length > 0
			? [
					locations[0].coordinates?.latitude,
					locations[0].coordinates?.longitude,
				]
			: [52.132633, 5.291266]; // Default to center of Netherlands if no locations
	const mapZoom = 8;

	if (locations[0]?.coordinates?.latitude === undefined) {
		return null;
	}

	return (
		<div style={{ height: "320px", width: "100%", marginBottom: "2rem" }}>
			<MapContainer
				center={mapCenter}
				zoom={mapZoom}
				style={{
					height: "100%",
					width: "100%",
					borderRadius: "1rem",
				}}
			>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				/>
				{locations.map((location) => (
					<Marker
						key={location._id}
						position={[
							location.coordinates?.latitude,
							location.coordinates?.longitude,
						]}
					>
						<Popup>
							<div>
								<h5 className="font-bold">{location?.name}</h5>
								<p>{location?.address?.city}</p>
							</div>
						</Popup>
					</Marker>
				))}
			</MapContainer>
		</div>
	);
};

export default SinglePracticeMap;
