import { Transition } from "@headlessui/react";
import React from "react";
import AnimateIn from "../Animations/AnimateIn";
import { BellAlertIcon } from "@heroicons/react/20/solid";

const Notice = ({
	show,
	children,
	type = "info",
	customStyles = "",
	title = "Let op...",
}) => {
	const typeStyles = {
		info: "border-blue-500 bg-blue-50 text-blue-800",
		success: "border-green-500 bg-green-50 text-green-800",
		warning: "border-yellow-500 bg-yellow-50 text-yellow-800",
		error: "border-red-500 bg-red-50 text-red-800",
	};

	return (
		<Transition show={show}>
			<AnimateIn>
				<div
					className={`rounded-2xl p-4 ${typeStyles[type]} ${customStyles}`}
				>
					<div className="flex items-center gap-1 mb-1">
						<BellAlertIcon className="size-5" />
						<p className="text-sm font-semibold">{title}</p>
					</div>
					{children}
				</div>
			</AnimateIn>
		</Transition>
	);
};

export default Notice;
