import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { BoltIcon, VideoCameraIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon, CheckIcon } from "@heroicons/react/16/solid";
import { PopupButton } from "react-calendly";

import Container from "../../shared/components/UIElements/Container";
import MainLogo from "../../shared/components/Branding/MainLogo";
import Heading from "../../shared/components/UIElements/Heading";
import Badge from "../../shared/components/UIElements/Badge";
import Button from "../../shared/components/FormElements/Button";
import lightbulbEmoji from "../../assets/Lightbulb.png";
import Modal from "../../shared/components/UIElements/Modal";

const ChooseOnboardingType = () => {
	const [showModal, setShowModal] = useState(false);

	const query = new URLSearchParams(useLocation().search);
	const praktijknaam = query.get("praktijk");
	const name = query.get("name");
	const email = query.get("email");
	const aantalstoelen = query.get("stoelen");

	const queryParams = `?pilot=true&uid=${btoa(praktijknaam)}`;

	const purchaseLinks = [
		{
			url: `https://api.snelterecht.nl/api/billing/checkout/klein${queryParams}`,
			minStoelen: 0,
			maxStoelen: 3,
		},
		{
			url: `https://api.snelterecht.nl/api/billing/checkout/middel${queryParams}`,
			minStoelen: 4,
			maxStoelen: 6,
		},
		{
			url: `https://api.snelterecht.nl/api/billing/checkout/groot${queryParams}`,
			minStoelen: 7,
			maxStoelen: 1000,
		},
	];

	return (
		<>
			<Container transparent={true}>
				<div className="flex items-center gap-2">
					<MainLogo />
					<XMarkIcon className="size-4 text-slate-600" />{" "}
					<span className="text-brand-intense text-xl font-semibold">
						{praktijknaam || "Jouw Praktijk"}
					</span>
				</div>
				<div className="mt-20 mb-8">
					<div className="mb-8 w-1/4">
						<div className="flex items-center justify-between mb-2">
							<span className="text-sm text-green-700 font-medium">
								1 van de 2 stappen voltooid
							</span>
						</div>
						<div className="w-full bg-gray-200 rounded-full h-2.5">
							<div className="bg-green-500 h-2.5 rounded-full w-2/3"></div>
						</div>
					</div>
					<Heading>
						Bijna klaar... Hoe wil je starten met Snelterecht?
					</Heading>
					<div className="mt-10 flex flex-col md:flex-row gap-6 [&>div]:w-full md:[&>div]:w-1/3 [&>div]:rounded-3xl [&>div]:p-6 [&>div]:bg-slate-100">
						<div className="border-brand-500 border-2 justify-between flex-col flex">
							<div>
								<VideoCameraIcon className="size-8 mb-3 text-brand-600" />

								<h3 className="text-lg font-semibold">
									Onboarding sessie inplannen
								</h3>
								<p className="text-gray-500 mt-1 mb-4">
									Plan een Zoom sessie met Matthijs en je
									receptie team waarin je uitleg krijgt over
									Snelterecht.
								</p>
							</div>

							<PopupButton
								url={`https://calendly.com/matthijs-b658/snelterecht-pilot-intake?utm_campaign=${btoa(
									praktijknaam,
								)}`}
								rootElement={document.getElementById("root")}
								className="text-white bg-brand-intense hover:opacity-90 rounded-lg px-4 py-2 text-sm font-semibold hover:scale-105 w-fit"
								text="Bekijk beschikbaarheid"
								prefill={{
									email,
									name: `${name} (${praktijknaam})`,
								}}
							/>
						</div>
						<div className="justify-between flex-col flex">
							<div className="flex items-top justify-between">
								<BoltIcon className="size-8 mb-3 text-brand-600" />
							</div>
							<div className="flex items-center gap-2">
								<h3 className="text-lg font-semibold">
									Zelf aan de slag
								</h3>
								<Badge>Direct toegang</Badge>
							</div>
							<p className="text-gray-500 mt-1 mb-4">
								We hebben een uitgebreide serie aan filmpjes en
								uitleg voor je klaar staan met alle info die ook
								tijdens de intake wordt besproken.
							</p>
							<Button
								size="medium"
								iconSuffix={ArrowRightIcon}
								onClick={() => setShowModal(true)}
							>
								Krijg direct toegang
							</Button>
						</div>
					</div>
				</div>
				<div className="flex flex-col md:flex-row items-center gap-4 bg-yellow-50 bg-opacity-90 p-4 rounded-2xl w-full md:w-2/3">
					<img
						src={lightbulbEmoji}
						alt="Lightbulb"
						className="size-8"
					/>
					<div>
						<p className="text-yellow-900 font-semibold">
							Je kunt de onboarding altijd nog inplannen als je al
							je account hebt
						</p>
						<p className="text-yellow-800 text-sm">
							Alles wat tijdens de onboarding wordt uitgelegd,
							staat ook in de filmpjes. Je kunt ons daarnaast
							altijd bereiken via de chat of een Zoom inplannen
							met Matthijs
						</p>
					</div>
				</div>
			</Container>
			<Modal open={showModal} setOpen={() => setShowModal(false)}>
				<div className="px-6 pb-8">
					<h3 className="font-semibold text-xl">
						Probeer 1 maand gratis
					</h3>
					<p className="text-slate-600 pb-6 pt-2">
						Je kunt vrijblijvend een maand lang{" "}
						<strong>gratis</strong> Snelterecht uitproberen. <br />
						<br />
						In het volgende scherm wordt gevraagd om je
						betaalgegevens, maar je hoeft nog niks af te rekenen
						(los van de rekeningsverificatie van €0,01). <br />
						<br /> Goed om te weten: we hebben een{" "}
						<strong>geen gezeur garantie</strong>, dus als je een
						paar dagen te laat bent met het stopzetten o.i.d. is er
						niks aan de hand en regelen we het gewoon.
					</p>
					<Button
						iconSuffix={ArrowRightIcon}
						target="_blank"
						to={
							purchaseLinks.find(
								(link) =>
									aantalstoelen >= link.minStoelen &&
									aantalstoelen <= link.maxStoelen,
							).url
						}
					>
						Krijg direct toegang
					</Button>
					<span className="flex items-center text-green-600 text-sm font-semibold mt-2">
						<CheckIcon className="size-5" />
						Probeer 1 maand gratis
					</span>
				</div>
			</Modal>
		</>
	);
};

export default ChooseOnboardingType;
