import React, { useEffect, useState } from "react";
import cn from "mxcn";

const AnimateIn = ({
	children,
	delay = 0,
	duration = 300,
	className = "",
	type = "default",
	style,
	as = "div",
}) => {
	const animationStyles = {
		default: {
			from: "opacity-0 scale-90 -translate-x-16 blur-sm",
			to: "opacity-100 scale-100 translate-x-0 translate-y-0 blur-none",
		},
		fromTop: {
			from: "opacity-0 -translate-y-16 blur-sm",
			to: "opacity-100 translate-y-0 blur-none",
		},
		zoomIn: {
			from: "opacity-0 scale-90 -translate-x-16 blur-sm",
			to: "opacity-100 scale-100 translate-x-0 translate-y-0 blur-none",
		},
		moveInBottom: {
			from: "opacity-0 translate-y-16 blur-sm",
			to: "opacity-100 translate-y-0 blur-none",
		},
	};

	const [animate, setAnimate] = useState(animationStyles[type].from);
	const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia(
			"(prefers-reduced-motion: reduce)",
		);

		const mediaQueryChangeHandler = (e) => {
			setPrefersReducedMotion(e.matches);
		};

		setPrefersReducedMotion(mediaQuery.matches);
		mediaQuery.addEventListener("change", mediaQueryChangeHandler);

		return () => {
			mediaQuery.removeEventListener("change", mediaQueryChangeHandler);
		};
	}, []);

	useEffect(() => {
		if (prefersReducedMotion) {
			// If the user prefers reduced motion, skip the animation
			setAnimate(animationStyles[type].to);
			return;
		}

		const timer = setTimeout(() => {
			setAnimate(animationStyles[type].to);
		}, delay);

		return () => clearTimeout(timer);
	}, [delay, prefersReducedMotion]);

	return React.createElement(
		as,
		{
			className: cn("transition-all ease-in-out", className, animate),
			style: {
				transitionDuration: prefersReducedMotion
					? "0ms"
					: `${duration}ms`,
				...style,
			},
		},
		children,
	);
};

export default AnimateIn;
