import React, { useState } from "react";
import { useField } from "formik";
import Label from "./Label";
import FieldErrorMessage from "./FieldErrorMessage";
import Tooltip from "../UIElements/Tooltip";

const CustomTagInput = ({
	label,
	description,
	placeholder = "Type and press enter...",
	...props
}) => {
	const [field, meta, helpers] = useField(props);
	const { setValue } = helpers;
	const [inputError, setInputError] = useState("");

	// Initialize field.value as empty array if undefined/null
	if (!field.value) {
		setValue([]);
	}

	const isValidEmail = (email) => {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	const addTag = (value) => {
		const newValue = value.trim();
		if (newValue && !field.value.includes(newValue)) {
			if (isValidEmail(newValue)) {
				setValue([...field.value, newValue]);
				setInputError("");
			} else {
				setInputError("Voer een geldig e-mailadres in");
			}
		}
	};

	const handleKeyDown = (e) => {
		if (
			e.target.value &&
			(e.key === "Enter" || e.key === "," || e.key === " ")
		) {
			e.preventDefault();
			addTag(e.target.value);
			e.target.value = "";
		}
	};

	const handleBlur = (e) => {
		if (e.target.value) {
			addTag(e.target.value);
			e.target.value = "";
		}
	};

	const removeTag = (tagToRemove) => {
		setValue(field.value.filter((tag) => tag !== tagToRemove));
	};

	return (
		<div className={`grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}>
			<div className="col-span-full">
				<Label label={label} description={description} {...props} />

				<div className="mt-2 relative rounded-md shadow-sm max-w-4xl">
					<div className="block border-0 py-1 text-gray-900 rounded-lg ring-1 ring-inset ring-gray-300 pr-3 placeholder:text-gray-400 focus-within:ring-2 focus-within:ring-inset focus-within:ring-brand-600 sm:text-sm sm:leading-6">
						<div className="flex flex-wrap gap-1 items-center first:ml-1.5">
							{field.value?.map((tag, index) => (
								<span
									key={index}
									className="bg-gray-100 px-3 py-1 rounded-full flex items-center gap-1"
								>
									{tag}
									<Tooltip
										content="Weghalen"
										id={`remove-tag-${index}`}
									>
										<button
											type="button"
											onClick={() => removeTag(tag)}
											className="text-gray-500 hover:text-gray-700"
										>
											×
										</button>
									</Tooltip>
								</span>
							))}
							<input
								type="text"
								onKeyDown={handleKeyDown}
								onBlur={handleBlur}
								placeholder={placeholder}
								className="outline-none border-none flex-1 bg-transparent min-w-[120px] text-sm"
							/>
						</div>
					</div>
				</div>
				<FieldErrorMessage meta={meta} />
				{inputError && (
					<p className="mt-2 text-sm text-orange-700">{inputError}</p>
				)}
			</div>
		</div>
	);
};

export default CustomTagInput;
