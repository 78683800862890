import React, { useEffect, useState, useMemo } from "react";
import capitalizeString from "./capitalizeString";

const FormattedDate = ({
	errorMessage = "Geen valide datum",
	date,
	relative = false,
	verb,
	alwaysDate = false,
	...props
}) => {
	const [currentDate, setCurrentDate] = useState(new Date());

	useEffect(() => {
		if (relative) {
			const interval = setInterval(() => {
				setCurrentDate(new Date());
			}, 60000);
			return () => clearInterval(interval);
		}
	}, [relative]);

	const parsedDate = useMemo(() => new Date(date), [date]);
	const now = useMemo(() => new Date(), []);

	const getDateFormatOptions = (includeYear) => ({
		weekday: "long",
		day: "numeric",
		month: "long",
		...(includeYear ? { year: "numeric" } : {}),
	});

	const formatDate = (date, includeYear = false) => {
		const options = getDateFormatOptions(includeYear);
		return capitalizeString(
			new Intl.DateTimeFormat("nl", options).format(date),
		);
	};

	const isTomorrow = (date, now) => {
		const tomorrow = new Date(now);
		tomorrow.setDate(tomorrow.getDate() + 1);
		return date.toDateString() === tomorrow.toDateString();
	};

	const isToday = (date, now) => date.toDateString() === now.toDateString();

	const isYesterday = (date, now) => {
		const yesterday = new Date(now);
		yesterday.setDate(yesterday.getDate() - 1);
		return date.toDateString() === yesterday.toDateString();
	};

	if (!date) {
		return <span {...props}>{errorMessage}</span>;
	}

	if (relative) {
		const timeDifference = (currentDate - parsedDate) / (1000 * 60); // Calculate time difference in minutes
		const daysDifference = Math.floor(timeDifference / (60 * 24));
		const monthsDifference = Math.floor(daysDifference / 30);

		if (timeDifference < 1) {
			return <span {...props}>Zojuist {verb}</span>;
		} else if (timeDifference < 60) {
			return (
				<span {...props}>
					{Math.floor(timeDifference)} min geleden {verb}
				</span>
			);
		} else if (timeDifference < 1440) {
			return (
				<span {...props}>
					{Math.floor(timeDifference / 60)} uur geleden {verb}
				</span>
			);
		} else if (daysDifference < 30) {
			return (
				<span {...props}>
					{daysDifference > 1
						? `${daysDifference} dagen geleden`
						: `${daysDifference} dag geleden`}{" "}
					{verb}
				</span>
			);
		} else {
			return (
				<span {...props}>
					{monthsDifference > 1
						? `${monthsDifference} maanden geleden`
						: `${monthsDifference} maand geleden`}{" "}
					{verb}
				</span>
			);
		}
	}

	if (alwaysDate) {
		return (
			<span {...props}>
				{formatDate(
					parsedDate,
					parsedDate.getFullYear() !== now.getFullYear(),
				)}
			</span>
		);
	}

	if (isToday(parsedDate, now)) {
		return <span {...props}>{capitalizeString("Vandaag")}</span>;
	} else if (isYesterday(parsedDate, now)) {
		return <span {...props}>{capitalizeString("Gisteren")}</span>;
	} else if (isTomorrow(parsedDate, now)) {
		return <span {...props}>{capitalizeString("Morgen")}</span>;
	} else {
		return (
			<span {...props}>
				{formatDate(
					parsedDate,
					parsedDate.getFullYear() !== now.getFullYear(),
				)}
			</span>
		);
	}
};

export default FormattedDate;
