import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import Container from "../../shared/components/UIElements/Container";
import Button from "../../shared/components/FormElements/Button";
import MainLogo from "../../shared/components/Branding/MainLogo";
import {
	ArrowRightIcon,
	ArrowsRightLeftIcon,
	ComputerDesktopIcon,
	GlobeAltIcon,
} from "@heroicons/react/20/solid";

import backgroundImage from "../../assets/Background.png";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";

const AuthenticateDesktopApp = () => {
	const [deviceId, setDeviceId] = useState(null);
	const [searchParams] = useSearchParams();
	const { user } = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		const deviceIdParam = searchParams.get("deviceId");
		if (deviceIdParam) {
			setDeviceId(decodeURIComponent(deviceIdParam));
		}
	}, [searchParams]);

	useEffect(() => {
		const updateUser = async () => {
			if (user) {
				await user.update({
					unsafeMetadata: {
						desktopAppConnected: true,
						deviceId: deviceId,
						...user.unsafeMetadata,
					},
				});
			}
		};

		updateUser();
	}, [deviceId]);

	const handleConnectClick = () => {
		if (user && deviceId) {
			const desktopAppUrl = `snelterecht-dentiva://auth/callback/${user.id}`;
			window.location.href = desktopAppUrl;
		}
	};

	const backgroundImageUrl = `${process.env.REACT_APP_FRONTEND_URL}${backgroundImage}`;

	return (
		<div
			className="bg-slate-100 min-h-screen pb-20 flex flex-col"
			style={{
				backgroundImage: `url(${backgroundImageUrl})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "contain",
				backgroundPosition: "right bottom",
			}}
		>
			<Container transparent>
				<div className="flex justify-between pt-14 pb-16 items-center">
					<div className="w-1/3 justify-start hidden md:flex">
						<MainLogo />
					</div>
					<div className="w-1/3 flex justify-end">
						{/* You can add a close button here if needed */}
					</div>
				</div>
				<div className="max-w-md mr-auto bg-white shadow-sm rounded-xl overflow-hidden border border-gray-200">
					<div className="px-6 py-8">
						<div className="flex items-center mb-4 gap-x-2">
							<ComputerDesktopIcon className="size-6 text-brand-intense" />
							<ArrowsRightLeftIcon className="size-6 text-slate-400" />
							<GlobeAltIcon className="size-6 text-brand-intense" />
						</div>

						<h1 className="text-2xl font-bold text-gray-900 mb-6">
							Verbind je account met de desktop app
						</h1>
						<p className="text-gray-600 mb-8">
							Klik op de knop hieronder om je account te verbinden
							met het desktop programma. Je wordt automatisch
							doorgestuurd naar de Windows-app.
						</p>
						{deviceId && (
							<Button
								size="large"
								onClick={handleConnectClick}
								iconSuffix={ArrowUpRightIcon}
								fullWidth
							>
								Verbinden
							</Button>
						)}
					</div>
				</div>
			</Container>
		</div>
	);
};

export default AuthenticateDesktopApp;
