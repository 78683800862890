import React from "react";
import { useLocation, Link } from "react-router-dom";

import { ArrowTopRightOnSquareIcon, PlusIcon } from "@heroicons/react/16/solid";
import TasksCounter from "../../../tasks/components/TasksCounter";
import Button from "../FormElements/Button";

const NavItem = ({ item }) => {
	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	const location = useLocation();
	item.current =
		item.href === "/"
			? location.pathname === "/"
			: location.pathname.startsWith(item.href);

	if (item.hide) return null;

	return (
		<div className={item.classNames}>
			{item.external ? (
				// For external links, use the <a> tag
				<a
					key={item.name}
					href={item.href}
					onClick={(e) => {
						// posthog.capture(`🔗 Geopend: ${item.name}`, {
						// 	link: item.href,
						// });
					}}
					target="_blank"
					rel="noopener noreferrer"
					className={classNames(
						item.current
							? "text-black"
							: "text-gray-400 hover:bg-gray-100 hover:text-gray-900 duration-100 transition-colors",
						"group flex items-center px-2 py-2 text-md rounded-lg font-semibold ph-autocapture",
					)}
				>
					<item.icon
						className={classNames(
							item.current
								? "text-brand-600"
								: "text-gray-400 group-hover:text-gray-500",
							"mr-3 flex-shrink-0 h-6 w-6",
						)}
						aria-hidden="true"
					/>
					{item.name}
					{item.external && (
						<ArrowTopRightOnSquareIcon className="w-4 ml-2 opacity-60" />
					)}
				</a>
			) : (
				// For internal links, use the <Link> component
				<Link
					to={item.href}
					className={classNames(
						item.current
							? "text-black"
							: "text-gray-400 hover:bg-gray-100 hover:text-gray-900 duration-100 transition-colors",
						"group flex items-center px-2 py-2 text-md rounded-lg font-semibold ph-autocapture",
					)}
				>
					<item.icon
						className={classNames(
							item.current
								? "text-brand-600"
								: "text-gray-400 group-hover:text-gray-500",
							"mr-3 flex-shrink-0 size-6",
						)}
						aria-hidden="true"
					/>

					{item.name}
					<div className="flex items-center gap-x-2 ml-auto">
						{item.includeCounter && (
							<span className="ml-auto">
								<TasksCounter />
							</span>
						)}
						{item.plusIcon && (
							<>
								<Link
									to={item.plusUrl}
									className="ml-auto p-0.5 bg-[#f3f4f6] rounded-md hover:bg-slate-300 text-slate-600 hover:text-slate-800 duration-100 transition-colors"
								>
									<PlusIcon
										className="ml-auto size-4  ph-autocapture"
										aria-hidden="true"
									/>
								</Link>
							</>
						)}
					</div>
				</Link>
			)}
		</div>
	);
};

export default NavItem;
