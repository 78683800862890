import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import SkeletonLoading from "../../shared/components/UIElements/SkeletonLoading";
import PriorityStar from "../../patients/components/PriorityStar";
import FormattedDate from "../../shared/components/util/FormattedDate";
import BehandelaarAvatar from "../../practice/components/Behandelaren/BehandelaarAvatar";
import EmptyState from "../../shared/components/Tables/EmptyState";
import PatientDetail from "./PatientDetail";

const SelectPatientsTable = ({
	patients = [],
	notAvailablePatients = [],
	isLoading,
	selectedPatients,
	setSelectedPatients,
	creatiefVullen = false,
	slotDuration,
	autoCheckAll = false,
}) => {
	const [detailPatient, setDetailPatient] = useState(null);
	const [isTreatmentTypePresent, setIsTreatmentTypePresent] = useState(false);

	const [checked, setChecked] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);

	const checkboxRef = useRef(null);

	useEffect(() => {
		setIsTreatmentTypePresent(
			patients.some((p) => p.appointment.treatmentType),
		);

		// Add this effect to auto-check all patients
		if (autoCheckAll || creatiefVullen) {
			setSelectedPatients(patients.map((p) => p._id));
		}
	}, [patients, autoCheckAll, creatiefVullen, setSelectedPatients]);

	useLayoutEffect(() => {
		if (checkboxRef.current) {
			const isIndeterminate =
				selectedPatients.length > 0 &&
				selectedPatients.length < patients.length;
			setChecked(selectedPatients.length === patients.length);
			setIndeterminate(isIndeterminate);
			checkboxRef.current.indeterminate = isIndeterminate;
		}
	}, [selectedPatients, patients]);

	const togglePatientSelection = (patient) => {
		if (selectedPatients.includes(patient._id)) {
			setSelectedPatients(
				selectedPatients.filter((id) => id !== patient._id),
			);
		} else {
			setSelectedPatients([...selectedPatients, patient._id]);
		}
	};

	const toggleAll = () => {
		setSelectedPatients(
			checked || indeterminate ? [] : patients.map((p) => p._id),
		);
	};

	return (
		<>
			<div className="mt-6">
				{!isLoading && patients.length === 0 && (
					<EmptyState
						title="Geen patiënten die voldoen aan de criteria"
						description="Helaas... Geen patiënt komt in aanmerking voor dit agendagat."
						icon={UserGroupIcon}
					/>
				)}

				{notAvailablePatients.length > 0 && (
					<div className="mt-8">
						<p className="italic text-sm leading-6 text-gray-600">
							{notAvailablePatients.length > 1 &&
								`${notAvailablePatients.length} andere patiënten zijn met vakantie of hebben aangegeven niet te kunnen.`}
							{notAvailablePatients.length === 1 &&
								`${notAvailablePatients.length} andere patiënt is met vakantie of heeft aangegeven niet te kunnen.`}
						</p>
					</div>
				)}

				{(isLoading || patients.length > 0) && (
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="relative w-12 px-6 sm:w-16 sm:px-8"
												>
													<input
														type="checkbox"
														className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded text-brand-600 focus:ring-brand-600 cursor-pointer"
														ref={checkboxRef}
														checked={checked}
														onChange={toggleAll}
													/>
												</th>
												<th
													scope="col"
													className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
												>
													Naam
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Duur behandeling
												</th>
												{isTreatmentTypePresent && (
													<th
														scope="col"
														className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
													>
														Soort behandeling
													</th>
												)}

												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Geplande afspraak
												</th>
												{patients.some(
													(patient) => patient.notes,
												) && (
													<th
														scope="col"
														className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-80"
													>
														Opmerkingen
													</th>
												)}
											</tr>
										</thead>
										{isLoading && (
											<SkeletonLoading
												rows={8}
												columns={5}
											/>
										)}
										<tbody className="divide-y divide-gray-200">
											{patients.map((patient) => (
												<tr
													key={patient._id}
													className="cursor-pointer hover:bg-gray-50 transition-all duration-150 ease-in-out"
													style={{
														boxShadow:
															selectedPatients.includes(
																patient._id,
															)
																? "2px 0 0 #7F56D9 inset"
																: "none",
													}}
												>
													<td className="w-12 sm:w-16 px-4 cursor-default">
														<div className="flex items-center h-full ">
															<input
																type="checkbox"
																className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
																checked={selectedPatients.includes(
																	patient._id,
																)}
																onChange={() =>
																	togglePatientSelection(
																		patient,
																	)
																}
															/>
														</div>
													</td>
													<td
														className="pl-2 pr-3 py-4 text-sm text-black font-medium"
														onClick={() =>
															setDetailPatient(
																patient._id,
															)
														}
													>
														<p className="flex">
															{patient.name}
															{patient.priority && (
																<PriorityStar />
															)}
														</p>
														<p className="text-xs text-gray-500">
															{
																patient.patientNumber
															}
														</p>
													</td>
													<td
														className="px-3 py-4 text-sm text-gray-500"
														onClick={() =>
															setDetailPatient(
																patient._id,
															)
														}
													>
														{
															patient.appointment
																.duration
														}{" "}
														minuten{" "}
														{creatiefVullen &&
															patient.appointment
																.duration -
																slotDuration >
																0 && (
																<span className="text-sm text-red-600 font-semibold">
																	+
																	{patient
																		.appointment
																		.duration -
																		slotDuration}{" "}
																	min
																</span>
															)}
													</td>

													{isTreatmentTypePresent && (
														<td
															className="px-3 py-4 text-sm text-gray-500"
															onClick={() =>
																setDetailPatient(
																	patient._id,
																)
															}
														>
															{patient.appointment
																.treatmentType &&
																patient
																	.appointment
																	.treatmentType
																	.name}
														</td>
													)}
													<td
														className="px-3 py-4 text-sm text-gray-500"
														onClick={() =>
															setDetailPatient(
																patient._id,
															)
														}
													>
														<div className="flex items-center">
															<BehandelaarAvatar
																firstName={
																	patient
																		.behandelaar
																		.firstName
																}
																lastName={
																	patient
																		.behandelaar
																		.lastName
																}
															/>
															<div className="pl-2">
																<p className="text-black">
																	<FormattedDate
																		date={
																			patient
																				.appointment
																				.datePlanned
																		}
																		errorMessage={
																			"Geen afspraak ingepland"
																		}
																	/>
																</p>
																<p className="text-xs pt-0.5">
																	{
																		patient
																			.behandelaar
																			.firstName
																	}{" "}
																	{
																		patient
																			.behandelaar
																			.lastName
																	}
																</p>
															</div>
														</div>
													</td>
													{patients.some(
														(patientX) =>
															patientX.notes,
													) && (
														<>
															{patient.notes && (
																<td
																	onClick={() =>
																		setDetailPatient(
																			patient._id,
																		)
																	}
																	className="px-3 py-4 text-sm text-gray-500 max-w-80"
																>
																	{
																		patient.notes
																	}
																</td>
															)}
														</>
													)}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<PatientDetail
				patientId={detailPatient}
				open={!!detailPatient}
				setOpen={() => setDetailPatient(null)}
				onClose={() => setDetailPatient(null)}
				onPreviewPage={true}
				reload={() => {}}
			/>
		</>
	);
};

export default SelectPatientsTable;
