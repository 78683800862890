import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import useFetch from "../../../shared/hooks/useFetch";
import { useUser } from "@clerk/clerk-react";
import Heading from "../../../shared/components/UIElements/Heading";
import PracticeList from "../../components/PracticeList";
import "leaflet/dist/leaflet.css";

// Fix for default marker icon
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
	iconUrl: require("leaflet/dist/images/marker-icon.png"),
	shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapEvents = ({ practices, setPracticesInView }) => {
	const map = useMap();

	useEffect(() => {
		const updatePracticesInView = () => {
			const bounds = map.getBounds();
			setPracticesInView(
				practices.filter((practice) =>
					bounds.contains([
						practice.coordinates.latitude,
						practice.coordinates.longitude,
					]),
				),
			);
		};

		map.on("moveend", updatePracticesInView);
		updatePracticesInView(); // Initial update

		return () => {
			map.off("moveend", updatePracticesInView);
		};
	}, [map, setPracticesInView, practices]);

	return null;
};

const PracticesMap = () => {
	const fetchData = useFetch();
	const { user } = useUser();
	const [practices, setPractices] = useState([]);
	const [practicesInView, setPracticesInView] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [showOnlyActive, setShowOnlyActive] = useState(true);
	const mapRef = useRef();

	useEffect(() => {
		const fetchPractices = async () => {
			setIsLoading(true);
			const data = await fetchData(
				`/api/admin/practice-coordinates/${user.id}/`,
			);
			setPractices(data);
			setPracticesInView(data);
			setIsLoading(false);
		};
		fetchPractices();
	}, [fetchData, user.id]);

	const filteredPractices = showOnlyActive
		? practices.filter((practice) => practice?.activeSubscription)
		: practices;

	const mapCenter = [52.132633, 5.291266]; // Coordinates for the center of the Netherlands
	const mapZoom = 7;

	return (
		<div className="mb-24">
			<Heading>Praktijken Kaart</Heading>
			<div className="mt-4">
				<label className="inline-flex items-center">
					<input
						type="checkbox"
						className="form-checkbox h-5 w-5 text-blue-600"
						checked={showOnlyActive}
						onChange={(e) => setShowOnlyActive(e.target.checked)}
					/>
					<span className="ml-2 text-gray-700">
						Laat alleen actieve praktijken zien
					</span>
				</label>
			</div>
			{isLoading ? (
				<p>Kaart laden...</p>
			) : (
				<div className="flex mt-8 gap-x-4">
					<div
						style={{
							height: "600px",
							width: "70%",
							marginBottom: "4rem",
						}}
					>
						<MapContainer
							center={mapCenter}
							zoom={mapZoom}
							style={{
								height: "130%",
								width: "100%",
								borderRadius: "1.5rem",
							}}
							ref={mapRef}
						>
							<TileLayer
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							/>
							{filteredPractices.map((practice) => (
								<Marker
									key={practice._id}
									position={[
										practice.coordinates.latitude,
										practice.coordinates.longitude,
									]}
								>
									<Popup>
										<div>
											<h5 className="font-bold">
												{practice.name}
											</h5>
											<p>{practice.address.city}</p>
											<p>
												Status:{" "}
												{practice?.activeSubscription
													? "Actief"
													: "Inactief"}
											</p>
										</div>
									</Popup>
								</Marker>
							))}
							<MapEvents
								practices={filteredPractices}
								setPracticesInView={setPracticesInView}
							/>
						</MapContainer>
					</div>
					<div
						className="w-30 ml-4 overflow-y-auto"
						style={{ height: "600px" }}
					>
						<PracticeList
							practices={practicesInView}
							mapRef={mapRef}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default PracticesMap;
