import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Button from "../components/PortalButton";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import Loading from "../../shared/pages/Loading";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";
import Textarea from "../../shared/components/FormElements/Textarea";
import RadioGroup from "../../shared/components/FormElements/RadioGroup";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import Modal from "../../shared/components/UIElements/Modal";
import Heading from "../../shared/components/UIElements/Heading";

const DeletePatient = () => {
	const [patient, setPatient] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isDeleted, setIsDeleted] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { patientId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	// Get a specific query parameter
	const key = searchParams.get("key");

	const deletePatient = async (values) => {
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/public/patients/${patientId}/?key=${key}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(values),
			},
		);
		if (response.ok) {
			setIsDeleted(true);
		} else {
			const errorData = await response.json();
			toast.error(errorData.message, {
				position: "top-center",
			});
		}
	};

	useEffect(() => {
		const fetchPatient = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/public/patient/${patientId}`,
			);
			const responseData = await response.json();
			setPatient(responseData);
			setIsLoading(false);

			if (responseData.status === "deleted") {
				setIsDeleted(true);
			}
		};

		if (!isDeleted) {
			fetchPatient();
		}
	}, [isDeleted]);

	if (isLoading || (!patient && !isDeleted)) return <Loading />;

	if (isDeleted) {
		return (
			<div className="flex flex-col items-center justify-center h-[100dvh]">
				<CheckCircleIcon className="w-16 h-16 text-green-500" />
				<h2 className="text-2xl font-semibold">
					U bent succesvol verwijderd
				</h2>
				<p className="mt-2 text-slate-600 text-center">
					Uw gegevens zijn succesvol verwijderd uit de Snelterecht
					systemen. U wordt niet meer opgeroepen voor vrijgekomen
					plekken.
				</p>
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col h-[100dvh] px-6 pt-24 pb-6">
				<div>
					<MiniBackButton to={`/c/pt/${patientId}?key=${key}`} />
					<h1 className="text-2xl font-bold">
						Weet u zeker dat u zich uit Snelterecht wilt halen?
					</h1>
					<p className="text-gray-600 pt-3">
						Uw gegevens worden geanonimiseerd en u wordt niet meer
						uitgenodigd voor vrijgekomen plekken.
					</p>
				</div>

				<Formik
					initialValues={{
						reason: "",
						comment: "",
					}}
					validationSchema={Yup.object({
						reason: Yup.string().required("Reden is verplicht"),
						comment: Yup.string(),
					})}
					onSubmit={(values) => {
						deletePatient(values);
					}}
				>
					{({ setFieldValue }) => (
						<Form className="flex flex-col h-full">
							<div className="flex-grow">
								<RadioGroup
									label="Waarom wilt u zichzelf van de Snelterecht lijst afhalen?"
									name="reason"
									options={[
										{
											label: "Ik ben geen patiënt bij deze praktijk",
											value: "not_a_patient",
										},
										{
											label: "Ik heb nooit gevraagd om mijn afspraak te vervroegen",
											value: "not_requested",
										},
										{
											label: "Ik wil mijn afspraak toch niet meer vervroegen",
											value: "changed_mind",
										},
										{
											label: "Anders, namelijk...",
											value: "other",
										},
									]}
									setFieldValue={setFieldValue}
								/>
								<Textarea
									label="Opmerkingen"
									name="comment"
									setFieldValue={setFieldValue}
								/>
							</div>
							<Button
								fullWidth
								ghost
								iconPrefix={QuestionMarkCircleIcon}
								onClick={() => setIsModalOpen(true)}
							>
								Informatie over gegevens
							</Button>
							<div className="sticky bottom-0 bg-white py-4">
								<Button
									type="submit"
									fullWidth
									classNames="bg-red-600 hover:bg-red-700"
								>
									Ja, haal me van de lijst af
								</Button>
								<p className="text-center text-gray-600 mt-2 text-sm">
									Dit kan niet ongedaan gemaakt worden
								</p>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			<Modal open={isModalOpen} setOpen={setIsModalOpen}>
				<div className="pb-10 px-8">
					<Heading size="3">
						Wat gebeurt er met mijn gegevens?
					</Heading>
					<p className="text-gray-600 mt-2">
						Uw persoonlijke gegevens, zoals uw naam en
						telefoonnummer, worden verwijderd uit onze systemen. De
						reden waarom u zichzelf van de lijst afhaalt wordt
						uitsluitend gekoppeld aan uw patiëntnummer.
						<br />
						<br /> U wordt niet meer opgeroepen voor vrijgekomen
						plekken in de agenda.
					</p>
				</div>
			</Modal>
		</>
	);
};

export default DeletePatient;
