import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import Tooltip from "../../shared/components/UIElements/Tooltip";

const ActivityIndicator = ({ activities }) => {
	const containerRef = useRef(null);

	const formatDate = (date) => {
		return new Date(date)
			.toLocaleDateString("nl-NL", {
				weekday: "short",
				day: "numeric",
				month: "short",
			})
			.toLowerCase();
	};

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollLeft = containerRef.current.scrollWidth;
		}
	}, [activities]);

	return (
		<div className="relative">
			<div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent pointer-events-none"></div>
			<div
				ref={containerRef}
				className="flex gap-2 overflow-x-auto scrollbar-hide"
			>
				{activities?.map((activity) => (
					<Tooltip
						id={activity.date}
						content={`${formatDate(activity.date)}`}
					>
						<div
							key={activity.date}
							className={classNames("rounded-full size-4", {
								"bg-white border-[4px] border-green-600 ":
									activity.isActive && !activity.claimedSlot,
								"bg-slate-200": !activity.isActive,
								"bg-green-600": activity.claimedSlot,
							})}
						></div>
					</Tooltip>
				))}
			</div>
		</div>
	);
};

export default ActivityIndicator;
