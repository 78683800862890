import React from "react";
import { useField } from "formik";
import { ArrowRightIcon } from "@heroicons/react/16/solid";
import Label from "./Label";
import FieldErrorMessage from "./FieldErrorMessage";

const TimeRangePicker = ({
	label,
	description,
	startTimeName,
	endTimeName,
	...props
}) => {
	const [startTimeField, startTimeMeta, startTimeHelpers] =
		useField(startTimeName);
	const [endTimeField, endTimeMeta, endTimeHelpers] = useField(endTimeName);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === startTimeName) {
			startTimeHelpers.setValue(value);
		} else if (name === endTimeName) {
			endTimeHelpers.setValue(value);
		}
	};

	return (
		<div
			className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}
		>
			<div className="col-span-full">
				<Label label={label} description={description} />
				<div className="flex space-x-4 items-center">
					<input
						type="time"
						name={startTimeName}
						value={startTimeField.value || ""}
						onChange={handleChange}
						className="block w-1/2 lg:w-1/3 xl:w-1/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						min="06:00"
						max="22:55"
						step="300"
					/>
					<ArrowRightIcon className="size-4 text-gray-400" />
					<input
						type="time"
						name={endTimeName}
						value={endTimeField.value || ""}
						onChange={handleChange}
						className="block w-1/2 lg:w-1/3 xl:w-1/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						min="06:05"
						max="23:00"
						step="300"
					/>
				</div>
				<FieldErrorMessage meta={startTimeMeta} />
				<FieldErrorMessage meta={endTimeMeta} />
			</div>
		</div>
	);
};

export default TimeRangePicker;
